import styled from 'styled-components'
import { spaces } from 'styles/mixins'
import { secondaryTitle } from 'styles/typography'

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: ${spaces.large4};
`

export const Header = styled.div`
    ${secondaryTitle}
    padding: ${spaces.large2};
    margin-bottom: ${spaces.large2};
`

export const TableWrapper = styled.div`
  height: 80vh;
`

export const ImageWrapper = styled.div`
  width: 80%;
  text-align: center;
  margin: 0 auto;
  padding: ${spaces.large9} 0;
`

export const CreateNewImageOverlay = styled.img`
  width: 128px;
  opacity: .8;
`
