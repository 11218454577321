import { useState, Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

import CustomIconButton from 'components/CustomIconButton'
import CustomDialog from 'components/CustomDialog'
import CustomDropzone from 'components/CustomDropzone'
import PublishIcon from '@material-ui/icons/Publish'
import BlenderLogo from 'assets/Logo_Blender.svg'
import Queue from 'utils/queue'
import { showAlert } from 'redux/alert/action'
import { RequestMethod } from 'types/api'
import { API_UPLOAD_BLEND } from 'env'

interface Props {
  id: number
}

const UploadBlendDialog = ({ id }: Props) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [myFiles, setMyFiles] = useState<File[]>([])
  const [uploadingFile, setUploadingFile] = useState('')
  const { getAccessTokenSilently } = useAuth0()

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles])
    },
    [myFiles]
  )

  const handleRemoveFile = (file: File) => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const recursiveUpload = async (filesInQueue: Queue) => {
    if (filesInQueue.isEmpty()) {
      dispatch(showAlert('Upload success', 'success'))
      setLoading(false)
      setMyFiles([])
      setUploadingFile('')
      setProgress(0)
      handleClose()
      return
    }
    try {
      const accessToken = await getAccessTokenSilently()
      const fileToUpload = filesInQueue[0]
      setUploadingFile(fileToUpload.name)
      let formData = new FormData()
      formData.append(`file`, fileToUpload)
      formData.append(`word_id`,id.toString())
      const config = {
        method: RequestMethod.POST,
        url: API_UPLOAD_BLEND,
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress: (progress: ProgressEvent) => {
          const percentCompleted = Math.round(
            (progress.loaded * 100) / progress.total
          )
          setProgress(percentCompleted)
        },
      }
      const res = await axios(config)
      if (res.status === 200) {
        handleRemoveFile(fileToUpload)
        filesInQueue.dequeue()
        recursiveUpload(filesInQueue)
      }
    } catch (err) {
      console.error('Error', err)
      setLoading(false)
      dispatch(showAlert('Upload fail please try again', 'error'))
    }
  }

  const handleSubmit = () => {
    const queue = new Queue()
    const tempFiles = [...myFiles]
    if (tempFiles.length > 0) {
      tempFiles.forEach((file) => queue.enqueue(file))
      recursiveUpload(queue)
    }
  }

  return (
    <Fragment>
      <CustomIconButton
        tooltipLabel="Upload .blend"
        svgIcon={<PublishIcon />}
        onClick={handleOpen}
        placement="top"
        iconSize="large"
        arrow
      />
      <CustomDialog open={open} title="Upload Blend File" onClose={handleClose} onSubmit={handleSubmit}>
        <CustomDropzone
          fileType=".blend"
          imageSource={BlenderLogo}
          onDrop={handleDrop}
          onRemoveFile={handleRemoveFile}
          loading={loading}
          progress={progress}
          uploadingFile={uploadingFile}
          myFiles={myFiles}
        />
      </CustomDialog>
    </Fragment>
  )
}

export default UploadBlendDialog
