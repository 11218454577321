import { Fragment, ChangeEvent, FocusEvent, KeyboardEvent } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
//material ui component
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
//icon
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
//component
import CustomTimePicker from 'components/CustomTimePicker'
import SortableChips from 'components/SortableChips'
//type
import { CaptionInput } from 'types/captionInput'
import { StartEndMoment } from 'types/moment'

import captionFormStyle from 'styles/material_ui/components/captionFormStyle'
//redux
import { wordSegmentation } from 'redux/tokenization/action'
import {
  deleteChipWrapper,
  changeFocusChip,
  addRowWrapper,
  deleteRowWrapper,
  changeTimeWrapper,
  stateCaptionWrapper,
} from 'redux/captionInput/action'
import { isEmpty } from 'lodash'
import { clearKeypoint, getAnimationKeypoint } from 'redux/keypoint/action'
import { Moment } from 'types/moment'
import { getArrayOfWordsAndTime } from 'utils/arrayTools'

import { Box, FlexBox } from './style'

const useStyles = makeStyles(captionFormStyle)
interface Props {
  rowId: number
  item: CaptionInput
}

const CaptionForm = ({ rowId, item }: Props) => {
  const { start, end, tokens, focusedChip, subtitle } = item
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const showSecond = true

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    dispatch(stateCaptionWrapper(value, rowId))
  }

  const handleTimeChange = (value: Moment, type: 'start' | 'end') => {
    dispatch(changeTimeWrapper(value, rowId, type))
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (!isEmpty(value)) {
      dispatch(wordSegmentation(value, rowId))
      dispatch(stateCaptionWrapper('', rowId))
    }
  }

  const handlePreviewAnimation = () => {
    const { words, time } = getArrayOfWordsAndTime(item)
    const result = []
    const times: StartEndMoment[] = []
    result.push(words)
    times.push(time)
    if (!isEmpty(words)) {
      dispatch(clearKeypoint())
      dispatch(getAnimationKeypoint(result, times))
    }
  }

  const handleAddRow = () => {
    dispatch(addRowWrapper(rowId))
  }

  const handleDeleteRow = () => {
    dispatch(deleteRowWrapper(rowId))
  }

  const renderEndAdornment = () => {
    if (tokens.length > 0) {
      return (
        <Fragment>
          <Tooltip
            title={`${t('home.form.input.preview')}`}
            placement="top"
            arrow
          >
            <IconButton
              component="span"
              color="default"
              className={classes.previewAnimationBtn}
              onClick={handlePreviewAnimation}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      )
    }
  }

  const renderStartAdornment = () => {
    if (tokens.length > 0) {
      return <SortableChips item={item} rowId={rowId} />
    }
    return null
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const chips = item.tokens
    const target = event.target as HTMLInputElement
    const value = target.value

    switch (event.key) {
      case 'Backspace':
        if (value === '') {
          if (focusedChip != null) {
            dispatch(deleteChipWrapper(rowId, focusedChip))
            if (focusedChip > 0) {
              dispatch(changeFocusChip(focusedChip - 1, rowId))
            }
          } else {
            dispatch(changeFocusChip(chips.length - 1, rowId))
          }
        }
        break
      case 'Delete':
        if (value === '' && focusedChip != null) {
          dispatch(deleteChipWrapper(rowId, focusedChip))
          if (focusedChip) {
            if (focusedChip <= chips.length - 1) {
              dispatch(changeFocusChip(focusedChip, rowId))
            }
          }
        }
        break
      case 'ArrowLeft':
        if (focusedChip === null && value === '' && chips.length) {
          dispatch(changeFocusChip(chips.length - 1, rowId))
        } else if (focusedChip != null && focusedChip > 0) {
          dispatch(changeFocusChip(focusedChip - 1, rowId))
        }
        break
      case 'ArrowRight':
        if (focusedChip != null && focusedChip < chips.length - 1) {
          dispatch(changeFocusChip(focusedChip + 1, rowId))
        } else {
          dispatch(changeFocusChip(null, rowId))
        }
        break
      default:
        dispatch(changeFocusChip(null, rowId))
        break
    }
  }

  return (
    <Box>
      <FlexBox>
        <CustomTimePicker
          id={`start-time-${rowId}`}
          showSecond={showSecond}
          value={start}
          className={classes.inputForm}
          onChange={(value: Moment) => handleTimeChange(value, 'start')}
          label={t('home.form.input.start')}
        />
        <CustomTimePicker
          id={`end-time-${rowId}`}
          showSecond={showSecond}
          value={end}
          className={classes.inputForm}
          onChange={(value: Moment) => handleTimeChange(value, 'end')}
          label={t('home.form.input.end')}
        />
      </FlexBox>
      <FlexBox>
        <FormControl
          fullWidth
          className={classnames(classes.inputForm, classes.longForm)}
          variant="outlined"
        >
          <InputLabel htmlFor={`chip-input-${rowId}`}>
            {t('home.form.input.subtitle')}
          </InputLabel>
          <OutlinedInput
            classes={{
              root: classes.chipInputRoot,
              input: classes.chipInput,
            }}
            label={t('home.form.input.subtitle')}
            id={`chip-input-${rowId}`}
            value={subtitle}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            endAdornment={renderEndAdornment()}
            startAdornment={renderStartAdornment()}
          />
        </FormControl>
      </FlexBox> 
      <IconButton
        component="span"
        onClick={handleAddRow}
        className={classnames(classes.iconBtnWrapper, classes.addBtn)}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        component="span"
        onClick={handleDeleteRow}
        className={classnames(classes.iconBtnWrapper, classes.removeBtn)}
      >
        <RemoveIcon />
      </IconButton>
    </Box>
  )
}

export default CaptionForm
