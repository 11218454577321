import {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
  ChangeEvent,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
//material ui component
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Pagination from '@material-ui/lab/Pagination'
//component
import Tooltab from 'components/Tooltab'
import PosChipDescribe from 'components/PosChipDescribe'
import CaptionForm from 'components/CaptionForm'
//utils
import { getStartEndTime } from 'utils/time'
import Queue from 'utils/queue'
import { pos } from 'utils/partOfSpeech'
import { getTotalPage, getRowsStartEndIndex } from 'utils/arrayTools'
//redux
import { selectYoutubeTranscript } from 'redux/youtubeTranscript/selector'
import { autoFillCaption } from 'redux/captionInput/action'
import { selectCaptions } from 'redux/captionInput/selector'
import {
  tokenizeStart,
  tokenizeSuccess,
  tokenizeFailure,
} from 'redux/tokenization/action'
import { addChip, stateCaptionWrapper } from 'redux/captionInput/action'
import { showAlert } from 'redux/alert/action'
//types
import { CaptionInput, CaptionsToSave } from 'types/captionInput'
import { YoutubeTranscript } from 'types/transcript'
import { RequestMethod } from 'types/api'
//style
import captionCardStyle from 'styles/material_ui/components/captionCardStyle'
import { Container, DescriptionContainer, Footer } from './style'
import { API_TOKENIZATION } from 'env'

const useStyles = makeStyles(captionCardStyle)
const MAX_ROWS = 10

const CaptionCard = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const youtubeTranscript = useSelector(selectYoutubeTranscript)
  const dispatch = useDispatch()
  const captions = useSelector(selectCaptions)

  const [page, setPage] = useState(1)
  const [rowStartIndex, setRowStartIndex] = useState(0)
  const [rowEndIndex, setRowEndIndex] = useState(captions.length)

  const recursiveSegmentation = useCallback(
    async (queue) => {
      if (queue.isEmpty()) return
      else {
        const sentence = queue[0].sentence
        const index = queue[0].index
        const sentenceToSend = sentence.trim()
        if (isEmpty(sentenceToSend)) return
        dispatch(tokenizeStart())
        try {
          const tokenizedConfig = {
            method: RequestMethod.POST,
            url: API_TOKENIZATION,
            data: {
              sentence: sentenceToSend,
            },
          }
          const response = await axios(tokenizedConfig)
          const data = response.data.result
          data.forEach((word: any) => {
            if (word.hasOwnProperty('alternative')) {
              word.alternative.unshift({
                original_word: word.original_word,
                POS: word.POS || '',
                meaning: word.meaning || '',
                word_id: word.word_id,
              })
            }
          })
          dispatch(tokenizeSuccess(data, index))
          dispatch(stateCaptionWrapper('', index))
          dispatch(addChip(data, index))
          queue.dequeue()
          recursiveSegmentation(queue)
        } catch (err) {
          const message = err?.response?.data.error
          dispatch(
            showAlert(
              message || 'Tokenization Failure. Please try again',
              'error'
            )
          )
          dispatch(tokenizeFailure(message || 'tokenize action error'))
        }
      }
    },
    [dispatch]
  )

  const autoFillForm = useMemo(
    () => (transcript: Array<CaptionsToSave | YoutubeTranscript>) => {
      const queue = new Queue()
      let arr: Array<CaptionInput> = []
      transcript.forEach((caption, i) => {
        const { startTime, endTime } = getStartEndTime(
          caption.start,
          caption.duration
        )
        if ('tokens' in caption) {
          const obj = {
            start: startTime,
            end: endTime,
            subtitle: '',
            tokens: caption.tokens,
            focusedChip: null,
          }
          arr.push(obj)
        } else {
          const obj = {
            start: startTime,
            end: endTime,
            subtitle: caption.text,
            tokens: [],
            focusedChip: null,
          }
          queue.enqueue({ sentence: caption.text, index: i })
          arr.push(obj)
        }
      })
      dispatch(autoFillCaption(arr))
      recursiveSegmentation(queue)
    },
    [recursiveSegmentation, dispatch]
  )

  useEffect(() => {
    if (!isEmpty(youtubeTranscript)) {
      autoFillForm(youtubeTranscript)
    }
  }, [youtubeTranscript, autoFillForm])

  useEffect(() => {
    const { startIndex, endIndex } = getRowsStartEndIndex(page)
    setRowStartIndex(startIndex)
    setRowEndIndex(endIndex)
  }, [captions, page])

  const handlePageChange = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Fragment>
      <Tooltab />
      <Container>
        <DescriptionContainer>
          <Typography
            variant="h2"
            gutterBottom
            color="textSecondary"
            className={classes.exampleTypo}
          >
            {t('home.form.header.title')}
          </Typography>
          {pos.map((item, i) => (
            <PosChipDescribe
              pos={item.POS}
              label={item.label}
              key={`${item.label}_${i}`}
            />
          ))}
        </DescriptionContainer>
        {captions
          .slice(rowStartIndex, rowEndIndex + 1)
          .map((ele: CaptionInput, i: number) => {
            const temp = (page - 1) * 10
            const id = i + temp
            return <CaptionForm rowId={id} key={i} item={ele} />
          })}
      </Container>
      <Footer>
        <Pagination
          defaultValue={page}
          count={getTotalPage(captions, MAX_ROWS)}
          onChange={handlePageChange}
        />
      </Footer>
    </Fragment>
  )
}

export default CaptionCard
