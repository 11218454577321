import {
  InitialState,
  LoadingAcionTypes,
  LOADING_START,
  LOADING_STOP,
  LOADING_PLAYER_PAGE_START,
  LOADING_PLAYER_PAGE_STOP,
} from 'redux/loading/types'

const INITIAL_STATE: InitialState = {
  isLoad: false,
  isLoadPlayerPage: false
}

const loadingReducer = (
  state = INITIAL_STATE,
  action: LoadingAcionTypes
): InitialState => {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        isLoad: true,
      }
    case LOADING_PLAYER_PAGE_START:
      return {
        ...state,
        isLoadPlayerPage: true
      }
    case LOADING_STOP:
    case LOADING_PLAYER_PAGE_STOP:
      return INITIAL_STATE
    default:
      return state
  }
}

export default loadingReducer
