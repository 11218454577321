import {
  PLAY_ANIMATION,
  STOP_ANIMATION,
  ModelPlayerActionTypes,
  InitialState,
} from 'redux/modelPlayer/types'

const INITIAL_STATE: InitialState = {
  status: 1,
}

const modelPlayerReducer = (
  state = INITIAL_STATE,
  action: ModelPlayerActionTypes
): InitialState => {
  switch (action.type) {
    case PLAY_ANIMATION:
      return {
        status: -1,
      }
    case STOP_ANIMATION:
      return {
        status: 1,
      }
    default:
      return state
  }
}

export default modelPlayerReducer
