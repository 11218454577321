import axios from 'axios'
import { RequestMethod } from 'types/api'
import { API_TOKENIZATION } from 'env'

export const tokenization = async (sentence: string) => {
  const config = {
    method: RequestMethod.POST,
    url: API_TOKENIZATION,
    data: {
      sentence: sentence,
    },
  }
  try {
    const response = await axios(config)
    const data = response.data.result    
    data.forEach((word: any) => {
      if (word.hasOwnProperty('alternative')) {
        word.alternative.unshift({
          original_word: word.original_word,
          POS: word.POS || 'ไม่ระบุ',
          meaning: word.meaning || null,
          word_id: word.word_id || null,
        })
      }
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
