import {
  InitialState,
  KeypointActionTypes,
  FETCH_KEYPOINT_START,
  FETCH_KEYPOINT_SUCCESS,
  FETCH_KEYPOINT_FAILURE,
  FETCH_TIMELINE_SUCCESS,
  CLEAR_KEYPOINT,
} from 'redux/keypoint/types'

const INITIAL_STATE: InitialState = {
  loading: false,
  pose: {
    frame: {},
    pose: {},
    sentences: [],
  },
  time: [],
  timeline: [],
  error: '',
}

const keypointReducer = (
  state = INITIAL_STATE,
  action: KeypointActionTypes
): InitialState => {
  switch (action.type) {
    case FETCH_KEYPOINT_START:
      return { ...state, loading: true }
    case FETCH_KEYPOINT_SUCCESS:
      return { ...state, pose: action.pose, time: action.time, loading: false }
    case FETCH_KEYPOINT_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case FETCH_TIMELINE_SUCCESS:
      return { ...state, loading: false, timeline: action.timeline }
    case CLEAR_KEYPOINT:
      return INITIAL_STATE
    default:
      return state
  }
}

export default keypointReducer
