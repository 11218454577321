import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { Dispatch } from 'redux'
import {
  Time,
  KeypointActionTypes,
  FETCH_KEYPOINT_START,
  FETCH_KEYPOINT_SUCCESS,
  FETCH_KEYPOINT_FAILURE,
  FETCH_TIMELINE_SUCCESS,
  CLEAR_KEYPOINT,
} from 'redux/keypoint/types'
import { getStartAndDuration } from 'utils/time'
import { StartEndMoment } from 'types/moment'
import { RequestMethod } from 'types/api'
import { API_KEYPOINT } from 'env'
import { showAlert } from 'redux/alert/action'
import { Token } from 'types/token'
import { Keypoint, AllTimes } from 'types/model'

export const fetchKeypointStart = () => ({
  type: FETCH_KEYPOINT_START,
})

export const fetchKeypointSuccess = (
  pose: Keypoint,
  time: AllTimes[]
): KeypointActionTypes => ({
  type: FETCH_KEYPOINT_SUCCESS,
  pose: pose,
  time: time,
})

export const fetchKeypointFailure = (
  err: string
): KeypointActionTypes => ({
  type: FETCH_KEYPOINT_FAILURE,
  payload: err,
})

export const fetchTimelineSuccess = (
  timeline: Array<any>
): KeypointActionTypes => ({
  type: FETCH_TIMELINE_SUCCESS,
  timeline: timeline,
})

export const clearKeypoint = (): KeypointActionTypes => ({
  type: CLEAR_KEYPOINT,
})

export const getAnimationKeypoint = (
  wordList: Array<Array<Token>>,
  momentTimes?: Array<StartEndMoment>,
  commonTimes?: Time | Array<Time>
) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchKeypointStart())
    let animationTimes: Array<Time> = []    
    const config = {
      method: RequestMethod.POST,
      url: API_KEYPOINT,
      data: {
        sentences: wordList,
      },
    }
    if (momentTimes) {
      momentTimes.forEach((time) => {
        if (isEmpty(time)) return
        const { start, duration } = getStartAndDuration(time.start, time.end)
        if (duration === 0) {
          dispatch(showAlert('Start and End time cannot be the same', 'error'))
          return
        } else {
          animationTimes.push({
            start_time: start,
            duration: duration,
          })
        }
      })
    } else if (commonTimes) {
      if (Array.isArray(commonTimes)) {
        animationTimes = [...commonTimes]
      } else {
        animationTimes.push(commonTimes)
      }
    }
    if (isEmpty(animationTimes)) return
    try {
      const response = await axios(config)
      const data = response.data      
      dispatch(fetchKeypointSuccess(data, animationTimes))
    } catch (err) {
      const message = err.response?.data.error
      const defaultMessage = 'Something went wrong! Please try again.'
      dispatch(showAlert(message || defaultMessage, 'error'))
      dispatch(fetchKeypointFailure(message || defaultMessage))
    }
  }
}

export const getAnimationTimeline = (timeline: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchTimelineSuccess(timeline))
  }
}
