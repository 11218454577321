import { useState, useEffect, ChangeEvent } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { stateSearchWord } from 'redux/search/action'
import { RequestMethod } from 'types/api'
import { Token } from 'types/token'
import { API_SEARCH_WORD } from 'env'
import customAutocompleteSearch from 'styles/material_ui/components/customAutocompleteSearchStyle'

const useStyles = makeStyles(customAutocompleteSearch)

interface ResponseWord {
  id: number
  word: string
  POS: string
}

const CustomAutoCompleteSearch = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<Array<ResponseWord>>([])
  const [input, setInput] = useState<string>('')
  const [cache, setCache] = useState<Array<ResponseWord>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  
  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const queryWord = async (query: string) => {
    const url = `${API_SEARCH_WORD}/${query}`
    const config = { method: RequestMethod.GET, url }
    const cacheWords = _.map(cache, 'word')
    const isQueryInCache = cacheWords.includes(query)
    if (isQueryInCache) {
      const searchWord = cache.filter((el) => el.word.includes(query))
      setOptions(searchWord)
    } else {
      setLoading(true)
      try {
        const response = await axios(config)
        const data = response.data.result
        setLoading(false)
        setOptions(data)
        setCache(data)
      } catch (error) {
        setLoading(false)
        console.error('error', error)
      }
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (!_.isEmpty(value)) {
      queryWord(value)
    }
    setInput(value)
  }
  const debouncehandleInputChange = _.debounce(handleInputChange, 1000)

  const handleOptionSelected = (option: string, value: string) => {
    return option === value
  }

  const handleFilterOptions = (options: Array<string>) => {
    return options
  }

  const handleChange = (
    _event: ChangeEvent<any>,
    value: string | null,
    reason: any
  ) => {
    if (reason === 'select-option') {
      const selectedOption = _.find(cache, (item) => item.word === value)
      if (selectedOption) {
        const word: Token = {
          original_word: selectedOption.word,
          word_id: selectedOption.id,
          POS: selectedOption.POS
        }        
        dispatch(stateSearchWord(word))
      }
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getOptions = () => {
    const arr = [...options]
    const result = _.map(arr, 'word')
    return result
  }

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <Autocomplete
        openOnFocus={true}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        getOptionSelected={handleOptionSelected}
        filterOptions={handleFilterOptions}
        options={getOptions()}
        loading={loading}
        renderInput={(params) => (
          <InputBase
            {...params}
            ref={params.InputProps.ref}
            placeholder={t('search.input.placeholder')}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={debouncehandleInputChange}
            value={input}
            endAdornment={
              loading && <CircularProgress size={20} color="inherit" />
            }
          />
        )}
      />
    </div>
  )
}

export default CustomAutoCompleteSearch
