import styled, { css } from 'styled-components'
import { black, spaces, borders, subBlack, scene, fontWeights } from 'styles/mixins'
import { mediaBigDesktop } from 'styles/media'

export const Container = styled.div`
  background-color: ${black};
  width: 100%;
  height: 100%;
  padding-top: 2vw;
  ${mediaBigDesktop(css`
    padding-top: 4vw;
  `)}
`

export const ViewerSection = styled.div`
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: ${borders.default};
  background-color: ${scene};
`

export const VideoWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const tooltabStyle = css`
  width: 100%;
  border: 1px solid ${black};
  padding: ${spaces.normal};
`

export const HeaderTooltab = styled.div`
  ${tooltabStyle}
  display: flex;
  justify-content: flex-end;
`

export const Tooltab = styled.div`
  ${tooltabStyle}
  text-align: center;
  background-color: ${subBlack};
`

export const CustomizeBtnWrapper = styled.div`
  color: #fff;
`

export const CustomizePopoverWrapper = styled.div`
  padding: ${spaces.normal};
  max-width: 200px;
`

export const Title = styled.h4`
  font-weight: ${fontWeights.regular};
`

export const Section = styled.div`
  padding: ${spaces.normal};
`