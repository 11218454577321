import moment from 'moment'
import { Moment } from 'types/moment'

export const getStartEndTime = (start: number, duration: number) => {
  const result = {
    startTime: moment().hour(0).minute(0).second(0).millisecond(0),
    endTime: moment().hour(0).minute(0).second(0).millisecond(0),
  }
  const start_millisecond = start * 1000
  const duration_millisecond = duration * 1000
  result.startTime.set({
    millisecond: start_millisecond,
  })
  result.endTime.set({
    millisecond: start_millisecond + duration_millisecond,
  })
  return result
}

const convertMomentToSecond = (moment: Moment) => {
  let result =
    moment.get('hour') * 3600000 +
    moment.get('minute') * 60000 +
    moment.get('second') * 1000 +
    moment.get('millisecond')
  result = result / 1000
  return result
}

export const getStartAndDuration = (momentStart: Moment, momentEnd: Moment) => {
  const start = convertMomentToSecond(momentStart)
  const duration = parseFloat(
    (convertMomentToSecond(momentEnd) - start).toFixed(3)
  )
  return { start, duration }
}

export const randomDate = (start: any, end: any) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  )
}

const addZero = (i:number) => {
  let result = i.toString()
  if (i < 10){
    result = `0` + i
  }
  return result
}

export const getUpdatedAt = (date: Date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  return `${date.getDate()} ${
    months[date.getMonth()]
  } ${date.getFullYear()} ${addZero(date.getHours())}:${addZero(date.getMinutes())}`
}
