import { css, FlattenSimpleInterpolation } from 'styled-components'

//600px
export const mediaPhone = (content: FlattenSimpleInterpolation) => css`
  @media (max-width: 37.5em) {
    ${content}
  }
`

//900px
export const mediaTabPort = (content: FlattenSimpleInterpolation) => css`
  @media (max-width: 56.25em) {
    ${content}
  }
`

//1200px
export const mediaTabLand = (content: FlattenSimpleInterpolation) => css`
  @media (max-width: 75em) {
    ${content}
  }
`

//1800px
export const mediaBigDesktop = (content: FlattenSimpleInterpolation) => css`
  @media (max-width: 112.5em) {
    ${content}
  }
`

export const mediaPhoneLand = (content: FlattenSimpleInterpolation) => css`
 @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) { 
   ${content}
 }
`
