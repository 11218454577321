import { createSelector } from 'reselect'
import { RootState } from 'redux/root-reducer'

const selectKeypoint = (state: RootState) => state.keypoint

export const selectCurrentKeypoint = createSelector(
  [selectKeypoint],
  (keypoint) => keypoint.pose
)

export const loadingkeypoint = createSelector(
  [selectKeypoint],
  (keypoint) => keypoint.loading
)

export const selectAllTimes = createSelector(
  [selectKeypoint],
  (keypoint) => keypoint.time
)
