import { createGlobalStyle } from 'styled-components'
import { fontSizes, fontWeights, background, black } from './mixins'

const GlobalStyle = createGlobalStyle`

    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    html{
        font-size: 62.5%;
    }

    body {
        box-sizing: border-box;
        font-family: 'Roboto','Mitr', sans-serif;
        font-weight: ${fontWeights.light};
        font-size: ${fontSizes.normal};
        color: ${black};
        background: ${background};
    }

    h1, h2, h3, h4, h5, h6, button {
        font-weight: ${fontWeights.light};
    }

    a:link,a:visited {
        cursor: pointer;
        text-decoration: none;
    }

    p, div, a, span {
        word-break: break-word;
    }

    button {
        word-break: initial;
        font-family: 'Mitr', sans-serif !important;
        font-weight: ${fontWeights.light} !important;
    }
`

export default GlobalStyle
