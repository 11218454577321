import { Fragment, useState, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

import EditIcon from '@material-ui/icons/Edit'

import CustomIconButton from 'components/CustomIconButton'
import CustomDialog from 'components/CustomDialog'

import { RequestMethod } from 'types/api'

import { showAlert } from 'redux/alert/action'
import { API_WORD } from 'env'
import { FormWrapper } from './styles'
import WordForm from 'components/WordForm'

interface Props {
  id: number
  word: string
  pos: string
  meaning: string
  category: number
  variant: string
}

const EditWordDialog = ({
  id,
  word,
  pos,
  meaning,
  category,
  variant,
}: Props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [wordInfo, setWordInfo] = useState({
    word: word,
    pos: pos,
    meaning: meaning,
    category: category,
    variant: variant,
  })
  const { getAccessTokenSilently } = useAuth0()
  const history = useHistory()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const { value, name } = target
    if (name === 'category' && typeof value === 'string')
      setWordInfo({ ...wordInfo, [name]: parseInt(value) })
    else setWordInfo({ ...wordInfo, [name]: value })
  }

  const handleSave = async () => {
    if (id !== -1) {
      try {
        const accessToken = await getAccessTokenSilently()
        const config = {
          method: RequestMethod.PUT,
          url: API_WORD,
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
          data: {
            word_id: id,
            word: wordInfo.word,
            variant: wordInfo.variant,
            meaning: wordInfo.meaning || '',
            POS: wordInfo.pos,
            category: wordInfo.category,
          },
        }
        const res = await axios(config)
        if (res.status === 200) {
          dispatch(showAlert('Save success', 'success'))
          handleClose()
          history.go(0)
        }
      } catch (err) {
        console.error('Error', err)
      }
    }
  }

  return (
    <Fragment>
      <CustomIconButton
        tooltipLabel="Edit"
        svgIcon={<EditIcon />}
        arrow
        onClick={handleOpen}
        placement="top"
      />
      <CustomDialog
        title="Edit"
        open={open}
        onClose={handleClose}
        onSubmit={handleSave}
        submitLabel="Save"
      >
        <FormWrapper>
          <WordForm wordInfo={wordInfo} onChange={handleChange} />
        </FormWrapper>
      </CustomDialog>
    </Fragment>
  )
}

export default EditWordDialog
