import { Container, Spinner } from './style'

const SpinnerOverlay = () => {
  return (
    <Container>
      <Spinner />
      <br />
      Automatically generating caption from video. Please wait...
    </Container>
  )
}

export default SpinnerOverlay
