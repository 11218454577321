import styled from 'styled-components'
import { spaces } from 'styles/mixins'

export const Center = styled.div`
  margin: 0 auto;
  text-align: center;
`

export const Username = styled.h5`
  margin-top: ${spaces.normal};
`
