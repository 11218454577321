export const otherColor = {
  green: '#5cb85c',
  yellow: '#ffd31d',
  red: '#d92027',
  salmon: '#FB5252',
  blue: '#1273eb',
  dark: '#222831',
  primary: '#6d10cd',
  secondary: '#623dd6',
  accent2: '#576ae0',
  accent3: '#4d98ea',
  darkGreen: '#5b8a72',
  darkerGreen: '#56776c',
  darkestGreen: '#1e6f5c',
}

export const redPalette = {
  50: '#ffe3e8',
  100: '#feb9c6',
  200: '#fc8ba0',
  300: '#f85b7b',
  400: '#f33460',
  500: '#ee0245',
  600: '#de0045',
  700: '#c90042',
  800: '#b60041',
  900: '#94003e',
}

export const bluePalette = {
  50: '#e2f2ff',
  100: '#badeff',
  200: '#8dcaff',
  300: '#5ab5ff',
  400: '#2ea5ff',
  500: '#0095ff',
  600: '#0386ff',
  700: '#1274eb',
  800: '#1862d9',
  900: '#1c41b9',
}

export const orangePalette = {
  50: '#fff2df',
  100: '#ffddb0',
  200: '#ffc77d',
  300: '#ffaf49',
  400: '#ff9e22',
  500: '#ff8d00',
  600: '#fa8100',
  700: '#f47100',
  800: '#ee6002',
  900: '#e54304',
}

export const mintPalette = {
  50: '#dffafb',
  100: '#aef2f5',
  200: '#74eaf0',
  300: '#12e0eb',
  400: '#00d9e7',
  500: '#00d0e5',
  600: '#00c0d1',
  700: '#00aab6',
  800: '#00959d',
  900: '#007170',
}

export const purplePalette = {
  50: '#f2e7fd',
  100: '#ddc3f9',
  200: '#c79bf6',
  300: '#b06ef3',
  400: '#9d48f0',
  500: '#8912eb',
  600: '#7e08e5',
  700: '#6f00dc',
  800: '#6000d7',
  900: '#4600d0',
}

export const magentaPalette = {
  50: '#fbe4ef',
  100: '#f6bbd6',
  200: '#f290bb',
  300: '#ee62a0',
  400: '#ec3e8a',
  500: '#eb1274',
  600: '#d91270',
  700: '#c31269',
  800: '#ad1064',
  900: '#870d5a',
}

export const greenPalette = {
  50: '#f2fde4',
  100: '#defabb',
  200: '#c6f68d',
  300: '#aaf255',
  400: '#90ee02',
  500: '#75e900',
  600: '#61d800',
  700: '#41c300',
  800: '#09af00',
  900: '#008b00',
}

export const yellowPalette = {
  50: '#fdfde5',
  100: '#f9fac0',
  200: '#f6f795',
  300: '#f2f367',
  400: '#eeef3f',
  500: '#eeee02',
  600: '#efdb00',
  700: '#f0c200',
  800: '#f2a900',
  900: '#f37e00',
}

export const pinkPalette = {
  50: '#ffdef5',
  100: '#fface5',
  200: '#ff6ad5',
  300: '#f400bf',
  400: '#e400ad',
  500: '#d4009c',
  600: '#c6009a',
  700: '#b20096',
  800: '#a00092',
  900: '#7f008b',
}

export const gray = {
  50: '#FAFAFA',
  100: '#F3F4F5',
  200: '#EDEFF0',
  300: '#DCDFE0',
  500: '#999C9E',
  700: '#76797A',
}

export const black = '#232729'
export const subBlack = '#323232'
export const background = '#fcfcfc'
export const scene = '#28527A'
