import { Fragment } from 'react'
import { useDropzone } from 'react-dropzone'

import SvgIcon from '@material-ui/core/SvgIcon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { ListItemText } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ClearIcon from '@material-ui/icons/Clear'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import LinearProgress from '@material-ui/core/LinearProgress'

import { ReactComponent as UploadIcon } from 'assets/cloud-upload.svg'
import { Container, Subcontent, ProgressWrapper, Image } from './style'

interface Props {
  fileType: string 
  loading:boolean 
  progress:number 
  uploadingFile:string 
  onDrop: (acceptedFiles:File[]) => void 
  onRemoveFile: (file:File) => void
  myFiles: File[] 
  imageSource?: string 
  maxFiles?: number 
}
/**
 * The component that allow user to drag and drop or select files.
 * The accepted files will be an parameter of onDrop function.
 * (Use onDrop to get accepted files)
 * 
 * Props:
 * 
 *  fileType - Type of the file that you want to upload 
 * 
 *  loading - Is the file uploading
 * 
 *  progress - Progress of the uploading file (to specify the progress of LinearProgress component)
 * 
 *  uploadingFile - The name of uploading file
 * 
 *  onDrop - Function is called when user drop or select files
 * 
 *  onRemoveFile - Function is called when user click to remove the file
 * 
 *  myFiles - All accepted files
 * 
 *  imageSource(Optional) - Image file location for being an image overlay in Dropzone
 * 
 *  maxFiles(Optional) - The maximum files that are allowed
 * 
 */
const CustomBlendDropzone = ({
  maxFiles = 1,
  fileType,
  imageSource,
  loading,
  progress,
  uploadingFile,
  onDrop,
  onRemoveFile,
  myFiles,
}: Props) => {

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: fileType, onDrop, maxFiles: maxFiles })

  const renderLinearProgress = (name: string) => {
    if (uploadingFile === name)
      return <LinearProgress value={progress} variant="determinate" />
    else return <LinearProgress variant="determinate" value={0} />
  }

  const acceptedFileItems = myFiles.map((file) => (
    <ListItem key={`${file.name}-${file.size}`}>
      <ListItemIcon>
        <InsertDriveFileIcon />
      </ListItemIcon>
      <ListItemText>{file.name}</ListItemText>
      <ProgressWrapper>{renderLinearProgress(file.name)}</ProgressWrapper>
      <ListItemSecondaryAction>
        <IconButton onClick={() => onRemoveFile(file)} disabled={loading}>
          <ClearIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ))

  return (
    <Fragment>
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <Fragment>
          <input {...getInputProps()} />
          {imageSource ? (
            <Image src={imageSource} alt={fileType} />
          ) : (
            <SvgIcon style={{ fontSize: '60px' }}>
              <UploadIcon />
            </SvgIcon>
          )}
          <Subcontent>
            {`Drag and drop ${fileType} file here, or click to select`}
          </Subcontent>
          <Subcontent>{`(Only ${fileType} file will be accepted)`}</Subcontent>
        </Fragment>
      </Container>
      <br></br>
      <h4>{`Your ${fileType} Files`}</h4>
      <List>{acceptedFileItems}</List>
    </Fragment>
  )
}
export default CustomBlendDropzone
