import { Token } from 'types/token'

export const TOKENIZE_START = 'TOKENIZE_START'
export const TOKENIZE_SUCCESS = 'TOKENIZE_SUCCESS'
export const TOKENIZE_FAILURE = 'TOKENIZE_FAILURE'
export const CLEAR_TOKENIZE = 'CLEAR_TOKENIZE'
export interface InitialState {
  loading: boolean
  data: Array<Token>
  index: number
  error: string
}

interface Alternative {
  original_word: string
  word_id: number
  meaning: string
  POS: string
}

export interface TokenizationResponse {
  original_word: string
  word_id: number
  meaning: string
  POS: string
  alternative: Alternative[]
  valid: boolean
  total_frames: number
}

interface TokenizeStart {
  type: typeof TOKENIZE_START
}

interface TokenizeSuccess {
  type: typeof TOKENIZE_SUCCESS
  payload: Array<Token>
  index: number
}

interface TokenizeFailure {
  type: typeof TOKENIZE_FAILURE
  payload: string
}

interface ClearTokenizeWord{
  type: typeof CLEAR_TOKENIZE
}

export type TokenizationActionTypes =
  | TokenizeStart
  | TokenizeSuccess
  | TokenizeFailure
  | ClearTokenizeWord
