import { createStyles } from '@material-ui/core'
import { fontWeights, otherColor } from 'styles/mixins'

const captionFormStyle = createStyles({
  longForm: {
    flexGrow: 10,
  },
  inputForm: {
    margin: '0 12px',
    flexGrow: 1,
    fontSize: '16px',
    fontWeight: parseInt(fontWeights.light),
  },
  iconBtnWrapper: {
    width: '30px',
    height: '30px',
    fontSize: '24px',
  },
  previewAnimationBtn: {
    fontSize: '24px',
  },
  chipInputRoot: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    flex: 1,
    marginTop: 0,
    minWidth: 70,
    boxSizing: 'border-box',
  },
  chipInput: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    appearance: 'none', // Remove border in Safari, doesn't seem to break anything in other browsers
    WebkitTapHighlightColor: 'rgba(0,0,0,0)', // Remove mobile color flashing (deprecated style).
    float: 'left',
    flex: 1,
    fontWeight: parseInt(fontWeights.light),
  },
  addBtn: {
    color: otherColor.accent2,
  },
  removeBtn: {
    color: otherColor.salmon,
  },
})

export default captionFormStyle
