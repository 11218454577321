import styled, { css } from 'styled-components'
import { fontSizes, fontWeights, otherColor, gray } from './mixins'

export const primaryTitle = css`
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.large6};
  span {
    color: ${otherColor.yellow};
  }
`

export const secondaryTitle = css`
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.large1};
`

export const thaiTypo = css`
  font-family: 'Mitr', sans-serif;
  font-weight: ${fontWeights.light};
`

export const ellipsisText = (line: number) => css`
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  ${line > 1
    ? css`
        display: -webkit-box;
        -webkit-line-clamp: ${line};
        -webkit-box-orient: vertical;
      `
    : css`
        white-space: nowrap;
      `};
`

export const PrimaryTitle = styled.h1`
  ${primaryTitle}
`

export const SecondaryTitle = styled.h3`
  ${secondaryTitle}
`

export const ThaiTypo = styled.span`
  ${thaiTypo}
`

export const SubContent = styled.span`
  color: ${gray[500]};
`
