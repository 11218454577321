import { useState, ChangeEvent, Fragment, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Popover from '@material-ui/core/Popover'

import SaveIcon from '@material-ui/icons/Save'
import ShareIcon from '@material-ui/icons/Share'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

import LinkSharing from 'components/LinkSharing'
import CustomButton from 'components/CustomButton'

// import { usePrevious } from 'utils/hook'
import { selectCaptions } from 'redux/captionInput/selector'
import {
  selectProjectInfo,
  // selectDirtyBit,
  selectSaveStatus,
} from 'redux/project/selector'
import {
  changeProjectName,
  setDirtyBit,
  saveProject,
  // resetSaveStatus,
} from 'redux/project/action'

import { Flex, Box } from './style'
import tooltabStyle from 'styles/material_ui/components/tooltabStyle'

const useStyles = makeStyles(tooltabStyle)

const Tooltab = () => {
  const classes = useStyles()
  const [error, setError] = useState('')
  const [shareAnchorEl, setShareAnchorEl] = useState<any>(null)
  const captions = useSelector(selectCaptions)
  const project = useSelector(selectProjectInfo)
  // const dirtyBit = useSelector(selectDirtyBit)
  const saveLabel = useSelector(selectSaveStatus)
  // const previousDirtyBit = usePrevious(dirtyBit)
  const { name, project_id, youtube_id } = project
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()

  // useEffect(() => {
  //   // if user make changed wait 1 min then, save
  //   if (dirtyBit) {
  //     if (!previousDirtyBit) {
  //       dispatch(resetSaveStatus())
  //        // BUG HERE. When user leave page but this function is already triggered. So, it save again after 1 min.
  //       setTimeout(handleSave, 60000)
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dirtyBit])

  const handleChangeSubtitleName = (event: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError('')
    }
    dispatch(setDirtyBit())
    dispatch(changeProjectName(event.target.value))
  }

  const handleSave = async () => {
    if (isEmpty(name)) {
      setError('Please enter subtitle name')
    } else {
      const accessToken = await getAccessTokenSilently()
      dispatch(saveProject(name, youtube_id, project_id, captions, accessToken))
    }
  }

  const getDisabledLink = () => {
    if (youtube_id) return false
    else return true
  }

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setShareAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setShareAnchorEl(null)
  }

  const goToViewer = () => {
    const win = window.open(`/player/${project_id}`, '_blank')
    win?.focus()
  }

  const shareOpen = Boolean(shareAnchorEl)
  const shareId = shareOpen ? 'share-popover' : undefined

  return (
    <div className={classes.root} style={{display: "block"}}>
      <Box>
      <Flex>
        {!getDisabledLink() && (
          <Fragment>
            <CustomButton
              startIcon={<PlayArrowIcon />}
              myStyles={`purpleoutlined`}
              onClick={goToViewer}
              style={{marginLeft: "0px"}}
            >
              {t('home.tooltab.animation')}
            </CustomButton>
            <CustomButton
              myStyles={`black`}
              startIcon={<ShareIcon />}
              onClick={handleOpen}
            >
              {t('player.header.tooltab.share')}
            </CustomButton>
            <Popover
              id={shareId}
              open={shareOpen}
              anchorEl={shareAnchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LinkSharing
                value={`${window.location.origin}/player/${project_id}`}
              />
            </Popover>
          </Fragment>
        )}
      </Flex>
      <Flex>
        <TextField
          label="Name"
          variant="outlined"
          name="subtitleName"
          margin="dense"
          value={name}
          onChange={handleChangeSubtitleName}
          error={!isEmpty(error)}
          helperText={error}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.buttonStyle}
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          {saveLabel}
        </Button>
      </Flex>
    </Box>
    </div>
  )
}
export default Tooltab
