import { YoutubeTranscript } from 'types/transcript'
import { CaptionsToSave } from 'types/captionInput'

export const GET_TRANSCRIPT_START = 'GET_TRANSCRIPT_START'
export const GET_TRANSCRIPT_SUCCESS = 'GET_TRANSCRIPT_SUCCESS'
export const GET_TRANSCRIPT_FAILURE = 'GET_TRANSCRIPT_FAILURE'
export const CLEAR_TRANSCRIPT = 'CLEAR_TRANSCRIPT'
export interface InitialState {
  loading: boolean
  transcript: Array<YoutubeTranscript> | Array<CaptionsToSave>
  error: string
}

interface RetrieveTranscriptStart {
  type: typeof GET_TRANSCRIPT_START
}

interface RetrieveTranscriptSuccess {
  type: typeof GET_TRANSCRIPT_SUCCESS
  payload: Array<YoutubeTranscript> | Array<CaptionsToSave>
}

interface RetrieveTranscriptFailure {
  type: typeof GET_TRANSCRIPT_FAILURE
  payload: string
}

interface ClearTranscript {
  type: typeof CLEAR_TRANSCRIPT
}

export type YoutubeTranscriptActionTypes =
  | RetrieveTranscriptStart
  | RetrieveTranscriptSuccess
  | RetrieveTranscriptFailure
  | ClearTranscript
