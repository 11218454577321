import styled from 'styled-components'
import { spaces, fontSizes } from 'styles/mixins'
import { CardStyle } from 'styles/card'

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: ${spaces.normal} ${spaces.large1};
  display: flex;
  align-items: center;
  ${CardStyle}
`

export const IconWrapper = styled.div`
  margin-right: ${spaces.normal};
`

export const SearchBox = styled.input`
  width: 100%;
  border: none;
  font-size: ${fontSizes.normal};
  padding: ${spaces.normal};
  background: transparent;
  &:focus {
    outline: none;
  }
`
