import isEmpty from 'lodash/isEmpty'
import { AllTimes, Keypoint, Timeline } from 'types/model'
import * as THREE from 'three'

const FPS = 15

const timeToFrame = (time: number) => {
  let frame = 0
  frame = time / (1 / FPS)
  return frame
}
const buildTimeline = (
  keypoint: Keypoint,
  allTimes: AllTimes[],
  isEditPage: boolean | undefined,
  startPose: number[][]
) => {
  const { frame, pose, sentences } = keypoint
  const filteredSentences = sentences.map((array_1d) => {
    return array_1d.filter((el) => el != null)
  })

  let timeline: Timeline = {}
  let timelineKeySorted: number[] = []
  let currentFrame = 0
  let gap = 0
  // convert start pose to quaternion
  const arrstartQuaternion = []

  for (let i = 0; i < startPose.length; i++) {
    let startPoseQuaternion = new THREE.Quaternion().set(
      startPose[i][0],
      startPose[i][1],
      startPose[i][2],
      startPose[i][3]
    )

    let arrQua = []
    arrQua.push(startPoseQuaternion.x)
    arrQua.push(startPoseQuaternion.y)
    arrQua.push(startPoseQuaternion.z)
    arrQua.push(startPoseQuaternion.w)
    arrstartQuaternion.push(arrQua)
  }
  if (!isEmpty(filteredSentences) && !isEmpty(filteredSentences[0]) && !isEmpty(allTimes)) {
    timeline[0] = arrstartQuaternion
    for (let i = 0; i < filteredSentences.length; i++) {
      // calcalate number of frame use in this sentence
      let total_sentence_frame = 0
      const betweenPose = 10
      const copyLastPose = 5
      for (const word_id of filteredSentences[i]) {
        total_sentence_frame += betweenPose
        total_sentence_frame += copyLastPose

        if (frame[word_id].length !== 0) {
          const lastFrameIndex = frame[word_id][frame[word_id].length - 1]
          const firstFrameIndex = frame[word_id][0]
          total_sentence_frame += lastFrameIndex - firstFrameIndex
        }
      }

      // calculate speed factor (we don't sign slower than normal speed if there is time left instead we rest at start pose)
      const speed_factor = Math.max(
        1,
        total_sentence_frame / timeToFrame(allTimes[i].duration)
      )
      gap = 10 / speed_factor
      // shift to start at time 0 in the editing page
      currentFrame = isEditPage
        ? timeToFrame(0)
        : timeToFrame(allTimes[i].start_time)
      // add pose of each word to the timeline
      for (const word_id of filteredSentences[i]) {
        if (frame[word_id].length !== 0) {
          currentFrame += gap
          const firstFrameIndex = frame[word_id][0]
          let relativeFrame = 0
          for (let i = 0; i < frame[word_id].length; i++) {
            relativeFrame = (frame[word_id][i] - firstFrameIndex) / speed_factor
            timeline[currentFrame + relativeFrame] = pose[word_id][i]
          }
          currentFrame += relativeFrame
          currentFrame += copyLastPose / speed_factor
          timeline[currentFrame] = pose[word_id][pose[word_id].length - 1]
        }
      }
      // insert start pose if the next frame start after current frame + gap
      if (i < filteredSentences.length - 1) {
        const diff = timeToFrame(allTimes[i + 1].start_time) - currentFrame
        if (diff > gap) {
          timeline[currentFrame + Math.min(diff / 2, gap)] = arrstartQuaternion
          timeline[
            timeToFrame(allTimes[i + 1].start_time) - Math.min(diff / 2, gap)
          ] = arrstartQuaternion
        }
      }
    }
    timeline[currentFrame + gap] = arrstartQuaternion
    timelineKeySorted = Object.keys(timeline)
      .map((e) => parseFloat(e))
      .sort((a, b) => a - b)
  }
  return { timeline, timelineKeySorted }
}

export default buildTimeline
