import React, { useRef } from 'react'
import { useFrame } from 'react-three-fiber'

const Box = ({ ...props }) => {
  return (
    <mesh {...props}>
      <icosahedronBufferGeometry attach="geometry" args={[10, 1]} />
      <meshBasicMaterial attach="material" color="lightblue" wireframe />
    </mesh>
  )
}
const Loading = ({ ...props }) => {
  const mesh = useRef()
  useFrame(() => {
    if (mesh.current !== null) {
      mesh.current.rotation.x = mesh.current.rotation.y += 0.02
    }
  })
  return (
    <group ref={mesh} scale={[1, 1, 1]}>
      <Box position={[0, 0, 0]} />
    </group>
  )
}
export default Loading
