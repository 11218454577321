import { ChangeEvent, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { Container, IconWrapper, SearchBox } from './style'

interface Props {
  source: any[]
  onSearch: (result: any[]) => void
}

const CustomSearch = ({ source, onSearch }: Props) => {
  const [query, setQuery] = useState('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const value = target.value
    if (value) {
      const temp = source.filter((item) => item.word.includes(value))
      onSearch(temp)
    }else{
      onSearch(source)
    }
    setQuery(value)
  }

  return (
    <Container>
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      <SearchBox
        placeholder="Search..."
        onChange={handleChange}
        value={query}
      />
    </Container>
  )
}

export default CustomSearch
