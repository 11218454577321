export const CHANGE_CHARACTER = 'CHANGE_CHARACTER'
export const CHANGE_SKIN_COLOR = 'CHANGE_SKIN_COLOR'
export interface InitialState{
    currentCharacter: string,
    currentSkinColor: string,
}

interface ChangeCharacter {
    type: typeof CHANGE_CHARACTER,
    payload: string
}

interface ChangeSkinColor {
    type: typeof CHANGE_SKIN_COLOR,
    payload: string
}

export type CharacterActionTypes = ChangeCharacter | ChangeSkinColor