import { ReactNode } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CustomButton from 'components/CustomButton'

interface Props {
  onAgree?: () => void
  onDisagree?: () => void
  handleClose: () => void
  title: string
  content: ReactNode
  open: boolean
  enableAction?: boolean
}

const CustomAlertDialog = ({
    title,
  onAgree,
  onDisagree,
  open,
  handleClose,
  content,
  enableAction = true,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      {enableAction && (
        <DialogActions>
          <CustomButton onClick={onDisagree} myStyles='black'>No</CustomButton>
          <CustomButton onClick={onAgree} myStyles='redcontained'>
            Yes
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default CustomAlertDialog
