import { css } from 'styled-components'
import { borders } from './mixins'

export const CardStyle = css`
  width: 100%;
  border-radius: ${borders.round};
  background: white;
  box-shadow: 0px 2px 6px 1px rgba(98, 61, 214, 0.2);
  overflow: hidden;
`
