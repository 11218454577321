import { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import {
  SpeechRecognitionEventArgs,
  Recognizer,
  ResultReason,
  SpeechRecognizer,
} from 'microsoft-cognitiveservices-speech-sdk'
import MicIcon from '@material-ui/icons/Mic'
import CustomIconButton from 'components/CustomIconButton'
import StopIcon from '@material-ui/icons/Stop'
import Navbar from 'components/Navbar'
import Threescene from 'components/ThreeScene'
import Queue from 'utils/queue'
import { getAnimationKeypoint } from 'redux/keypoint/action'
import { tokenization } from 'utils/live'
import { getSpeechTokenOrRefresh } from 'azure/speech'
import { Container, SceneWrapper } from './style'

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

const LivePage = () => {
  const [displayText, setDisplayText] = useState('')
  const [listen, setListen] = useState(false)
  const [speechRecognizer, setSpeechReconizer] = useState<
    SpeechRecognizer | undefined
  >()
  const dispatch = useDispatch()

  const recursiveTextToAnimation = async (sentence: string, queue: Queue) => {
    if (queue.isEmpty()) return
    const tokenizedWord = await tokenization(sentence)    
    const filteredWord = tokenizedWord.filter((word: any) =>
      word.hasOwnProperty('POS')
    )
    
    if (filteredWord.length !== 0) {        
        const times = {
          start_time: 0,
          duration: 0,
        }
        const len = filteredWord.length
        const dura = len + 0.5
        times.duration = dura        
        queue.dequeue()
        dispatch(getAnimationKeypoint([filteredWord], undefined, times))
      
    }
  }

  const sttFromMic = async () => {
    const queue = new Queue()
    setDisplayText('')
    const tokenObj = await getSpeechTokenOrRefresh()
    if (tokenObj) {
      const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
        tokenObj?.authToken,
        tokenObj?.region
      )
      speechConfig.speechRecognitionLanguage = 'th-TH'

      const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput()
      const recognizer = new speechsdk.SpeechRecognizer(
        speechConfig,
        audioConfig
      )
      setSpeechReconizer(recognizer)
      setDisplayText('speak into your microphone...')

      recognizer.startContinuousRecognitionAsync(() => setListen(true))
      recognizer.recognizing = (
        _sender: Recognizer,
        event: SpeechRecognitionEventArgs
      ) => {
        const recognizingText = event.result.text
        setDisplayText(recognizingText)
      }
      recognizer.recognized = (
        _sender: Recognizer,
        event: SpeechRecognitionEventArgs
      ) => {
        const result = event.result
        const recognizedText = result.text
        if (recognizedText) {
          if (result.reason === ResultReason.RecognizedSpeech) {
            queue.enqueue(recognizedText)
            setDisplayText(recognizedText)
            recursiveTextToAnimation(recognizedText, queue)
          }
        }
      }
    }
  }

  const stopMic = () => {
    speechRecognizer?.stopContinuousRecognitionAsync(() => {
      setListen(false)
    })
  }

  return (
    <Fragment>
      <Navbar />
      <Container>
        <p>{displayText}</p>
        <SceneWrapper>
          <Threescene playerState={-1} gender='male'/>
        </SceneWrapper>
        {listen ? (
          <CustomIconButton
            svgIcon={<StopIcon />}
            tooltipLabel="Stop"
            onClick={stopMic}
            color="primary"
            iconSize="48px"
          />
        ) : (
          <CustomIconButton
            svgIcon={<MicIcon />}
            tooltipLabel="Start Listening"
            onClick={sttFromMic}
            color="primary"
            iconSize="48px"
          />
        )}
        <h5>Press microphone for displaying sign language as realtime</h5>
      </Container>
    </Fragment>
  )
}

export default LivePage
