import { Fragment, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TranslateIcon from '@material-ui/icons/Translate'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import SearchIcon from '@material-ui/icons/Search'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import CustomDropdown from 'components/CustomDropdown'
import AccountMenu from 'components/AccountMenu'
import { selectCurrentLanguage } from 'redux/language/selector'

import { Language } from 'types/language'
import navbarStyle from 'styles/material_ui/components/navbarStyle'
import { Center } from './style'

interface Props {
  handleGoToSearch: () => void
  options: Array<string>
  changeLanguage: (event: MouseEvent, value: Language) => void
  handleLogin: () => void
  handleLogout: () => void
}

const useStyles = makeStyles(navbarStyle)

const Mobile = ({
  handleGoToSearch,
  options,
  changeLanguage,
  handleLogin,
  handleLogout,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const currentLang = useSelector(selectCurrentLanguage)

  const languageDropdown = (
    <ListItem>
      <Center>
        <CustomDropdown
          startIcon={<TranslateIcon />}
          options={options}
          handleClickItem={changeLanguage}
        >
          {currentLang}
        </CustomDropdown>
      </Center>
    </ListItem>
  )

  return (
    <Fragment>
      {isAuthenticated ? (
        <List>
          <AccountMenu onLogout={handleLogout} mobile />
          <Divider />
          {languageDropdown}
        </List>
      ) : (
        <List>
          <ListItem>
            <Center>
              <IconButton size="small">
                <Avatar />
              </IconButton>
            </Center>
          </ListItem>
          <ListItem button onClick={handleGoToSearch}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText>{t('navbar.search')}</ListItemText>
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogin} className={classes.login}>
            <ListItemIcon>
              <ArrowForwardIcon />
            </ListItemIcon>
            <ListItemText>{t('navbar.login')}</ListItemText>
          </ListItem>
          <Divider />
          {languageDropdown}
        </List>
      )}
    </Fragment>
  )
}

export default Mobile
