import styled, { keyframes } from 'styled-components'
import { otherColor, fontWeights } from 'styles/mixins'

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const Container = styled.div`
  position: relative;
  font-weight: ${fontWeights.bold};
  width: 100%;
  left: 0;
  z-index: 9999;
  text-align: center;
  opacity: 0.8;
`

export const Spinner = styled.div`
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: ${rotate} 0.8s infinite linear;
  border: 5px solid ${otherColor.primary};
  border-right-color: transparent;
  border-radius: 50%;
`
