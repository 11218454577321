import styled, {css} from 'styled-components'
import { Link } from 'react-router-dom'
import {
  otherColor,
  fontWeights,
  fontSizes,
  spaces,
  borders,
} from 'styles/mixins'
import { mediaTabPort } from 'styles/media'

export const CustomLink = styled(Link)`
  font-family: 'Mitr', 'Roboto', sans-serif;
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.normal};
  color: ${otherColor.accent2};
  padding: ${spaces.small1} ${spaces.normal};
  border-radius: ${borders.default};
  border: 1px solid ${otherColor.accent2};
  transition: all 0.2s;
  display: inline-flex;
  position: relative;
  &:hover {
    color: #fff;
    background-color: ${otherColor.accent2};
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  ${mediaTabPort(css`
    padding-top: 5px;
  `)}
`

// TODO: maybe add this as a property of "CustomButton"
export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaTabPort(css`
    display: grid;
    justify-content: center;
  `)}
`