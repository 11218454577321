import {
    InitialState, LanguageActionTypes, SWITCH_LANGUAGE
} from 'redux/language/types'

const INITIAL_STATE: InitialState = {
    lang: 'English',
}

const languageReducer = (
    state = INITIAL_STATE,
    action: LanguageActionTypes
): InitialState => {
    switch (action.type) {
        case SWITCH_LANGUAGE:
            return {
                lang: action.payload,
            }
        default:
            return state
    }
}

export default languageReducer
