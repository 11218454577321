import { ChangeEvent} from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { posTh } from 'utils/partOfSpeech'
import { useCategory } from 'utils/hook'

interface WordInfo {
  word: string
  pos: string
  meaning: string
  category: number
  variant: string
}

interface Props {
  wordInfo: WordInfo
  onChange: (e: ChangeEvent) => void
}

const WordForm = ({ wordInfo, onChange }: Props) => {
  const categoryOptions = useCategory()
  const { word, pos, meaning, category, variant } = wordInfo

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          label="New Word"
          variant="outlined"
          placeholder="Type the word you want to add here..."
          onChange={onChange}
          value={word}
          name="word"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Variant"
          variant="outlined"
          placeholder="เช่น สิว(เขรอะ) ให้ใส่คำว่า เขรอะ"
          onChange={onChange}
          value={variant}
          name="variant"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Part of speech"
          variant="outlined"
          SelectProps={{ native: true }}
          name="pos"
          value={pos}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          onChange={onChange}
          select
        >
          <option value="">Please select part of speech</option>
          {posTh.map((option) => (
            <option
              value={option.value}
              key={`${option.value}-${option.label}`}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Category"
          SelectProps={{ native: true }}
          variant="outlined"
          name="category"
          value={category}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChange}
          fullWidth
          select
        >
          <option value={-1}>Please select category</option>
          {categoryOptions.length !== 0 &&
            categoryOptions.map((ele) => (
              <option value={ele.id} key={`${ele.id}-${ele.en}`}>
                {ele.th}
              </option>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Type meaning of the word here..."
          variant="outlined"
          label="Meaning"
          value={meaning}
          name="meaning"
          onChange={onChange}
          rows={3}
          multiline
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default WordForm
