import styled from 'styled-components'
import { otherColor, spaces } from 'styles/mixins'
import { WORD_STATUS } from 'types/wordStatus'

interface StatusLabelProps{
  status: WORD_STATUS
}

const getStatusColor = (status: WORD_STATUS) => {
  switch (status) {
    case WORD_STATUS.VALIDATING:
      return otherColor.blue
    case WORD_STATUS.READY:
      return otherColor.green
    case WORD_STATUS.REJECT:
      return otherColor.red
    default:
      return '#000'
  }
}

export const PageContainer = styled.div`
  margin: ${spaces.normal} auto;
  max-width: 80%;
`

export const TableWrapper = styled.div`
  height: 80vh;
`

export const SearchWrapper = styled.div`
  margin-bottom: ${spaces.normal};
`

export const Link = styled.a`
  color: ${otherColor.blue};
`

export const StatusLabel = styled.p<StatusLabelProps>(
  ({status}) => `
  color: ${getStatusColor(status)};
  text-transform: capitalize;
  `
)
