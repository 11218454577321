import {
  InitialState,
  SearchActionTypes,
  STATE_SEARCH_WORD,
} from 'redux/search/types'

const INITIAL_STATE: InitialState = {
  foundWord: undefined,
}

const searchReducer = (
  state = INITIAL_STATE,
  action: SearchActionTypes
): InitialState => {
  switch (action.type) {
    case STATE_SEARCH_WORD:
      return {
        foundWord: action.payload,
      }
    default:
      return state
  }
}

export default searchReducer
