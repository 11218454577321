import styled, { css } from 'styled-components'
import { mediaTabLand } from 'styles/media'
import { spaces } from 'styles/mixins'

export const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 2;
  margin: ${spaces.normal} 0;
  align-items: center;
  ${mediaTabLand(css`
    display: block;
  `)}
`

export const FlexBox = styled.div`
  display: flex;
  margin: ${spaces.normal} 0;
  min-width: 0;
  width 100%;
`
