import { fontSizes, spaces } from 'styles/mixins'

const customDropdownStyle = (theme) => ({
  caret: {
    fontSize: fontSizes.large2,
    marginLeft: spaces.normal,
  },
  icon: {
    fontSize: fontSizes.large2,
    marginRight: spaces.normal,
  },
})

export default customDropdownStyle
