import { ReactNode } from 'react'
import { withStyles } from '@material-ui/core'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
interface Props extends IconButtonProps {
  tooltipLabel: string
  svgIcon: ReactNode
  placement?: 'top' | 'bottom'
  iconSize?: string
  arrow?: boolean
}
const MyTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
    fontWeight: 300,
  },
}))(Tooltip)

const CustomIconButton = ({
  tooltipLabel,
  onClick,
  svgIcon,
  placement,
  iconSize,
  color,
  arrow,
  ...props
}: Props) => {
  return (
    <MyTooltip title={tooltipLabel} placement={placement} arrow={arrow}>
      <span>
        <IconButton onClick={onClick} color={color} {...props}>
          <SvgIcon style={{ fontSize: iconSize }}>{svgIcon}</SvgIcon>
        </IconButton>
      </span>
    </MyTooltip>
  )
}

export default CustomIconButton
