import { createStyles } from '@material-ui/core'
import {
  spaces,
  borders,
  redPalette,
  fontSizes,
  fontWeights,
} from 'styles/mixins'

const tooltabStyle = () => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spaces.large2,
    boxShadow: '0px 2px 6px 1px rgba(98, 61, 214, 0.2);',
    borderRadius: borders.default,
    alignItems: 'center',
  },
  buttonStyle: {
    margin: '0 12px',
    textTransform: 'capitalize',
  },
  redColor: {
    color: redPalette[400],
  },
  link: {
    fontSize: fontSizes.normal,
    fontWeight: parseInt(fontWeights.light),
    color: redPalette[400],
    borderColor: redPalette[400],
    '&:hover': {
      backgroundColor: redPalette[400],
      color: '#fff',
    },
  },
  playIcon: {
    fontSize: fontSizes.large3,
    marginRight: spaces.small1,
  },
})

export default tooltabStyle
