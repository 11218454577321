import moment from 'moment'
import { Token } from 'types/token'
import { Moment } from 'types/moment'
import { CaptionInput } from 'types/captionInput'

export const changeSubtitle = (
  captions: CaptionInput[],
  msg: string,
  rowId: number
) => {
  let arr = [...captions]
  arr[rowId].subtitle = msg
  return arr
}

export const addNewToken = (
  captions: CaptionInput[],
  tokenToAdd: Token[],
  rowId: number
) => {
  let arr = [...captions]
  tokenToAdd.forEach((newToken: Token) => {
    arr[rowId].tokens.push({ ...newToken, isClicked: false })
  })
  return arr
}

export const deleteToken = (
  captions: CaptionInput[],
  tokenIdToDelete: number,
  rowId: number
) => {
  let arr = [...captions]
  arr[rowId].tokens.forEach((_token: Token, i: number) => {
    if (i === tokenIdToDelete) {
      arr[rowId].tokens.splice(tokenIdToDelete, 1)
    }
  })
  return arr
}

export const changeFocusToken = (
  captions: CaptionInput[],
  newFocusIndex: number | null,
  rowId: number
) => {
  let arr = [...captions]
  arr[rowId].focusedChip = newFocusIndex
  return arr
}

export const changeWord = (
  captions: CaptionInput[],
  rowId: number,
  indexOfToken: number,
  tokenToReplace: Token
) => {
  let arr = [...captions]
  arr[rowId].tokens.forEach((token: Token, i: number) => {
    if (i === indexOfToken) {
      token.original_word = tokenToReplace.original_word
      token.meaning = tokenToReplace.meaning
      token.POS = tokenToReplace.POS
      token.word_id = tokenToReplace.word_id
    }
  })
  return arr
}

export const addRow = (captions: CaptionInput[], rowId: number) => {
  let arr = [...captions]
  arr.splice(rowId + 1, 0, {
    start: moment().hour(0).minute(0).second(0).millisecond(0),
    end: moment().hour(0).minute(0).second(0).millisecond(0),
    subtitle: '',
    tokens: [],
    focusedChip: null,
  })
  return arr
}

export const deleteRow = (captions: CaptionInput[], rowId: number) => {
  let arr = [...captions]
  if (arr.length > 1) {
    arr.splice(rowId, 1)
  }
  return arr
}

export const changeTime = (
  captions: CaptionInput[],
  timeToChange: Moment,
  rowId: number,
  name: 'start' | 'end'
) => {
  let arr = [...captions]
  arr[rowId][name] = timeToChange
  return arr
}

export const autoFill = (
  captions: CaptionInput[],
  newCaption: Array<CaptionInput>
) => {
  let arr = [...captions]
  arr = newCaption
  return arr
}

export const moveToken = (captions: CaptionInput[],rowId:number,from:number,to:number) => {
  let arr = [...captions]
  const tokens = arr[rowId].tokens
  const tokensLength = tokens.length
  tokens.splice(to < 0 ? tokensLength + to : to, 0, tokens.splice(from, 1)[0])  
  return arr
}
