import { useAuth0 } from "@auth0/auth0-react";
import { Box, Card, CardContent, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import CustomButton from "components/CustomButton";
import { Logo } from "components/Navbar/style";
import Justsigns from 'assets/justsigns.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#ffffff'
    },
    card: {
      padding: theme.spacing(2),
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 12px 40px rgba(0,0,0,0.12)'
    }
  }),
);

interface EmailNotVerifiedPageProp {
  email: string
}

const EmailNotVerifiedPage = (props: EmailNotVerifiedPageProp) => {
  const { loginWithRedirect, logout } = useAuth0()

  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      className={classes.root}
    >
      <Card className={classes.card}>
        <CardContent>
          <div style={{ textAlign: 'center' }}>
            <Box m={2}>
              <Logo src={Justsigns} alt="logo" />
            </Box>
            <Box mt={4} >
              <Typography variant="h5" gutterBottom >
                Verify Your Email Address
              </Typography>
            </Box>
            <Box m={2} >
              <Typography variant="body1" gutterBottom >
                To continue using JustSigns, please verified your email address:
              </Typography>
            </Box>
            <Box m={2} >
              <Typography variant="body1" gutterBottom >
                <b>{props.email}</b>
              </Typography>
            </Box>
            <Box mt={4} >
              <CustomButton onClick={() => logout()} myStyles={`redoutlined`}>
                Cancel
              </CustomButton>
              <CustomButton onClick={async () => await loginWithRedirect()} myStyles={`greenoutlined`}>
                Continue
              </CustomButton>
            </Box>
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}

export default EmailNotVerifiedPage
