import { createStyles, Theme } from '@material-ui/core'
import { otherColor, fontSizes, fontWeights } from 'styles/mixins'

const accountMenuStyle = (theme: Theme) => createStyles({
  subheader: {
    fontSize: fontSizes.small1,
    fontWeight: parseInt(fontWeights.regular),
  },
  logout: {
    color: otherColor.red,
  },
  logoutIcon: {
    color: otherColor.red,
    fontSize: fontSizes.large3,
  },
  avatar: {
    backgroundColor: otherColor.primary,
    color: theme.palette.getContrastText(otherColor.primary),
    fontSize: fontSizes.large1,
  },
})

export default accountMenuStyle
