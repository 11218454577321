import classNames from 'classnames'
import Button, { ButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import customButtonStyle from 'styles/material_ui/components/customButtonStyle'

interface Props extends ButtonProps {
  myStyles:
    | `black`
    | `red`
    | `redcontained`
    | `redoutlined`
    | `blue`
    | `bluecontained`
    | `blueoutlined`
    | `orange`
    | `orangecontained`
    | `orangeoutlined`
    | `mint`
    | `mintcontained`
    | `mintoutlined`
    | `purple`
    | `purplecontained`
    | `purpleoutlined`
    | `magenta`
    | `magentacontained`
    | `magentaoutlined`
    | `green`
    | `greencontained`
    | `greenoutlined`
    | `yellow`
    | `yellowcontained`
    | `yellowoutlined`
    | `pink`
    | `pinkcontained`
    | `pinkoutlined`
  disabled?: boolean
}

const useStyles = makeStyles(customButtonStyle)
/*Create button in theme*/
const CustomButton = ({
  children,
  myStyles,
  disabled,
  ...props
}: Props) => {
  const classes = useStyles()

  const buttonClasses = classNames({
    [classes.button]: true,
    [classes[myStyles]]: myStyles,
    [classes.disabled]: disabled,
  })

  return (
    <Button {...props} disabled={disabled} className={buttonClasses}>
      {children}
    </Button>
  )
}

export default CustomButton
