import { AllTimes, Keypoint } from "types/model"

export const FETCH_KEYPOINT_START = 'FETCH_KEYPOINT_START'
export const FETCH_KEYPOINT_SUCCESS = 'FETCH_KEYPOINT_SUCCESS'
export const FETCH_KEYPOINT_FAILURE = 'FETCH_KEYPOINT_FAILURE'
export const FETCH_TIMELINE_SUCCESS = 'FETCH_TIMELINE_SUCCESS'
export const CLEAR_KEYPOINT = 'CLEAR_KEYPOINT'

export interface InitialState {
  loading: boolean
  pose: Keypoint
  time: AllTimes[]
  timeline: any
  error: string
}

export interface Time {
  start_time: number
  duration: number
}

interface FetchKeypointStart {
  type: typeof FETCH_KEYPOINT_START
}

interface FetchKeypointSuccess {
  type: typeof FETCH_KEYPOINT_SUCCESS
  pose: Keypoint
  time: AllTimes[]
}

interface FetchKeypointFailure {
  type: typeof FETCH_KEYPOINT_FAILURE
  payload: string
}

interface FetchTimelineSuccess {
  type: typeof FETCH_TIMELINE_SUCCESS
  timeline: any
}

interface ClearKeypoint{
  type: typeof CLEAR_KEYPOINT
}

export type KeypointActionTypes =
  | FetchKeypointStart
  | FetchKeypointSuccess
  | FetchKeypointFailure
  | FetchTimelineSuccess
  | ClearKeypoint
