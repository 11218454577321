import { Fragment, useState } from 'react'
import HistoryIcon from '@material-ui/icons/History'
import Box from '@material-ui/core/Box'
import CustomIconButton from 'components/CustomIconButton'
import CustomDialog from 'components/CustomDialog'
import { getUpdatedAt } from 'utils/time'
import { UpdatedLog } from 'types/wordList'
import { Container, Topic, Detail } from './styles'

interface Props {
  history: UpdatedLog[] | undefined
}

/*Display the history of updating the word that 
who update
when the update occur
what the comment is
what the status is*/
const WordHistory = ({ history }: Props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  if (history)
    return (
      <Fragment>
        <CustomIconButton
          tooltipLabel="History"
          svgIcon={<HistoryIcon />}
          onClick={handleOpen}
          iconSize='18px'
          placement='top'
          arrow
        />
        <CustomDialog
          title="History"
          open={open}
          onClose={handleClose}
          scroll="paper"
          disabledAction
        >
          {history?.map((log, i) => (
            <Container key={`${log.updated_at}-${log.updated_by}-${i}`}>
              <Box display="flex">
                <Topic>Modified by:</Topic>
                <Detail>{log.updated_by}</Detail>
              </Box>
              <Box display='flex'><Topic>Modified:</Topic><Detail>{getUpdatedAt(new Date(log.updated_at + 'Z'))}</Detail></Box>
              <Box display='flex'><Topic>Status:</Topic><Detail>{log.status}</Detail></Box>
              <Box display='flex' flexWrap='wrap'><Topic>Comment:</Topic><Detail>{log.comment}</Detail></Box>
            </Container>
          ))}
        </CustomDialog>
      </Fragment>
    )
  else return null
}

export default WordHistory
