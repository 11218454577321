import styled, { css } from 'styled-components'
import { spaces } from 'styles/mixins'
import { mediaPhone, mediaTabPort, mediaTabLand } from 'styles/media'

export const Container = styled.div`
  width: 80%;
  height: 90vh;
  margin: 0 auto;
  overflow: hidden;
  padding: ${spaces.large4} 0;
  ${mediaPhone(css`
    width: 100%;
    padding: ${spaces.large4} ${spaces.small1};
    height: 60vh;
  `)}
`

export const SearchSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
  margin: 0 auto;
  ${mediaPhone(css`
    margin: 0 10px;
  `)}
`

export const SearchWrapper = styled.div`
  flex-grow: 1;
  ${mediaPhone(css`
    margin-left: 20px;
  `)}
`

export const SceneWrapper = styled.div`
  width: 800px;
  height: 800px;
  margin: 0 auto;
  ${mediaTabLand(css`
    width: 600px;
    height: 600px;
  `)}
  ${mediaPhone(css`
    width: 300px;
    height: 300px;
  `)}
`

export const AnimationSection = styled.div`
  position: relative;
  width: 100%;
  margin-top: 15px;
`

export const SliderWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 40px;
  z-index: 999;
  ${mediaTabPort(css`
      position: relative;
  `)}
`