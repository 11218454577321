export const pos = [
  {
    POS: 'home.form.header.body.verb.abbreviations',
    label: 'home.form.header.body.verb',
  },
  {
    POS: 'home.form.header.body.noun.abbreviations',
    label: 'home.form.header.body.noun',
  },
  {
    POS: 'home.form.header.body.preposition.abbreviations',
    label: 'home.form.header.body.preposition',
  },
  {
    POS: 'home.form.header.body.adverb.abbreviations',
    label: 'home.form.header.body.adverb',
  },
  {
    POS: 'home.form.header.body.pronoun.abbreviations',
    label: 'home.form.header.body.pronoun',
  },
  {
    POS: 'home.form.header.body.conjunction.abbreviations',
    label: 'home.form.header.body.conjunction',
  },
  {
    POS: 'home.form.header.body.interjection.abbreviations',
    label: 'home.form.header.body.interjection',
  },
  {
    POS: 'home.form.header.body.auxiliaryVerb.abbreviations',
    label: 'home.form.header.body.auxiliaryVerb',
  },
  {
    POS: 'home.form.header.body.number.abbreviations',
    label: 'home.form.header.body.number',
  },
]

export const posTh = [
  { value: 'ก', label: '(ก.) กริยา' },
  { value: 'น', label: '(น.) คำนาม' },
  { value: 'บ', label: '(บ.) บุรพบท' },
  { value: 'ว', label: '(ว.) วิเศษณ์' },
  { value: 'ส', label: '(ส.) สรรพนาม' },
  { value: 'สัน', label: '(สัน.) สันธาน' },
  { value: 'อ', label: '(อ.) อุทาน' },
]
