import { Container, MyTimePicker, LabelWrapper, Label } from './style'
import 'rc-time-picker/assets/index.css'

const CustomTimePicker = ({ id, label, value, ...props }) => {
  return (
    <Container>
      <LabelWrapper $isShrink={value}>
        <Label htmlFor={id}>{label}</Label>
      </LabelWrapper>
      <MyTimePicker {...props} id={id} value={value} />
    </Container>
  )
}

export default CustomTimePicker
