export enum RequestMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

export interface AxiosArgs {
  method: RequestMethod
  url: string
  data?: any
  headers?: any
}
