import { useEffect, useState, MouseEvent, ChangeEvent, ReactNode } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useMeasure from 'react-use-measure'
import isEmpty from 'lodash/isEmpty'
import YouTube from '@u-wave/react-youtube'

import { makeStyles } from '@material-ui/core/styles'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'

import ShareIcon from '@material-ui/icons/Share'
import BrushIcon from '@material-ui/icons/Brush'

import LinkSharing from 'components/LinkSharing'
import ThreeScene from 'components/ThreeScene'
import SkinColorBox from 'components/SkinColorBox'
import { loadPlayerPage } from 'redux/project/action'
import { selectYoutubeId } from 'redux/project/selector'
import playerPageStyle from 'styles/material_ui/views/playerPageStyle'

import {
  Container,
  ViewerSection,
  VideoWrapper,
  HeaderTooltab,
  CustomizeBtnWrapper,
  CustomizePopoverWrapper,
  Title,
  Section,
  Tooltab,
} from './style'

const YOUTUBE_WIDTH = '100%'
const YOUTUBE_HEIGHT = '100%'
const MODEST_BRANDING = true
const SHOW_RELATED_VIDEOS = false
const PLAYS_INLINE = true
const SKIN_COLOR = [
  '#F8D5BA',
  '#ECC6A9',
  '#E3B696',
  '#D9A785',
  '#CF9874',
  '#C28963',
  '#936E66',
  '#816058',
  '#6F514A',
  '#5C433C',
  '#4A342E',
]

const useStyles = makeStyles(playerPageStyle)

const Desktop = () => {
  const [player, setPlayer] = useState<any>(null)
  const [icon, setIcon] = useState<ReactNode | null>(null)
  const [shareAnchorEl, setShareAnchorEl] = useState<any>(null)
  const [customAnchorEl, setCustomAnchorEl] = useState<any>(null)
  const [playerState, setPlayerState] = useState<number>(-2)
  const [currentModel, setCurrentModel] = useState<string>('male')
  const youtubeId = useSelector(selectYoutubeId)
  const dispatch = useDispatch()
  const { projectId } = useParams<{ projectId: string }>()
  const { t } = useTranslation()
  const location = useLocation()
  const classes = useStyles()
  const [videoRef, bounds] = useMeasure()
  const [sceneRef, boundscene] = useMeasure()

  useEffect(() => {
    dispatch(loadPlayerPage(projectId))
  }, [projectId, dispatch])

  const onReady = (event: any) => {
    setPlayer(event.target)
  }

  const handleStartVideo = () => {
    setIcon(<PauseCircleFilledIcon />)
    player.playVideo()
    setPlayerState(2)
  }

  const handlePause = () => {
    setIcon(<PlayCircleFilledIcon />)
    player.pauseVideo()
    setPlayerState(1)
  }

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setShareAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setShareAnchorEl(null)
  }

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCurrentModel(value)
  }

  const handleOpenCustomize = (event: MouseEvent<HTMLButtonElement>) => {
    setCustomAnchorEl(event.currentTarget)
  }

  const handleCloseCustomize = () => {
    setCustomAnchorEl(null)
  }

  const handleClick = () => {
    if (!isEmpty(player)) {
      const playState = player.getPlayerState()
      setPlayerState(playState)

      switch (playState) {
        //video cued
        case 5:
          setIcon(<PauseCircleFilledIcon />)
          player.playVideo()
          break
        //press to pause (1=playing)
        case 1:
        case 0:
          setIcon(<PlayCircleFilledIcon />)
          player.pauseVideo()
          break
        //press to play (2=paused)
        case 2:
          setIcon(<PauseCircleFilledIcon />)
          player.playVideo()
          break
        default:
          break
      }
    }
  }

  const shareOpen = Boolean(shareAnchorEl)
  const shareId = shareOpen ? 'share-popover' : undefined

  const customOpen = Boolean(customAnchorEl)
  const customId = customOpen ? 'customize-popover' : undefined

  return (
    <Container>
      <HeaderTooltab>
        <CustomizeBtnWrapper>
          <Button
            onClick={handleOpenCustomize}
            color="inherit"
            startIcon={<BrushIcon />}
          >
            Customize Character
          </Button>
        </CustomizeBtnWrapper>
        <Popover
          id={customId}
          open={customOpen}
          anchorEl={customAnchorEl}
          onClose={handleCloseCustomize}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <CustomizePopoverWrapper>
            <Title>Characters</Title>
            <Section>
              <FormControl>
                <RadioGroup
                  row
                  name="gender"
                  value={currentModel}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Alex"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Nina"
                  />
                </RadioGroup>
              </FormControl>
            </Section>
            <Title>Skin</Title>
            <Section>
              <Box display="flex" flexWrap="wrap">
                {SKIN_COLOR.map((color) => (
                  <SkinColorBox color={color} key={color} />
                ))}
              </Box>
            </Section>
          </CustomizePopoverWrapper>
        </Popover>
        <Button
          className={classes.shareButton}
          startIcon={<ShareIcon />}
          onClick={handleOpen}
        >
          {t('player.header.tooltab.share')}
        </Button>
        <Popover
          id={shareId}
          open={shareOpen}
          anchorEl={shareAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <LinkSharing value={window.location.origin + location.pathname} />
        </Popover>
      </HeaderTooltab>
      <ViewerSection>
        <Grid container>
          <Grid item xs={12} md={8} sm={8}>
            <VideoWrapper ref={videoRef}>
              <YouTube
                video={youtubeId}
                width={YOUTUBE_WIDTH}
                height={YOUTUBE_HEIGHT}
                modestBranding={MODEST_BRANDING}
                showRelatedVideos={SHOW_RELATED_VIDEOS}
                playsInline={PLAYS_INLINE}
                onReady={onReady}
                onEnd={handlePause}
                onPlaying={handleStartVideo}
                onPause={handlePause}
              />
            </VideoWrapper>
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <div ref={sceneRef}>
              {bounds && boundscene && (
                <ThreeScene
                  playerState={playerState}
                  player={player}
                  isEditPage={false}
                  gender={currentModel}
                  height={bounds.height}
                  width={boundscene.width}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <Tooltab>
          <IconButton onClick={handleClick} className={classes.iconBtn}>
            {icon || <PlayCircleFilledIcon />}
          </IconButton>
        </Tooltab>
      </ViewerSection>
    </Container>
  )
}

export default Desktop
