import { CaptionsToSave } from 'types/captionInput'
import { PollingStatus } from 'types/speechToTextTranscript'

export const STATE_PROJECT_INFO = 'STATE_PROJECT_INFO'
export const CHANGE_PROJECT_NAME = 'CHANGE_PROJECT_NAME'
export const CHANGE_YOUTUBE_ID = 'CHANGE_YOUTUBE_ID'
export const CLEAR_PROJECT_CACHE = 'CLEAR_PROJECT_CACHE'
export const SET_DIRTY = 'SET_DIRTY'
export const RESET_DIRTY = 'RESET_DIRTY'
export const SAVE_PROJECT_START = 'SAVE_PROJECT_START'
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS'
export const SAVE_PROJECT_FAILURE = 'SAVE_PROJECT_FAILURE'
export const RESET_SAVE_STATUS = 'RESET_SAVE_STATUS'

export interface InitialState {
  name: string
  project_id: string
  captions: [] | CaptionsToSave[]
  youtube_id: string
  status: PollingStatus
  dirty_bit: boolean
  save_status: 'save' | 'saving...' | 'saved'
}

interface StateProjectInfo {
  type: typeof STATE_PROJECT_INFO
  name: string
  project_id: string
  captions: [] | CaptionsToSave[]
  youtube_id: string
  status: PollingStatus
}

interface ChangeProjectName {
  type: typeof CHANGE_PROJECT_NAME
  payload: string
}

interface ChangeYoutubeId {
  type: typeof CHANGE_YOUTUBE_ID
  payload: string
}

interface ClearProjectCache {
  type: typeof CLEAR_PROJECT_CACHE
}

interface SetDirtyBit {
  type: typeof SET_DIRTY
}

interface ResetDirtyBit {
  type: typeof RESET_DIRTY
}

interface SaveStart{
  type: typeof SAVE_PROJECT_START
}

interface SaveSuccess{
  type: typeof SAVE_PROJECT_SUCCESS
}

interface SaveFailure{
  type: typeof SAVE_PROJECT_FAILURE
}

interface ResetSaveStatus{
  type: typeof RESET_SAVE_STATUS
}

export type ProjectActionTypes =
  | StateProjectInfo
  | ChangeProjectName
  | ChangeYoutubeId
  | ClearProjectCache
  | SetDirtyBit
  | ResetDirtyBit
  | SaveStart
  | SaveSuccess
  | SaveFailure
  | ResetSaveStatus
