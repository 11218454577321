import {
  TokenizationActionTypes,
  InitialState,
  TOKENIZE_START,
  TOKENIZE_SUCCESS,
  TOKENIZE_FAILURE,
  CLEAR_TOKENIZE,
} from 'redux/tokenization/types'

const INITIAL_STATE: InitialState = {
  loading: false,
  data: [],
  index: 0,
  error: '',
}

const tokenizationReducer = (
  state = INITIAL_STATE,
  action: TokenizationActionTypes
): InitialState => {
  switch (action.type) {
    case TOKENIZE_START:
      return {...state,loading:true}
    case TOKENIZE_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        index: action.index,
        error: '',
      }
    case TOKENIZE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case CLEAR_TOKENIZE:
      return INITIAL_STATE
    default:
      return state
  }
}

export default tokenizationReducer
