import { useEffect, useRef, useState, useCallback } from 'react'
import { useFrame, useLoader } from 'react-three-fiber'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { clearKeypoint } from 'redux/keypoint/action'
import {
  selectCurrentKeypoint,
  selectAllTimes,
} from 'redux/keypoint/selector'
import buildTimeline from 'utils/timeline'
import { useLoadModel } from 'utils/hook'
import animate from 'utils/animate'
import { boneIndex, StandardBone } from 'types/bone'
import { NINA, MyBone } from 'types/model'

import * as THREE from 'three'

const BODY_MULTIPLY_PATH = '/nina/nina_textures/Nina_body_multiply.png'
const BODY_PATH = '/nina/nina_textures/Nina_body.png'
const CLOTHES_MULTIPLY_PATH = '/nina/nina_textures/Nina_clothes_multiply.png'
const HAIR1_MULTIPLY_PATH = '/nina/nina_textures/Nina_Hair1_multiply.png'
const HAIR2_MULTIPLY_PATH = '/nina/nina_textures/Nina_Hair2_multiply.png'
const CLOTHES2_MULTIPLY_PATH = '/nina/nina_textures/Nina_clothes2_multiply.png'
const CLOCK = new THREE.Clock()
const FPS = 30

interface Props {
  playerState: number
  player: any
  isEditPage?: boolean
}

const NinaModel = ({ playerState, player, isEditPage }: Props) => {
  const group = useRef()
  const keypoint = useSelector(selectCurrentKeypoint)
  const allTimes = useSelector(selectAllTimes)
  const dispatch = useDispatch()
  const [startPose, setStartPose] = useState<number[][]>([])
  const [bones, setBones] = useState<MyBone>({})
  const [startTime, setStartTime] = useState<number | undefined>()
  const nodes = useLoadModel(NINA.path)
  const Body_multiply = useLoader(THREE.TextureLoader, BODY_MULTIPLY_PATH)
  const Body = useLoader(THREE.TextureLoader, BODY_PATH)
  const Clothes_multiply = useLoader(THREE.TextureLoader, CLOTHES_MULTIPLY_PATH)
  const Hair1_multiply = useLoader(THREE.TextureLoader, HAIR1_MULTIPLY_PATH)
  const Hair2_multiply = useLoader(THREE.TextureLoader, HAIR2_MULTIPLY_PATH)
  const Clothes2_multiply = useLoader(
    THREE.TextureLoader,
    CLOTHES2_MULTIPLY_PATH
  )
  const texture = {
    Body_multiply: Body_multiply,
    Body: Body,
    Clothes_multiply: Clothes_multiply,
    Hair1_multiply: Hair1_multiply,
    Hair2_multiply: Hair2_multiply,
    Clothes2_multiply: Clothes2_multiply,
  }

  const fetchEverything = useCallback(
    async (startPoseList) => {
      await fetch('/start.csv')
        .then((response) => response.text())
        .then((data) => {
          let startPoseArr = [...startPoseList]
          data.split('\n').forEach((value) => {
            startPoseArr.push(value.split(' ').map((m) => parseFloat(m)))
          })

          setStartPose(startPoseArr)
        })

      await fetch('/standard_bone.json')
        .then((response) => response.json())
        .then((data: StandardBone) => {
          // create reverse map between model's bone name and our bone name
          let boneName: { [key: string]: string } = {}
          let bonesTemp: { [key: string]: any } = {}
          Object.entries(data).forEach(
            ([key, value]) => (boneName[value] = key)
          )
          // create map between index and bone object for creating animation
          Object.entries(nodes).forEach(([key, value]) => {
            if (value.type === 'Bone') {
              const index = boneName[key]
              if (boneIndex[index]) {
                bonesTemp[boneIndex[index]] = value
              }
            }
          })
          setBones(bonesTemp)
        })
    },
    [nodes]
  )

  useEffect(() => {
    if (!isEmpty(nodes)) {
      fetchEverything(startPose)
    }
    //eslint-disable-next-line
  }, [nodes])

  const { timeline, timelineKeySorted } = buildTimeline(
    keypoint,
    allTimes,
    isEditPage,
    startPose
  )

  const findKF = (FrameNo: number) => {
    const nextIndex = timelineKeySorted.findIndex(
      (element: number) => element > FrameNo
    )
    const prevIndex =
      nextIndex === -1 ? timelineKeySorted.length - 1 : nextIndex - 1
    return {
      previousFrame: timelineKeySorted[prevIndex],
      nextFrame: nextIndex === -1 ? -1 : timelineKeySorted[nextIndex],
    }
  }

  let t = 0
  let currentTime = 0

  useFrame(() => {
    //PlayerPage,playerState = -2: Video does not start. playerState = 1: pause video.
    if (!isEmpty(timeline) && playerState !== -2 && playerState !== 1) {
      if (!startTime) {
        setStartTime(CLOCK.getElapsedTime())
      }
      //Play from HomePage
      if (playerState === -1) {
        if (startTime) currentTime = CLOCK.getElapsedTime() - startTime
      }
      //5: start playing video
      if (playerState === 5 || playerState === 2) {
        currentTime = player.getCurrentTime()
      }
      let FrameNo = currentTime / (1 / FPS)

      const { previousFrame, nextFrame } = findKF(FrameNo)
      // nextFrame = -1 (stop playing)
      if (nextFrame !== -1) {
        t = (FrameNo - previousFrame) / (nextFrame - previousFrame)
        animate(bones, timeline, previousFrame, nextFrame, t)
      } else {
        dispatch(clearKeypoint())
        setStartTime(undefined)
      }
    }
  })

  return (
    <>
      {!isEmpty(nodes) && (
        <group
          ref={group}
          position={NINA.position}
          scale={NINA.scale}
          dispose={null}
        >
          <group>
            <primitive object={nodes['rootx']} />
            <skinnedMesh
              geometry={nodes.Nina_Hair2.geometry}
              skeleton={nodes.Nina_Hair2.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Hair2_multiply}
                color="#0a043c"
                map-flipY={false}
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_pullover.geometry}
              skeleton={nodes.Nina_pullover.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Clothes_multiply}
                color="#0a043c"
                map-flipY={false}
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_body.geometry}
              skeleton={nodes.Nina_body.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Body}
                map-flipY={false}
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_eyebrows.geometry}
              skeleton={nodes.Nina_eyebrows.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Body_multiply}
                map-flipY={false}
                color="#0a043c"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_eyes004.geometry}
              skeleton={nodes.Nina_eyes004.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_eyes004_1.geometry}
              skeleton={nodes.Nina_eyes004_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_fake_reflection.geometry}
              skeleton={nodes.Nina_fake_reflection.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_iris_left004.geometry}
              skeleton={nodes.Nina_iris_left004.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#151515"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_iris_left004_1.geometry}
              skeleton={nodes.Nina_iris_left004_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_iris_right004.geometry}
              skeleton={nodes.Nina_iris_right004.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#151515"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_iris_right004_1.geometry}
              skeleton={nodes.Nina_iris_right004_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_jeans.geometry}
              skeleton={nodes.Nina_jeans.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#0a043c"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Nina_tongue.geometry}
              skeleton={nodes.Nina_tongue.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
          </group>
        </group>
      )}
    </>
  )
}

export default NinaModel
