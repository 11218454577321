export const PLAY_ANIMATION = 'PLAY_ANIMATION'
export const STOP_ANIMATION = 'STOP_ANIMATION'

export interface InitialState {
    status: number
}

interface PlayAnimation {
    type: typeof PLAY_ANIMATION,
}

interface StopAnimation {
    type: typeof STOP_ANIMATION,
}

export type ModelPlayerActionTypes = PlayAnimation | StopAnimation