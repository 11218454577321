import { Fragment, useEffect, useState, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { useHistory } from 'react-router-dom'
import range from 'lodash/range'
import { useAuth0 } from '@auth0/auth0-react'
//material-ui
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
//components
import Navbar from 'components/Navbar'
import ProjectCard from 'components/ProjectCard'
import CustomAlert from 'components/CustomAlert'
import CustomButton from 'components/CustomButton'
//redux
import { loadingStart, loadingStop } from 'redux/loading/action'
//types
import { RequestMethod } from 'types/api'
import { ProjectCardInfo } from 'types/project'
//files
import CreateNewOverlay from 'assets/new_project.svg'
import { API_PROJECT } from 'env'
//style
import dashboardPageStyle from 'styles/material_ui/views/dashboardPageStyle'
import { Container, ImageWrapper, CreateNewImageOverlay } from './style'

const useStyles = makeStyles(dashboardPageStyle)

const DashboardPage = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [projects, setProjects] = useState<Array<ProjectCardInfo>>([])
  const [open, setOpen] = useState(false)
  const [projectNameError, setProjectNameError] = useState('')
  const [projectName, setProjectName] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const fetchDashboard = async (
    unmounted: boolean,
    source: CancelTokenSource
  ) => {
    setLoading(true)
    dispatch(loadingStart())
    const accessToken = await getAccessTokenSilently()

    const config = {
      method: RequestMethod.GET,
      url: API_PROJECT,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    }
    try {
      const response = await axios(config)
      const data = response.data.projects
      if (!unmounted) {
        setProjects(data)
        setLoading(false)
        dispatch(loadingStop())
      }
    } catch (err) {
      if (!unmounted) {
        console.error('err', err)
        setLoading(false)
        dispatch(loadingStop())
      }
    }
  }

  useEffect(() => {
    let unmounted = false
    let source = axios.CancelToken.source()
    fetchDashboard(unmounted, source)
    return () => {
      unmounted = true
      source.cancel('Cancelling in cleanup')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCreate = async () => {
    let error = false
    if (projectName === '') {
      setProjectNameError('Name cannot be empty')
      return
    }
    projects.forEach((project) => {
      if (project.name === projectName) {
        setProjectNameError('This name is already existed')
        error = true
        return
      }
    })
    if (error) return
    try {
      const accessToken = await getAccessTokenSilently()      
      const config = {
        method: RequestMethod.POST,
        url: API_PROJECT,
        data: {
          name: projectName,
        },
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
      }
      const res = await axios(config)
      const data = res.data
      const { status, project } = data
      if (status === 'success') {
        handleClose()
        history.push(`/edit/${project.project_id}`)
      }
    } catch (err) {
      console.error('err', err)
    }
  }

  const renderSkeleton = () =>
    range(6).map((i) => (
      <Grid item xs={12} sm={6} md={4} key={i}>
        <Box display="flex" justifyContent="space-between">
          <Skeleton variant="text" width="30%" />
          <Box display="flex">
            <Skeleton variant="circle" width={20} height={20} />
            <Skeleton variant="circle" width={20} height={20} />
          </Box>
        </Box>
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="rect" width="100%" height={240} />
      </Grid>
    ))

  const renderProjectCards = () => {
    if (projects.length > 0) {
      return projects.map((project, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <ProjectCard data={project} />
        </Grid>
      ))
    } else {
      return (
        <ImageWrapper>
          <CreateNewImageOverlay src={CreateNewOverlay} alt="create-new" />
          <br />
          <br />
          <h4>
            Click{' '}
            <span>
              <AddCircleIcon />
            </span>{' '}
            to create new project
          </h4>
        </ImageWrapper>
      )
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (projectNameError) {
      setProjectNameError('')
    }
    setProjectName(value)
  }

  return (
    <Fragment>
      <Navbar />
      <CustomAlert />
      <Container>
        <Grid container spacing={3}>
          {loading ? renderSkeleton() : renderProjectCards()}
          <Tooltip title="New Project" placement="left">
            <Fab
              color="primary"
              aria-label="add"
              onClick={handleOpenDialog}
              className={classes.fab}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Dialog
            onClose={handleClose}
            aria-labelledby="new-project-dialog"
            open={open}
            fullWidth
          >
            <DialogTitle>Create New Project</DialogTitle>
            <DialogContent>
              <TextField
                label="Project Name"
                value={projectName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={projectNameError !== ''}
                helperText={projectNameError}
              />
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={handleClose} myStyles="black">
                Cancel
              </CustomButton>
              <CustomButton onClick={handleCreate} myStyles="purplecontained">
                Create
              </CustomButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default DashboardPage
