import axios from 'axios'
import Cookie from 'universal-cookie';
import isEmpty from 'lodash/isEmpty'
import { RequestMethod } from 'types/api'
import { API_SPEECH_TOKEN } from 'env'

export const getSpeechTokenOrRefresh = async () => {
    const cookie = new Cookie()
    const speechToken = cookie.get('speech-token')

    if (isEmpty(speechToken)) {
        try {
            const config = { method: RequestMethod.GET, url: API_SPEECH_TOKEN }
            const response = await axios(config)
            const token = response.data.token
            const region = response.data.region
            cookie.set('speech-token', region + ':' + token, { maxAge: 540, path: '/' });
            return { authToken: token, region: region };
        } catch (error) {
            console.error('Error get speech token', error)
            return { authToken: null, error: error };
        }
    } else {
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}