import { Fragment, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Navbar from 'components/Navbar'
import CustomAutoCompleteSearch from 'components/CustomAutoCompleteSearch'
import ThreeScene from 'components/ThreeScene'
import CustomButton from 'components/CustomButton'
import AnimationSpeedSlider from 'components/AnimationSpeedSlider'

import { getAnimationKeypoint, clearKeypoint } from 'redux/keypoint/action'
import { selectFoundWord } from 'redux/search/selector'
import {
  Container,
  SearchSection,
  SearchWrapper,
  SceneWrapper,
  AnimationSection,
  SliderWrapper,
} from './style'

const SearchPage = () => {
  const dispatch = useDispatch()
  const searchWord = useSelector(selectFoundWord)
  const [times, setTimes] = useState({
    start: moment().hour(0).minute(0).second(0),
    end: moment().hour(0).minute(0).second(1),
  })
  const handleAnimation = () => {
    if (searchWord) {
      const wordList = [[searchWord]]
      dispatch(clearKeypoint())
      dispatch(getAnimationKeypoint(wordList, [times]))
    }
  }

  const getDisabledBtn = () => {
    if (isEmpty(searchWord)) return true
    else return false
  }
  const handleSpeed = (value: number | number[]) => {
    if (!Array.isArray(value)) {
      const endTime = moment().hour(0).minute(0).second(value)
      setTimes({ ...times, end: endTime })
    }
  }
  return (
    <Fragment>
      <Navbar />
      <Container>
        <SearchSection>
          <SearchWrapper>
            <CustomAutoCompleteSearch />
          </SearchWrapper>
          <CustomButton
            onClick={handleAnimation}
            myStyles={`redcontained`}
            disabled={getDisabledBtn()}
          >
            play
          </CustomButton>
        </SearchSection>
        <AnimationSection>
          <SliderWrapper><AnimationSpeedSlider onChange={handleSpeed} /></SliderWrapper>
          <SceneWrapper>
            <ThreeScene playerState={-1} isEditPage={false} gender="male" />
          </SceneWrapper>
        </AnimationSection>
      </Container>
    </Fragment>
  )
}

export default SearchPage
