import { createStyles } from '@material-ui/core'
import { fontSizes, otherColor, } from 'styles/mixins'

const dashboardPageStyle = () => createStyles({
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        backgroundColor: otherColor.secondary,
        fontSize: fontSizes.large2,
        '&:hover':{
            backgroundColor: otherColor.primary,
        }
    },
})

export default dashboardPageStyle