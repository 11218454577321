export const boneIndex:{[key:string]:number} = {
c_eye_targetx:0,
c_eye_targetl:1,
c_eye_targetr:2,
rootx: 3,
spine_01x: 4,
spine_02x: 5,
neckx: 6,
headx: 7,
c_skull_01x:8,
c_jawbonex:9,
c_chin_01x:10,
c_chin_02x:11,
c_lips_botl:12,
c_lips_botr:13,
c_lips_botx:14,
c_lips_bot_01l:15,
c_lips_bot_01r:16,
c_teeth_botl:17,
c_teeth_botr:18,
c_teeth_botx:19,
tong_01x:20,
tong_02x:21,
tong_03x:22,
c_lips_smilel:23,
c_lips_smiler:24,
c_lips_topl:25,
c_lips_topr:26,
c_lips_topx:27,
c_lips_top_01l:28,
c_lips_top_01r:29,
c_teeth_topl:30,
c_teeth_topr:31,
c_teeth_topx:32,
c_skull_02x:33,
c_cheek_inflatel:34,
c_cheek_inflater:35,
c_cheek_smilel:36,
c_cheek_smiler:37,
c_ear_01l:38,
c_ear_02l:39,
c_ear_01r:40,
c_ear_02r:41,
c_eye_offsetl:42,
c_eyel:43,
c_eye_ref_trackl:44,
c_eyelid_botl:45,
c_eyelid_bot_01l:46,
c_eyelid_bot_02l:47,
c_eyelid_bot_03l:48,
c_eyelid_corner_01l:49,
c_eyelid_corner_02l:50,
c_eyelid_topl:51,
c_eyelid_top_01l:52,
c_eyelid_top_02l:53,
c_eyelid_top_03l:54,
c_eye_offsetr:55,
c_eyer:56,
c_eye_ref_trackr:57,
c_eyelid_botr:58,
c_eyelid_bot_01r:59,
c_eyelid_bot_02r:60,
c_eyelid_bot_03r:61,
c_eyelid_corner_01r:62,
c_eyelid_corner_02r:63,
c_eyelid_topr:64,
c_eyelid_top_01r:65,
c_eyelid_top_02r:66,
c_eyelid_top_03r:67,
c_eyebrow_fulll:68,
c_eyebrow_01l:69,
c_eyebrow_01_endl:70,
c_eyebrow_02l:71,
c_eyebrow_03l:72,
c_eyebrow_fullr:73,
c_eyebrow_01r:74,
c_eyebrow_01_endr:75,
c_eyebrow_02r:76,
c_eyebrow_03r:77,
c_nose_01x:78,
c_nose_02x:79,
c_nose_03x:80,
c_skull_03x:81,
shoulderl: 82,
arm_stretchl: 83,
forearm_stretchl: 84,
handl: 85,
index1_basel: 86,
index1l: 87,
index2l: 88,
index3l: 89,
middle1_basel: 90,
middle1l: 91,
middle2l: 92,
middle3l: 93,
pinky1_basel: 94,
pinky1l: 95,
pinky2l: 96,
pinky3l: 97,
ring1_basel: 98,
ring1l: 99,
ring2l: 100,
ring3l: 101,
thumb1_base: 102,
thumb1l: 103,
thumb2l: 104,
thumb3l: 105,
shoulderr: 106,
arm_stretchr: 107,
forearm_stretchr: 108,
handr: 109,
index1_baser: 110,
index1r: 111,
index2r: 112,
index3r: 113,
middle1_baser: 114,
middle1r: 115,
middle2r: 116,
middle3r: 117,
pinky1_baser: 118,
pinky1r: 119,
pinky2r: 120,
pinky3r: 121,
ring1_baser: 122,
ring1r: 123,
ring2r: 124,
ring3r: 125,
thumb1_baser: 126,
thumb1r: 127,
thumb2r: 128,
thumb3r: 129,
thigh_stretchl: 130,
leg_stretchl: 131,
footl: 132,
toes_01l: 133,
thigh_stretchr: 134,
leg_stretchr: 135,
footr: 136,
toes_01r: 137
}

export type StandardBone = {[key:string]:string}
