import { createSelector } from 'reselect'
import { RootState } from 'redux/root-reducer'

const selectLoading = (state: RootState) => state.loading

export const selectIsLoading = createSelector(
  [selectLoading],
  (loading) => loading.isLoad
)

export const selectIsPlayerPageLoading = createSelector(
  [selectLoading],
  (loading) => loading.isLoadPlayerPage
)