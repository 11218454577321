export const LOADING_START = 'LOADING_START'
export const LOADING_STOP = 'LOADING_STOP'
export const LOADING_PLAYER_PAGE_START = 'LOADING_PLAYER_PAGE_START'
export const LOADING_PLAYER_PAGE_STOP = 'LOADING_PLAYER_PAGE_STOP'
export interface InitialState {
  isLoad: boolean
  isLoadPlayerPage: boolean
}

interface LoadingStart {
  type: typeof LOADING_START
}

interface LoadingStop {
  type: typeof LOADING_STOP
}

interface LoadingPlayerPageStart{
  type: typeof LOADING_PLAYER_PAGE_START
}

interface LoadingPlayerPageStop{
  type: typeof LOADING_PLAYER_PAGE_STOP
}

export type LoadingAcionTypes = LoadingStart | LoadingStop | LoadingPlayerPageStart | LoadingPlayerPageStop
