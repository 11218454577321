import styled from 'styled-components'

interface SkinBox {
  background: string
}

export const Container = styled.div<SkinBox>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.background};
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`
