import { Fragment, Suspense, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import ProtectedRoute from './auth/protected-route'
import ProtectedAdminRoute from './auth/protected-admin-route'

import EditPage from 'pages/EditPage'
import ViewerPage from 'pages/ViewerPage'
import SearchPage from 'pages/SearchPage'
import AddWordPage from 'pages/AddWordPage'
import DashboardPage from 'pages/DashboardPage'
import LoginOverlayPage from 'pages/LoginOverlayPage'
import LivePage from 'pages/LivePage'
import WordListPage from 'pages/WordListPage'

import { clearKeypoint } from 'redux/keypoint/action'
import { clearProjectCache } from 'redux/project/action'
import { clearTranscript } from 'redux/youtubeTranscript/action'
import { clearTokenizeWord } from 'redux/tokenization/action'
import { clearCaption } from 'redux/captionInput/action'
import { closeAlert } from 'redux/alert/action'

import GlobalStyle from 'styles/GlobalSheet'
import { myTheme } from './theme.js'
import EmailNotVerifiedPage from 'pages/EmailNotVerifiedPage'

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { isAuthenticated, error, isLoading } = useAuth0()

  //when location change clear data
  useEffect(() => {
    dispatch(clearCaption())
    dispatch(clearTokenizeWord())
    dispatch(clearKeypoint())
    dispatch(clearProjectCache())
    dispatch(clearTranscript())
    dispatch(closeAlert())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (error?.message?.startsWith('email_not_verified')) {
    return <EmailNotVerifiedPage email={error.message.split(':', 2)[1]} />;
  }

  if (isLoading) {
    return <GlobalStyle><LinearProgress /></GlobalStyle>
  }

  return (
    <Fragment key="app">
      <GlobalStyle />
      <ThemeProvider theme={myTheme}>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            <Route exact path="/">
              {isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <LoginOverlayPage />
              )}
            </Route>
            <ProtectedRoute path="/dashboard" component={DashboardPage} />
            <ProtectedRoute path="/edit/:projectId" component={EditPage} />
            <ProtectedRoute path="/live" component={LivePage} />
            <ProtectedAdminRoute path="/admin/wordlist" component={WordListPage} />
            <ProtectedAdminRoute path="/admin/addWord" component={AddWordPage} />
            <Route path="/search" component={SearchPage} />
            <Route path="/player/:projectId" component={ViewerPage} />
          </Switch>
        </Suspense>
      </ThemeProvider>
    </Fragment>
  )
}

export default App
