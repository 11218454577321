export const ALERT_SHOW = 'ALERT_SHOW'
export const ALERT_CLOSE = 'ALERT_CLOSE'

export interface InitialState {
  isShow: boolean
  message: string
  severity: string | undefined
}

interface ShowAlert {
  type: typeof ALERT_SHOW
  payload: string
  severity?: string | undefined
}

interface CloseAlert {
  type: typeof ALERT_CLOSE
}

export type AlertActionTypes = ShowAlert | CloseAlert
