import {
  CaptionActionTypes,
  ADD_CHIP,
  DELETE_CHIP,
  CHANGE_FOCUS_CHIP,
  CHANGE_SYNONYM_WORD,
  ADD_ROW,
  DELETE_ROW,
  CHANGE_START_TIME,
  CHANGE_END_TIME,
  CLEAR_CAPTION,
  AUTO_FILL,
  STATE_CAPTION,
  MOVE_CHIP,
} from 'redux/captionInput/types'
import { setDirtyBit } from 'redux/project/action'
import { Dispatch } from 'redux'
import { Token } from 'types/token'
import { Moment } from 'types/moment'
import { CaptionInput } from 'types/captionInput'

export const stateCaption = (msg: string, rowId: number): CaptionActionTypes => ({
  type: STATE_CAPTION,
  payload: msg,
  rowId: rowId,
})

export const stateCaptionWrapper = (msg: string, rowId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setDirtyBit())
    dispatch(stateCaption(msg, rowId))
  }
}

export const addChip = (newChip: Array<Token>, row: number): CaptionActionTypes => ({
  type: ADD_CHIP,
  payload: newChip,
  rowId: row,
})

const deleteChip = (row: number, id: number): CaptionActionTypes => ({
  type: DELETE_CHIP,
  rowId: row,
  tokenId: id,
})

export const deleteChipWrapper = (row: number, id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setDirtyBit())
    dispatch(deleteChip(row, id))
  }
}

export const changeFocusChip = (
  index: number | null,
  rowId: number
): CaptionActionTypes => ({
  type: CHANGE_FOCUS_CHIP,
  focus: index,
  rowId: rowId,
})

const changeSynonymWord = (
  token: Token,
  rowId: number,
  index: number
): CaptionActionTypes => ({
  type: CHANGE_SYNONYM_WORD,
  token: token,
  rowId: rowId,
  indexOfToken: index,
})

export const changeSynonymWordWrapper = (
  token: Token,
  rowId: number,
  index: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(setDirtyBit())
    dispatch(changeSynonymWord(token, rowId, index))
  }
}

const addRow = (rowId: number): CaptionActionTypes => ({
  type: ADD_ROW,
  rowId: rowId,
})

export const addRowWrapper = (rowId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setDirtyBit())
    dispatch(addRow(rowId))
  }
}

const deleteRow = (rowId: number): CaptionActionTypes => ({
  type: DELETE_ROW,
  rowId: rowId,
})

export const deleteRowWrapper = (rowId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setDirtyBit())
    dispatch(deleteRow(rowId))
  }
}

const changeTime = (
  value: Moment,
  rowId: number,
  name: 'start' | 'end'
): CaptionActionTypes =>
  name === 'start'
    ? {
        type: CHANGE_START_TIME,
        payload: value,
        rowId: rowId,
        name: name,
      }
    : { type: CHANGE_END_TIME, payload: value, rowId: rowId, name: name }

export const changeTimeWrapper = (
  value: Moment,
  rowId: number,
  name: 'start' | 'end'
) => {
  return async (dispatch: Dispatch) => {
    dispatch(setDirtyBit())
    dispatch(changeTime(value, rowId, name))
  }
}

export const autoFillCaption = (caption: Array<CaptionInput>): CaptionActionTypes => ({
  type: AUTO_FILL,
  payload: caption,
})

export const clearCaption = (): CaptionActionTypes => ({
  type: CLEAR_CAPTION,
})

const moveChip = (
  rowId: number,
  from: number,
  to: number
): CaptionActionTypes => ({
  type: MOVE_CHIP,
  rowId: rowId,
  from: from,
  to: to,
})

export const moveChipWrapper = (rowId: number, from: number, to: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setDirtyBit())
    dispatch(moveChip(rowId, from, to))
  }
}
