import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import TranslateIcon from '@material-ui/icons/Translate'
import Hidden from '@material-ui/core/Hidden'
import Menu from '@material-ui/icons/Menu'
import Close from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

import CustomDropdown from 'components/CustomDropdown'
import CustomButton from 'components/CustomButton'
import Mobile from './Mobile'
import Desktop from './Desktop'

import { switchLanguage } from 'redux/language/action'
import { selectCurrentLanguage } from 'redux/language/selector'
import { Language } from 'types/language'
import Justsigns from 'assets/justsigns.png'
import navbarStyle from 'styles/material_ui/components/navbarStyle'
import { Logo, Container, Options, LogoLink } from './style'

const useStyles = makeStyles(navbarStyle)
const options = ['English', 'ไทย']

const Navbar = () => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentLang = useSelector(selectCurrentLanguage)
  const {loginWithRedirect, logout} = useAuth0()
  
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const changeLanguage = (_event: MouseEvent, value: Language) => {
    let temp = 'en'
    switch (value) {
      case 'English':
        temp = 'en'
        break
      case 'ไทย':
        temp = 'th'
        break
      default:
        break
    }
    dispatch(switchLanguage(value))
    i18n.changeLanguage(temp)
  }

  const handleGoToSearch = () => {
    history.push('/search')
  }

  const handleLogin = async () => {
    await loginWithRedirect()
  }

  const handleLogout = () => {
    logout({returnTo: window.location.origin})
  }

  return (
    <Container>
      <div>
        <LogoLink to="/">
          <Logo src={Justsigns} alt="logo" />
        </LogoLink>
      </div>
      <Hidden mdUp>
        <IconButton onClick={toggleDrawer} style={{ fontSize: '18px' }}>
          <Menu />
        </IconButton>
      </Hidden>
      <Hidden mdUp>
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <Mobile
            handleGoToSearch={handleGoToSearch}
            options={options}
            changeLanguage={changeLanguage}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Options>
          <CustomButton
            onClick={handleGoToSearch}
            myStyles={`black`}
            startIcon={<SearchIcon />}
          >
            {t('navbar.search')}
          </CustomButton>
          <CustomDropdown
            startIcon={<TranslateIcon />}
            options={options}
            handleClickItem={changeLanguage}
          >
            {currentLang}
          </CustomDropdown>
          <Desktop handleLogin={handleLogin} handleLogout={handleLogout} />
        </Options>
      </Hidden>
    </Container>
  )
}

export default Navbar
