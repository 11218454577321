import React, { Suspense } from 'react'
import { ReactReduxContext, Provider } from 'react-redux'
import { Canvas } from 'react-three-fiber'
import { OrthographicCamera } from '@react-three/drei'
import AlexModel from 'components/AlexModel'
import NinaModel from 'components/NinaModel'
import Loading from 'components/AnimationModel/LoadingModel'
import './styles.css'

const d = 8.25
const FRUSTUM_SIZE = 200
interface Props {
  playerState: number
  player?: any
  scale?: Array<number>
  isEditPage?: boolean
  gender: string
  height?: number
  width?: number
}

const ThreeScene = ({
  playerState,
  isEditPage,
  player,
  gender,
  height,
  width,
}: Props) => {
  const sceneWidth = width || 512
  const sceneHeight = height || 520
  const aspectRatio = sceneWidth / sceneHeight

  const renderModel = () => {
    switch (gender) {
      case 'female':
        return (
          <NinaModel
            playerState={playerState}
            player={player}
            isEditPage={isEditPage}
          />
        )
      default:
        return (
          <AlexModel
            playerState={playerState}
            player={player}
            isEditPage={isEditPage}
          />
        )
    }
  }

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <Canvas
          shadowMap
          updateDefaultCamera={false}
          style={{ width: width || '100%', height: height || '100%' }}
          resize = {{scroll:false}}
        >
          <Provider store={store}>
            <OrthographicCamera
              position={[0, 0, 1000]}
              left={FRUSTUM_SIZE*aspectRatio / -2}
              right={FRUSTUM_SIZE*aspectRatio / 2}
              top={FRUSTUM_SIZE / 2}
              bottom={FRUSTUM_SIZE / -2}
              near={0.1}
              far={2000}
              zoom={2}
              makeDefault
              onUpdate={(self) => self.updateProjectionMatrix()}
            />
            <hemisphereLight intensity={0.4} position={[0, 0, 0]} />
            <directionalLight
              position={[-8, 20, 20]}
              shadow-camera-left={d * -1}
              shadow-camera-bottom={d * -1}
              shadow-camera-right={d}
              shadow-camera-top={d}
              shadow-camera-near={0.1}
              shadow-camera-far={1500}
              castShadow
            />
            <Suspense fallback={<Loading isEditPage={isEditPage} />}>
              {renderModel()}
            </Suspense>
          </Provider>
        </Canvas>
      )}
    </ReactReduxContext.Consumer>
  )
}

export default React.memo(ThreeScene)
