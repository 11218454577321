import { AlertActionTypes, ALERT_SHOW, ALERT_CLOSE } from 'redux/alert/types'

export const showAlert = (
  msg: string,
  severity?: string
): AlertActionTypes => ({
  type: ALERT_SHOW,
  payload: msg,
  severity: severity,
})

export const closeAlert = (): AlertActionTypes => ({
  type: ALERT_CLOSE,
})
