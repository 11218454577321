import { Fragment, useState, MouseEvent } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Popover from '@material-ui/core/Popover'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'

import CustomButton from 'components/CustomButton'
import AccountMenu from 'components/AccountMenu'

interface Props {
  handleLogin: () => void
  handleLogout: () => void
}

const Desktop = ({ handleLogin, handleLogout }: Props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const { user, isAuthenticated } = useAuth0()
  const profilePictureURL = user?.picture;

  const handlePopup = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'account-popover' : undefined

  return (
    <Fragment>
      {isAuthenticated ? (
        <Fragment>
          <IconButton onClick={handlePopup} size="small">
            <Avatar src={profilePictureURL}></Avatar>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <AccountMenu onLogout={handleLogout} />
          </Popover>
        </Fragment>
      ) : (
        <CustomButton onClick={handleLogin} myStyles={`greenoutlined`}>
          Login
        </CustomButton>
      )}
    </Fragment>
  )
}

export default Desktop
