import moment from 'moment'
import {
  InitialState,
  CaptionActionTypes,
  ADD_CHIP,
  DELETE_CHIP,
  CHANGE_FOCUS_CHIP,
  CHANGE_SYNONYM_WORD,
  ADD_ROW,
  DELETE_ROW,
  CHANGE_START_TIME,
  CHANGE_END_TIME,
  AUTO_FILL,
  CLEAR_CAPTION,
  STATE_CAPTION,
  MOVE_CHIP,
} from 'redux/captionInput/types'
import {
  addNewToken,
  deleteToken,
  changeFocusToken,
  changeWord,
  addRow,
  deleteRow,
  changeTime,
  autoFill,
  changeSubtitle,
  moveToken,
} from 'redux/captionInput/utils'

const INITIAL_STATE: InitialState = {
  captionInputs: [
    {
      start: moment().hour(0).minute(0).second(0).millisecond(0),
      end: moment().hour(0).minute(0).second(0).millisecond(0),
      subtitle: '',
      tokens: [],
      focusedChip: null,
    },
  ],
}

const captionInputReducer = (
  state = INITIAL_STATE,
  action: CaptionActionTypes
): InitialState => {
  switch (action.type) {
    case STATE_CAPTION:
      return {
        captionInputs: changeSubtitle(
          state.captionInputs,
          action.payload,
          action.rowId
        ),
      }
    case ADD_CHIP:
      return {
        captionInputs: addNewToken(
          state.captionInputs,
          action.payload,
          action.rowId
        ),
      }
    case DELETE_CHIP:
      return {
        captionInputs: deleteToken(
          state.captionInputs,
          action.tokenId,
          action.rowId
        ),
      }
    case CHANGE_FOCUS_CHIP:
      return {
        captionInputs: changeFocusToken(
          state.captionInputs,
          action.focus,
          action.rowId
        ),
      }
    case CHANGE_SYNONYM_WORD:
      return {
        captionInputs: changeWord(
          state.captionInputs,
          action.rowId,
          action.indexOfToken,
          action.token
        ),
      }
    case ADD_ROW:
      return {
        captionInputs: addRow(state.captionInputs, action.rowId),
      }
    case DELETE_ROW:
      return {
        captionInputs: deleteRow(state.captionInputs, action.rowId),
      }
    case CHANGE_START_TIME:
    case CHANGE_END_TIME:
      return {
        captionInputs: changeTime(
          state.captionInputs,
          action.payload,
          action.rowId,
          action.name
        ),
      }
    case AUTO_FILL:
      return {
        captionInputs: autoFill(state.captionInputs, action.payload),
      }
    case CLEAR_CAPTION:
      return {
        captionInputs: [
          {
            start: moment().hour(0).minute(0).second(0).millisecond(0),
            end: moment().hour(0).minute(0).second(0).millisecond(0),
            subtitle: '',
            tokens: [],
            focusedChip: null,
          },
        ],
      }
    case MOVE_CHIP:
      return {
        captionInputs: moveToken(
          state.captionInputs,
          action.rowId,
          action.from,
          action.to
        ),
      }
    default:
      return state
  }
}

export default captionInputReducer
