import { createStyles, Theme } from '@material-ui/core/styles'
import { otherColor, fontSizes } from 'styles/mixins'

const navbarStyle = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    flexbox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    avatar: {
      backgroundColor: otherColor.primary,
      color: theme.palette.getContrastText(otherColor.primary),
      fontSize: fontSizes.large1,
    },
    closeButtonDrawer: {
      position: 'absolute',
      right: '8px',
      top: '9px',
      zIndex: 1,
    },
    logout: {
      color: otherColor.red,
    },
    login:{
      color: otherColor.green,
    }
  })

export default navbarStyle
