export default class Queue extends Array {
  enqueue(val: any) {
    this.push(val)
  }

  dequeue() {
    return this.shift()
  }

  peek() {
    return this[0]
  }

  isEmpty() {
    return this.length === 0
  }
}
