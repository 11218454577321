import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import CustomDialog from 'components/CustomDialog'
import CustomIconButton from 'components/CustomIconButton'
import ThreeScene from 'components/ThreeScene'
import CustomButton from 'components/CustomButton'
import AnimationSpeedSlider from 'components/AnimationSpeedSlider'
import { getAnimationKeypoint, clearKeypoint } from 'redux/keypoint/action'
import { ReactComponent as Play } from 'assets/play.svg'
import { Token } from 'types/token'
import { SceneWrapper } from './style'

interface Props {
  word: Token
}

const AnimationDialog = ({ word }: Props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [times, setTimes] = useState({
    start: moment().hour(0).minute(0).second(0),
    end: moment().hour(0).minute(0).second(1),
  })

  const handleAnimation = () => {
    dispatch(clearKeypoint())
    dispatch(getAnimationKeypoint([[word]], [times]))
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleSpeed = (value: number | number[]) => {
    if (!Array.isArray(value)) {
      const endTime = moment().hour(0).minute(0).second(value)
      setTimes({ ...times, end: endTime })
    }
  }
  
  return (
    <Fragment>
      <CustomIconButton
        tooltipLabel="See Animation"
        svgIcon={<Play width="100%" height="100%" />}
        placement="top"
        onClick={handleOpen}
        iconSize="large"
        arrow
      />
      <CustomDialog
        open={open}
        title={word.original_word}
        disabledAction={true}
        onClose={handleClose}
      >
        <AnimationSpeedSlider onChange={handleSpeed} />
        <SceneWrapper>
          <ThreeScene playerState={-1} isEditPage={false} gender="male" />
        </SceneWrapper>
        <br />
        <CustomButton
          myStyles="purplecontained"
          onClick={handleAnimation}
          fullWidth
        >
          Play Animation
        </CustomButton>
      </CustomDialog>
    </Fragment>
  )
}

export default AnimationDialog
