import { CaptionInput } from 'types/captionInput'
import { Token } from 'types/token'
import { Moment } from 'types/moment'

export const STATE_CAPTION = 'STATE_CAPTION'
export const CHANGE_START_TIME = 'CHANGE_START_TIME'
export const CHANGE_END_TIME = 'CHANGE_END_TIME'
export const ADD_CHIP = 'ADD_CHIP'
export const DELETE_CHIP = 'DELETE_CHIP'
export const CHANGE_FOCUS_CHIP = 'CHANGE_FOCUS_CHIP'
export const CHANGE_SYNONYM_WORD = 'CHANGE_SYNONYM_WORD'
export const ADD_ROW = 'ADD_ROW'
export const DELETE_ROW = 'DELETE_ROW'
export const AUTO_FILL = 'AUTO_FILL'
export const CLEAR_CAPTION = 'CLEAR_CAPTION'
export const MOVE_CHIP = 'MOVE_CHIP'
export interface InitialState {
  captionInputs: Array<CaptionInput>
}

interface StateCaption{
  type: typeof STATE_CAPTION,
  payload: string,
  rowId: number
}

interface AddChip {
  type: typeof ADD_CHIP
  payload: Array<Token>
  rowId: number
}

interface DeleteChip {
  type: typeof DELETE_CHIP
  rowId: number
  tokenId: number
}

interface ChangeFocusChip {
  type: typeof CHANGE_FOCUS_CHIP
  focus: number | null
  rowId: number
}

interface ChangeSynonymWord {
  type: typeof CHANGE_SYNONYM_WORD
  token: Token
  rowId: number
  indexOfToken: number
}

interface AddRow {
  type: typeof ADD_ROW
  rowId: number
}

interface DeleteRow {
  type: typeof DELETE_ROW
  rowId: number
}

interface ChangeTime {
  type: typeof CHANGE_START_TIME | typeof CHANGE_END_TIME
  payload: Moment
  rowId: number
  name: 'start' | 'end'
}

interface AutoFill {
  type: typeof AUTO_FILL,
  payload: Array<CaptionInput>
}

interface ClearCaption {
  type: typeof CLEAR_CAPTION,
}

interface MoveChip{
  type: typeof MOVE_CHIP,
  rowId: number,
  from: number,
  to: number
}

export type CaptionActionTypes =
  | StateCaption
  | AddChip
  | DeleteChip
  | ChangeFocusChip
  | ChangeSynonymWord
  | AddRow
  | DeleteRow
  | ChangeTime
  | AutoFill
  | ClearCaption
  | MoveChip
