import { Token } from 'types/token'

export const STATE_SEARCH_WORD = 'STATE_SEARCH_WORD'

export interface InitialState {
  foundWord: Token | undefined
}

interface StateSearchWord {
  type: typeof STATE_SEARCH_WORD
  payload: any
}

export type SearchActionTypes = StateSearchWord
