import { useDispatch } from 'react-redux'
import {
  SortableContainer,
  SortableContainerProps,
  SortEnd,
} from 'react-sortable-hoc'
import Grid from '@material-ui/core/Grid'
import SortableCustomChip from 'components/SortableCustomChip'
import { moveChipWrapper } from 'redux/captionInput/action'
import { Token } from 'types/token'
import { CaptionInput } from 'types/captionInput'
import {ChipsContainer} from './style'
interface ContainerProps extends SortableContainerProps {
  item: CaptionInput
  rowId: number
}

interface SortableChipsProps {
  item: CaptionInput
  rowId: number
}

const SortableList = SortableContainer(({ item, rowId }: ContainerProps) => {
  const { tokens, focusedChip } = item
  
  return (
    <ChipsContainer>
      <Grid container>
        {tokens.map((token: Token, index: number) => (
          <SortableCustomChip
            key={`item-${token.word_id}-${index}`}
            indexOfToken={index}
            index={index}
            item={token}
            isFocused={focusedChip === index}
            rowId={rowId}
          />
        ))}
      </Grid>
    </ChipsContainer>
  )
})

const SortableChips = ({ item, rowId }: SortableChipsProps) => {
  const dispatch = useDispatch()
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    dispatch(moveChipWrapper(rowId, oldIndex, newIndex))
  }

  return <SortableList item={item} rowId={rowId} onSortEnd={onSortEnd} axis='xy' transitionDuration={400} distance={5}/>
}

export default SortableChips
