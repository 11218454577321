import { ChangeEvent, Fragment, useState } from 'react'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

import { green, red } from '@material-ui/core/colors'
import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import CustomIconButton from 'components/CustomIconButton'
import CustomDialog from 'components/CustomDialog'

import { RequestMethod } from 'types/api'
import { WORD_STATUS } from 'types/wordStatus'
import { API_WORD_STATUS } from 'env'

interface Props {
  wordId: number
  status: WORD_STATUS
}

const StatusActions = ({ wordId, status }: Props) => {
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState('')
  const { getAccessTokenSilently } = useAuth0()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setComment(value)
  }

  const handleUpdateStatus = async (
    newStatus: WORD_STATUS,
    feedback: string
  ) => {
    try {
      const accessToken = await getAccessTokenSilently()
      const config = {
        method: RequestMethod.PUT,
        url: API_WORD_STATUS,
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
        data: {
          word_id: wordId,
          comment: feedback || '',
          status: newStatus,
        },
      }
      const res = await axios(config)
      if (res.status === 200) {
        if (open) handleClose()
        window.location.reload()
      }
    } catch (err) {
      console.error('Update status error:', err)
    }
  }

  const renderActions = () => {
    switch (status) {
      case WORD_STATUS.VALIDATING:
        return (
          <Fragment>
            <CustomIconButton
              tooltipLabel="Approve"
              svgIcon={<CheckIcon style={{ color: green[500] }} />}
              placement="top"
              iconSize="18px"
              onClick={() => handleUpdateStatus(WORD_STATUS.READY, '')}
              arrow
            />
            <CustomIconButton
              tooltipLabel="Reject"
              svgIcon={<ClearIcon style={{ color: red[500] }} />}
              placement="top"
              iconSize="18px"
              onClick={handleOpen}
              arrow
            />
          </Fragment>
        )
      case WORD_STATUS.READY:
        return (
          <Fragment>
            <CustomIconButton
              tooltipLabel="Reject"
              svgIcon={<ClearIcon style={{ color: red[500] }} />}
              placement="top"
              iconSize="18px"
              onClick={handleOpen}
              arrow
            />
          </Fragment>
        )
      default:
        return <div></div>
    }
  }

  return (
    <Fragment>
      {renderActions()}
      <CustomDialog
        title="Reject"
        open={open}
        onClose={handleClose}
        onSubmit={() => handleUpdateStatus(WORD_STATUS.REJECT, comment)}
        submitLabel="Confirm"
      >
        <TextField
          variant="outlined"
          label="Comment"
          placeholder="Please give animator a feedback"
          name="comment"
          value={comment}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          fullWidth
          rows={3}
        />
      </CustomDialog>
    </Fragment>
  )
}

export default StatusActions
