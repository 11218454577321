import styled, { css } from 'styled-components'
import TimePicker from 'rc-time-picker'
import { spaces, fontSizes, otherColor } from 'styles/mixins'

const shrinkLabel = css`
  top: 2px;
  left: 22px;
  font-size: ${fontSizes.small2};
  z-index: 1;
`

export const Container = styled.div`
  position: relative;
  padding-bottom: ${spaces.large1};
`
export const LabelWrapper = styled.div`
  background-color: #fff;
  position: absolute;
  top: 28px;
  left: 22px;
  transition: 300ms ease all;
  font-size: ${fontSizes.normal};
  padding: 0 ${spaces.normal};
  ${({ $isShrink }) => $isShrink && shrinkLabel}
`

export const MyTimePicker = styled(TimePicker)`
  margin-top: ${spaces.large1} !important;
  .rc-time-picker-input {
    font-size: ${fontSizes.normal};
    height: 56px;
    padding: ${spaces.large1};
    background-color: transparent;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${otherColor.primary};
    }
  }
  .rc-time-picker-clear {
    top: 16px;
  }
`

export const Label = styled.label`
  font-family: 'Mitr', sans-serif;
  opacity: 0.7;
`
