import { useState, MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { SortableElement, SortableElementProps } from 'react-sortable-hoc'
//material ui component
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Popover from '@material-ui/core/Popover'
import Grid from '@material-ui/core/Grid'

//icon
import ErrorIcon from '@material-ui/icons/Error'
//components
import PopupHomograph from 'components/PopupHomograph'
import { deleteChipWrapper } from 'redux/captionInput/action'
import { setDirtyBit } from 'redux/project/action'
//style
import customChipStyle from 'styles/material_ui/components/customChipStyle'
import { Token } from 'types/token'

interface Props extends SortableElementProps {
  indexOfToken: number
  item: Token
  rowId: number
  isFocused: boolean
}

const MyTooltip = withStyles(() => ({
  tooltip:{
    fontSize: 14,
    fontWeight: 200,
  }
}))(Tooltip)

const useStyles = makeStyles(customChipStyle)

const SortableCustomChip = ({
  indexOfToken,
  item,
  rowId,
  isFocused,
}: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<any>()
  const dispatch = useDispatch()
  const handleOpenPopup = (event: MouseEvent<HTMLDivElement>) => {
    dispatch(setDirtyBit())
    item.isClicked = true
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const getTitle = (token: Token) => {
    if (token.meaning) {
      return token.meaning
    } else {
      return 'ไม่มีคำนี้ในคลังคำศัพท์ภาษามือ'
    }
  }

  const handleDeleteChip = () => {
    dispatch(setDirtyBit())
    dispatch(deleteChipWrapper(rowId, indexOfToken))
  }

  const renderPopup = () => {
    if (item.alternative) {
      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <PopupHomograph
            homograph={item.alternative}
            rowId={rowId}
            handleClose={handleClosePopup}
            indexOfToken={indexOfToken}
          />
        </Popover>
      )
    } else return
  }

  let color = ''
  if (item.hasOwnProperty('POS')) {
    switch (item['POS']) {
      case 'ส':
        color = 'magenta'
        break
      case 'ก':
        color = 'green'
        break
      case 'ว':
        color = 'purple'
        break
      case 'น':
        color = 'blue'
        break
      case 'ช':
        color = 'orange'
        break
      case 'บ':
        color = 'mint'
        break
      case 'สัน':
        color = 'yellow'
        break
      case 'อ':
        color = 'pink'
        break
      case 'จ':
        color = 'darkGreen'
        break
      default:
        break
    }
  }

  const chipClasses = classNames({
    [classes.chip]: true,
    //@ts-ignore
    [classes[`${color}Chip`]]: color,
    [classes.shake]: item.hasOwnProperty('alternative') && !item.isClicked,
    [classes.focused]: isFocused,
  })

  const avatarClasses = classNames({
    //@ts-ignore
    [classes[`${color}Avatar`]]: color,
  })

  const open = Boolean(anchorEl)
  const id = open ? 'combo-box' : undefined

  return (
    <Grid item>
      <MyTooltip title={getTitle(item)} placement="top" arrow>
        <Chip
          className={chipClasses}
          label={item.original_word}
          avatar={
            <Avatar className={avatarClasses}>
              {item.POS || <ErrorIcon />}
            </Avatar>
          }
          clickable
          onClick={handleOpenPopup}
          onDelete={handleDeleteChip}
          aria-describedby={id}
        />
      </MyTooltip>
      {renderPopup()}
    </Grid>
  )
}

export default SortableElement(SortableCustomChip)
