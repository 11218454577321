import isEmpty from 'lodash/isEmpty'

export const urlMatch = (str: string) => {
  //eslint-disable-next-line
  const expression = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
  const regex = new RegExp(expression)
  const result = str.match(regex)
  if (!isEmpty(result)) return result && result[0]
  else return ''
}

export const getYoutubeVideoId = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[7].length === 11) {
    return match[7]
  } else {
    return ''
  }
}

export const validateYoutubeUrl = (url: string) => {
  if (url !== undefined || url !== '') {
    //eslint-disable-next-line
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
    const match = url.match(regExp)
    if (match && match[2].length === 11) {
      return true
    } else {
      return false
    }
  }
  return false
}
