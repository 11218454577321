import { createSelector } from 'reselect'
import { RootState } from 'redux/root-reducer'

const selectCharacter= (state: RootState) => state.character

export const selectCurrentCharacter = createSelector(
  [selectCharacter],
  (character) => character.currentCharacter
)

export const selectSkinColor = createSelector(
  [selectCharacter],
  (character) => character.currentSkinColor
)