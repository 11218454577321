import { useState, Fragment } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import customDropdownStyle from 'styles/material_ui/components/customDropdownStyle.js'

const useStyles = makeStyles(customDropdownStyle)
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    maxHeight: '200px',
    minWidth: '100px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const CustomDropdown = ({
  children,
  options,
  disabled = false,
  handleClickItem,
  startIcon,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, option) => {
    handleClickItem(event, option)
    handleClose()
  }
  return (
    <Fragment>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        disabled={disabled}
        {...props}
      >
        {startIcon && <span className={classes.icon}>{startIcon}</span>}
        {children}
        <ArrowDropDownIcon className={classes.caret} />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              key={option}
              onClick={(event) => handleClick(event, option)}
            >
              {option}
            </MenuItem>
          ))}
      </StyledMenu>
    </Fragment>
  )
}

export default CustomDropdown
