import { Fragment } from 'react'
import Navbar from 'components/Navbar'
import LoginImageOverlay from 'assets/login_overlay.svg'
import { ImageWrapper, ImageOverlay } from './style'

const LoginOverlayPage = () => {

  return (
    <Fragment>
      <Navbar />
      <ImageWrapper>
        <ImageOverlay src={LoginImageOverlay} alt="locked" />
        <br />
        <br />
        <h4>Please login for further process.</h4>
      </ImageWrapper>
    </Fragment>
  )
}

export default LoginOverlayPage
