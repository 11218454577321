import axios from 'axios'
import { Dispatch } from 'redux'
import {
  STATE_PROJECT_INFO,
  CHANGE_PROJECT_NAME,
  CHANGE_YOUTUBE_ID,
  CLEAR_PROJECT_CACHE,
  ProjectActionTypes,
  SET_DIRTY,
  RESET_DIRTY,
  SAVE_PROJECT_START,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAILURE,
  RESET_SAVE_STATUS,
} from 'redux/project/types'
import { showAlert } from 'redux/alert/action'
import { fetchKeypointSuccess } from 'redux/keypoint/action'
import { getStartAndDuration } from 'utils/time'
import { getTokens } from 'utils/arrayTools'
import { ProjectInfo } from 'types/project'
import { RequestMethod } from 'types/api'
import { CaptionsToSave, CaptionInput } from 'types/captionInput'
import { API_PROJECT,API_KEYPOINT } from 'env'

export const stateProjectInfo = (info: ProjectInfo): ProjectActionTypes => ({
  type: STATE_PROJECT_INFO,
  name: info.name,
  project_id: info.project_id,
  captions: info.captions,
  youtube_id: info.youtube_id,
  status: info.status,
})

export const changeProjectName = (name: string): ProjectActionTypes => ({
  type: CHANGE_PROJECT_NAME,
  payload: name,
})

export const changeYoutubeId = (id: string): ProjectActionTypes => ({
  type: CHANGE_YOUTUBE_ID,
  payload: id,
})

export const clearProjectCache = (): ProjectActionTypes => ({
  type: CLEAR_PROJECT_CACHE,
})

export const setDirtyBit = (): ProjectActionTypes => ({
  type: SET_DIRTY,
})

export const resetDirtyBit = (): ProjectActionTypes => ({
  type: RESET_DIRTY,
})

const saveStart = (): ProjectActionTypes => ({
  type: SAVE_PROJECT_START,
})

const saveSuccess = (): ProjectActionTypes => ({
  type: SAVE_PROJECT_SUCCESS,
})

const saveFailure = (): ProjectActionTypes => ({
  type: SAVE_PROJECT_FAILURE,
})

export const resetSaveStatus = (): ProjectActionTypes => ({
  type: RESET_SAVE_STATUS,
})

export const saveProject = (
  projectName: string,
  youtubeId: string,
  projectId: string,
  captions: CaptionInput[] | CaptionsToSave[],
  accessToken: string
) => {
  return async (dispatch: Dispatch) => {
    const captionsToSave: Array<CaptionsToSave> = []
    captions.forEach((caption:any) => {
      if (!caption.hasOwnProperty('duration')) {
        if (caption.tokens.length !== 0) {
          const { start, duration } = getStartAndDuration(
            caption.start,
            caption.end
          )
          captionsToSave.push({
            start: start,
            duration: duration,
            tokens: getTokens(caption.tokens),
          })
        }
      }
    })
    try {
      dispatch(saveStart())
      const config = {
        method: RequestMethod.PUT,
        url: API_PROJECT,
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
        data: {
          name: projectName,
          youtube_id: youtubeId,
          project_id: projectId,
          captions: captionsToSave,
        },
      }
      const res = await axios(config)
      const data = res.data
      if (data.status === 'success') {
        dispatch(saveSuccess())
        dispatch(resetDirtyBit())
      }
    } catch (err) {
      const message = err.response.data.error
      dispatch(saveFailure())
      dispatch(showAlert(message, 'error'))
    }
  }
}

export const loadPlayerPage = (projectId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const config = {
        method: RequestMethod.GET,
        url: `${API_PROJECT}/${projectId}`,
      }
      const res = await axios(config)
      const data = res.data
      const { captions } = data
      dispatch(stateProjectInfo(data))
      const wordList: Array<any> = []
      const times: Array<any> = []
      captions.forEach((ele: CaptionsToSave) => {
        wordList.push(ele.tokens)
        times.push({
          start_time: ele.start,
          duration: ele.duration,
        })
      })
      const animationConfig = {
        method: RequestMethod.POST,
        url: API_KEYPOINT,
        data: {
          sentences: wordList,
        },
      }
      const resAnimation = await axios(animationConfig)
      const keypoint = resAnimation.data
      dispatch(fetchKeypointSuccess(keypoint, times))
    } catch (err) {
      console.error('err', err)
    }
  }
}
