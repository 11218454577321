import styled, { keyframes } from 'styled-components'
import { black } from 'styles/mixins'

const rotate = keyframes`
0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
    -ms-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
    -moz-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
    -ms-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
    -moz-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -ms-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
    -moz-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
`

export const Overlay = styled.div`
  background-color: ${black};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
`

export const Loading = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #fff;
  -webkit-animation: ${rotate} 1.2s infinite ease-in-out;
  -moz-animation: ${rotate} 1.2s infinite ease-in-out;
  -ms-animation: ${rotate} 1.2s infinite ease-in-out;
  animation: ${rotate} 1.2s infinite ease-in-out;
`
