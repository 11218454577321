import { useState, Fragment, MouseEvent } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/Delete'
import ShareIcon from '@material-ui/icons/Share'
import Popover from '@material-ui/core/Popover'

import CustomAlertDialog from 'components/CustomAlertDialog'
import CustomIconButton from 'components/CustomIconButton'
import LinkSharing from 'components/LinkSharing'

import { getYoutubeThumbnail } from 'utils/youtube'

import { clearCaption } from 'redux/captionInput/action'
import { showAlert } from 'redux/alert/action'

import { RequestMethod } from 'types/api'
import { API_PROJECT } from 'env'

interface Data {
  created_date: string
  name: string
  project_id: string
  youtube_id: string
}

interface Props {
  data: Data
}

const ProjectCard = ({ data }: Props) => {
  const [openBin, setOpenBin] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const { getAccessTokenSilently } = useAuth0()

  const history = useHistory()
  const dispatch = useDispatch()
  const handleOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenProject = () => {
    dispatch(clearCaption())
    const url = `/edit/${data.project_id}`
    history.push(url)
  }

  const handleClickOpenBin = () => {
    setOpenBin(true)
  }

  const handleCloseBin = () => {
    setOpenBin(false)
  }

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently()
    const config = {
      method: RequestMethod.DELETE,
      url: `${API_PROJECT}/${data.project_id}`,
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    }
    try {
      const res = await axios(config)
      if (res.status === 200) {
        const message = `Delete ${data.name} Success`
        dispatch(showAlert(message))
        window.location.reload(true)
      }
    } catch (err) {
      const message = `Delete ${data.name} Fail`
      dispatch(showAlert(message, 'error'))
      console.error('err', err)
    }
    handleCloseBin()
  }

  const renderHeaderAction = () => (
    <Box display="flex" justifyContent="flex-end">
      <div>
        <CustomIconButton
          onClick={handleClickOpenBin}
          svgIcon={<DeleteIcon />}
          tooltipLabel="Delete"
          placement="top"
        />
        <CustomAlertDialog
          title="Are you sure to delete this project?"
          onAgree={handleDelete}
          onDisagree={handleCloseBin}
          handleClose={handleCloseBin}
          content="This project will permanently delete from your account."
          open={openBin}
        />
        <CustomIconButton
          onClick={handleOpen}
          svgIcon={<ShareIcon />}
          tooltipLabel="Share"
          placement="top"
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <LinkSharing
            value={`${window.location.origin}/player/${data.project_id}`}
          />
        </Popover>
      </div>
    </Box>
  )

  const open = Boolean(anchorEl)
  const id = open ? 'share-popup' : undefined

  return (
    <Fragment>
      {data && (
        <Card>
          <CardHeader
            title={data.name}
            subheader={`Published: ${data.created_date}`}
            action={renderHeaderAction()}
          />
          <CardActionArea onClick={handleOpenProject}>
            <CardMedia
              component="img"
              alt={data.name}
              image={getYoutubeThumbnail(data.youtube_id)}
              title={data.name}
            />
          </CardActionArea>
        </Card>
      )}
    </Fragment>
  )
}

export default ProjectCard
