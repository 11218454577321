import styled, { css } from 'styled-components'
import { mediaTabLand } from 'styles/media'

export const SceneWrapper = styled.div`
  width: 400px;
  height: 400px;
  margin: 0 auto;
  ${mediaTabLand(css`
    width: 300px;
    height: 300px;
  `)}
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
`
