import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

import { changeSynonymWordWrapper } from 'redux/captionInput/action'

import { Token } from 'types/token'

import popupHomographStyle from 'styles/material_ui/components/popupHomographStyle'

interface Props {
  indexOfToken: number
  homograph: Array<Token>
  rowId: number
  handleClose: () => void
}

const useStyles = makeStyles(popupHomographStyle)

const PopupHomograph = ({
  indexOfToken,
  homograph,
  rowId,
  handleClose,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleClick = (homograph: Token) => {
    handleClose()
    dispatch(changeSynonymWordWrapper(homograph, rowId, indexOfToken))
  }

  return (
    <div className={classes.root}>
      <List
        component="nav"
        aria-label="popup-list"
        subheader={
          <ListSubheader component="div" id="popup-list">
            โปรดเลือกความหมาย
          </ListSubheader>
        }
      >
        {homograph.map((word: Token, i: number) => (
          <ListItem
            button
            key={`${word.original_word}_${i}`}
            onClick={() => handleClick(word)}
          >
            <ListItemText
              primary={`${word.original_word} (${word.POS}.)`}
              secondary={word.meaning}
            />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default PopupHomograph
