import { createStyles, Theme } from '@material-ui/core'
import {
  spaces,
  borders,
  redPalette,
  bluePalette,
  orangePalette,
  mintPalette,
  purplePalette,
  magentaPalette,
  greenPalette,
  yellowPalette,
  pinkPalette,
  black,
  gray,
} from 'styles/mixins'

const customButtonStyle = (theme: Theme) =>
  createStyles({
    button: {
      margin: `0 ${spaces.normal}`,
    },
    disabled:{
      color: black + '!important',
      backgroundColor: gray[500] + '!important',
    },
    black:{
      color: black,
    },
    red:{
      color: redPalette[400],
    },
    redcontained: {
      backgroundColor: redPalette[400],
      color: theme.palette.getContrastText(redPalette[400]),
      '&:hover, &:focus': {
        backgroundColor: redPalette[600],
      },
    },
    redoutlined: {
      borderRadius: borders.default,
      borderColor: redPalette[400],
      borderWidth: 1,
      borderStyle: 'solid',
      color: redPalette[400],
      '&:hover, &:focus': {
        backgroundColor: redPalette[400],
        color: '#fff',
      },
    },
    blue:{
      color: bluePalette[500],
    },
    bluecontained: {
      backgroundColor: bluePalette[500],
      color: theme.palette.getContrastText(bluePalette[500]),
      '&:hover, &:focus': {
        backgroundColor: bluePalette[700],
      },
    },
    blueoutlined: {
      borderRadius: borders.default,
      borderColor: bluePalette[500],
      borderWidth: 1,
      borderStyle: 'solid',
      color: bluePalette[500],
      '&:hover, &:focus': {
        backgroundColor: bluePalette[500],
        color: '#fff',
      },
    },
    orange:{
      color: orangePalette[500],
    },
    orangecontained: {
      backgroundColor: orangePalette[400],
      color: theme.palette.getContrastText(orangePalette[400]),
      '&:hover, &:focus': {
        backgroundColor: orangePalette[800],
      },
    },
    orangeoutlined: {
      borderRadius: borders.default,
      borderColor: orangePalette[500],
      borderWidth: 1,
      borderStyle: 'solid',
      color: orangePalette[500],
      '&:hover, &:focus': {
        backgroundColor: orangePalette[500],
        color: '#fff',
      },
    },
    mint:{
      color: mintPalette[700],
    },
    mintcontained: {
      backgroundColor: mintPalette[100],
      color: theme.palette.getContrastText(mintPalette[100]),
      '&:hover, &:focus': {
        backgroundColor: mintPalette[600],
      },
    },
    mintoutlined: {
      borderRadius: borders.default,
      borderColor: mintPalette[700],
      borderWidth: 1,
      borderStyle: 'solid',
      color: mintPalette[700],
      '&:hover, &:focus': {
        backgroundColor: mintPalette[700],
        color: '#fff',
      },
    },
    purple:{
      color: purplePalette[500],
    },
    purplecontained: {
      backgroundColor: purplePalette[500],
      color: theme.palette.getContrastText(purplePalette[500]),
      '&:hover, &:focus': {
        backgroundColor: purplePalette[700],
      },
    },
    purpleoutlined: {
      borderRadius: borders.default,
      borderColor: purplePalette[500],
      borderWidth: 1,
      borderStyle: 'solid',
      color: purplePalette[500],
      '&:hover, &:focus': {
        backgroundColor: purplePalette[500],
        color: '#fff',
      },
    },
    magenta:{
      color: magentaPalette[500],
    },
    magentacontained: {
      backgroundColor: magentaPalette[300],
      color: theme.palette.getContrastText(magentaPalette[300]),
      '&:hover, &:focus': {
        backgroundColor: magentaPalette[500],
      },
    },
    magentaoutlined: {
      borderRadius: borders.default,
      borderColor: magentaPalette[500],
      borderWidth: 1,
      borderStyle: 'solid',
      color: magentaPalette[500],
      '&:hover, &:focus': {
        backgroundColor: magentaPalette[500],
        color: '#fff',
      },
    },
    green:{
      color: greenPalette[800],
    },
    greencontained: {
      backgroundColor: greenPalette[600],
      color: theme.palette.getContrastText(greenPalette[600]),
      '&:hover, &:focus': {
        backgroundColor: greenPalette[900],
      },
    },
    greenoutlined: {
      borderRadius: borders.default,
      borderColor: greenPalette[800],
      borderWidth: 1,
      borderStyle: 'solid',
      color: greenPalette[800],
      '&:hover, &:focus': {
        backgroundColor: greenPalette[800],
        color: '#fff',
      },
    },
    yellow:{
      color: yellowPalette[800],
    },
    yellowcontained: {
      backgroundColor: yellowPalette[500],
      color: theme.palette.getContrastText(yellowPalette[500]),
      '&:hover, &:focus': {
        backgroundColor: yellowPalette[700],
      },
    },
    yellowoutlined: {
      borderRadius: borders.default,
      borderColor: yellowPalette[800],
      borderWidth: 1,
      borderStyle: 'solid',
      color: yellowPalette[800],
      '&:hover, &:focus': {
        backgroundColor: yellowPalette[800],
        color: '#fff',
      },
    },
    pink:{
      color: pinkPalette[300],
    },
    pinkcontained: {
      backgroundColor: pinkPalette[500],
      color: theme.palette.getContrastText(pinkPalette[500]),
      '&:hover, &:focus': {
        backgroundColor: pinkPalette[700],
      },
    },
    pinkoutlined: {
      borderRadius: borders.default,
      borderColor: pinkPalette[300],
      borderWidth: 1,
      borderStyle: 'solid',
      color: pinkPalette[300],
      '&:hover, &:focus': {
        backgroundColor: pinkPalette[300],
        color: '#fff',
      },
    },
  })

export default customButtonStyle
