import { createStyles, Theme } from '@material-ui/core'
import { fontWeights } from 'styles/mixins'
const customAutocompleteSearchStyle = (theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 2px 6px 1px rgba(98, 61, 214, 0.2)',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      fontWeight: parseInt(fontWeights.light),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '4px',
      },
    },
  })

export default customAutocompleteSearchStyle
