import styled from 'styled-components'
import { fontWeights, spaces, gray } from 'styles/mixins'

export const Container = styled.div`
    &:not(:last-child){
        padding-bottom: ${spaces.large2};
        border-bottom: 1px solid ${gray[300]};
        margin-bottom: ${spaces.large2};
    }
`

export const Topic = styled.h4 `
    font-weight: ${fontWeights.bold};
`

export const Detail = styled.h4`
    margin-left: ${spaces.normal};
`