import { makeStyles, Theme } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button, { ButtonProps } from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

interface Props extends ButtonProps {
  loading: boolean
  progress?: number
}

const CustomProgressButton = ({ loading, progress, ...props }: Props) => {
  const classes = useStyles()
  const renderLabel = () => {
    if (loading && progress) {
      return `${progress}%`
    } else return props.children
  }
  
  return (
    <div className={classes.wrapper}>
      <Button
        {...props}
        variant="contained"
        color="primary"
        className={classes.buttonSuccess}
        disabled={loading}
      >
        {renderLabel()}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}

export default CustomProgressButton
