import {
  LoadingAcionTypes,
  LOADING_START,
  LOADING_STOP,
  LOADING_PLAYER_PAGE_START,
  LOADING_PLAYER_PAGE_STOP
} from 'redux/loading/types'

export const loadingStart = (): LoadingAcionTypes => ({
  type: LOADING_START,
})

export const loadingStop = (): LoadingAcionTypes => ({
  type: LOADING_STOP,
})

export const loadingPlayerPageStart = (): LoadingAcionTypes => ({
  type: LOADING_PLAYER_PAGE_START
})

export const loadingPlayerPageStop = (): LoadingAcionTypes => ({
  type: LOADING_PLAYER_PAGE_STOP
})