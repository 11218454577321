import { createSelector } from 'reselect'
import { RootState } from 'redux/root-reducer'

const selectProject = (state: RootState) => state.project

export const selectProjectInfo = createSelector(
  [selectProject],
  (project) => project
)
export const selectProjectName = createSelector(
  [selectProject],
  (project) => project.name
)
export const selectYoutubeId = createSelector(
  [selectProject],
  (project) => project.youtube_id
)

export const selectDirtyBit = createSelector(
  [selectProject],
  (project) => project.dirty_bit
)

export const selectSaveStatus = createSelector(
  [selectProject],
  (project) => project.save_status
)

export const selectProjectCaptions = createSelector(
  [selectProject],
  project => project.captions
)
