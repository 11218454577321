import { Fragment } from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import CustomButton from 'components/CustomButton'

interface Props extends DialogProps {
  title: string
  onClose: () => void
  onSubmit?: () => void
  disabledSubmit?: boolean
  cancelLabel?: string
  submitLabel?: string
  disabledAction?: true
}
/*Create dialog with custom body, title, and actions*/
const CustomDialog = ({
  title,
  onClose,
  onSubmit,
  disabledSubmit,
  cancelLabel,
  submitLabel,
  disabledAction,
  ...props
}: Props) => {
  return (
    <Fragment>
      <Dialog {...props} fullWidth={true} disableBackdropClick={true}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <h5>{title}</h5>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>{props.children}</DialogContent>
        {!disabledAction && (
          <DialogActions>
            <CustomButton onClick={onClose} myStyles="black">
              {cancelLabel || `Cancel`}
            </CustomButton>
            <CustomButton
              onClick={onSubmit}
              disabled={disabledSubmit}
              myStyles="purplecontained"
            >
              {submitLabel || `Submit`}
            </CustomButton>
          </DialogActions>
        )}
      </Dialog>
    </Fragment>
  )
}

export default CustomDialog
