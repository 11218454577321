import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { CaptionInput } from 'types/captionInput'
import { Token } from 'types/token'
import { StartEndMoment } from 'types/moment'

export const replaceSpaceInArray = function (str: string) {
  return str.replace(/\s+/g, '')
}

export const getListOfArrayObjectValue = (arr: Array<any>, key: string) => {
  let result: Array<any> = []
  if (arr.length) {
    arr.map((element) => result.push(element[key]))
  }
  return result
}

export const getArrayOfWordsAndTime = (caption: CaptionInput) => {
  let words: Array<Token> = []
  let time: StartEndMoment = {
    start: moment().hour(0).minute(0).second(0).millisecond(0),
    end: moment().hour(0).minute(0).second(0).millisecond(0),
  }
  if (!isEmpty(caption.tokens)) {
    time = {
      start: caption.start,
      end: caption.end,
    }
    caption.tokens.forEach((token) => {
      words.push({
        original_word: token.original_word,
        word_id: token.word_id,
        POS: token.POS,
      })
    })
  }
  return { words, time }
}

export const getTotalPage = (arr: Array<CaptionInput>, maxRows: number) => {
  let totalPage = 0
  if (!isEmpty(arr)) {
    totalPage = Math.ceil(arr.length / maxRows)
  }
  if (totalPage === 0) {
    totalPage = 1
  }

  return totalPage
}

export const getRowsStartEndIndex = (page: number) => {
  const startIndex = (page - 1) * 10
  const endIndex = startIndex + 9

  return { startIndex, endIndex }
}

export const getValueFromArrayObject = (arr: Array<any>, key: string) => {
  const result: Array<any> = []
  if (arr) {
    arr.forEach((el) => {
      result.push(el[key])
    })
  }
  return result
}

export const getTokens = (tokens: Array<Token>) => {
  let result: Array<Token> = []
  tokens.forEach((token: Token) => {
    result.push({ ...token })
  })
  return result
}