import {Theme,createStyles} from '@material-ui/core'
import {
  spaces,
  bluePalette,
  orangePalette,
  mintPalette,
  purplePalette,
  magentaPalette,
  greenPalette,
  yellowPalette,
  fontWeights,
  pinkPalette,
  otherColor,
} from 'styles/mixins'

const customChipStyle = (theme:Theme) => createStyles({
  chip: {
    margin: `${spaces.normal} ${spaces.small1} 0 ${spaces.small1}`,
    fontWeight: parseInt(fontWeights.thin),
  },
  blueChip: {
    backgroundColor: bluePalette[600],
    color: theme.palette.getContrastText(bluePalette[600]),
    '&:hover, &:focus': {
      backgroundColor: bluePalette[700],
    },
  },
  blueAvatar: {
    backgroundColor: bluePalette[800],
    color: theme.palette.getContrastText(bluePalette[800]) + ' !important',
  },
  orangeChip: {
    backgroundColor: orangePalette[400],
    color: theme.palette.getContrastText(orangePalette[400]),
    '&:hover, &:focus': {
      backgroundColor: orangePalette[500],
    },
  },
  orangeAvatar: {
    backgroundColor: orangePalette[800],
    color: theme.palette.getContrastText(orangePalette[800]) + ' !important',
  },
  mintChip: {
    backgroundColor: mintPalette[500],
    color: theme.palette.getContrastText(mintPalette[500]),
    '&:hover, &:focus': {
      backgroundColor: mintPalette[600],
    },
  },
  mintAvatar: {
    backgroundColor: mintPalette[800],
    color: theme.palette.getContrastText(mintPalette[800]) + ' !important',
  },
  purpleChip: {
    backgroundColor: purplePalette[300],
    color: theme.palette.getContrastText(purplePalette[300]),
    '&:hover, &:focus': {
      backgroundColor: purplePalette[400],
    },
  },
  purpleAvatar: {
    backgroundColor: purplePalette[500],
    color: theme.palette.getContrastText(purplePalette[500]) + ' !important',
  },
  magentaChip: {
    backgroundColor: magentaPalette[500],
    color: theme.palette.getContrastText(magentaPalette[500]),
    '&:hover, &:focus': {
      backgroundColor: magentaPalette[600],
    },
  },
  magentaAvatar: {
    backgroundColor: magentaPalette[800],
    color: theme.palette.getContrastText(magentaPalette[800]) + ' !important',
  },
  greenChip: {
    backgroundColor: greenPalette[500],
    color: theme.palette.getContrastText(greenPalette[500]),
    '&:hover, &:focus': {
      backgroundColor: greenPalette[600],
    },
  },
  greenAvatar: {
    backgroundColor: greenPalette[700],
    color: theme.palette.getContrastText(greenPalette[700]) + ' !important',
  },
  yellowChip: {
    backgroundColor: yellowPalette[500],
    color: theme.palette.getContrastText(yellowPalette[500]),
    '&:hover, &:focus': {
      backgroundColor: yellowPalette[600],
    },
  },
  yellowAvatar: {
    backgroundColor: yellowPalette[700],
    color: theme.palette.getContrastText(yellowPalette[700]) + ' !important',
  },
  pinkChip: {
    backgroundColor: pinkPalette[100],
    color: theme.palette.getContrastText(pinkPalette[100]),
    '&:hover, &:focus': {
      backgroundColor: pinkPalette[200],
    },
  },
  pinkAvatar: {
    backgroundColor: pinkPalette[300],
    color: theme.palette.getContrastText(pinkPalette[300]) + ' !important',
  },
  darkGreenChip:{
    backgroundColor: otherColor.darkGreen,
    color: theme.palette.getContrastText(otherColor.darkGreen),
    '&:hover, &:focus': {
      backgroundColor: otherColor.darkerGreen,
    },
  },
  darkGreenAvatar: {
    backgroundColor: otherColor.darkestGreen,
    color: theme.palette.getContrastText(otherColor.darkestGreen) + ' !important',
  },
  transparentBg: {
    backgroundColor: 'transparent',
  },
  focused: {
    transition: 'all .2s',
    boxShadow: `0px 0px 8px ${bluePalette[800]}`,
  },
  shake: {
    animation: `$shaking .2s linear infinite`,
  },
  '@keyframes shaking': {
    '0%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(2deg)' },
    '50%': { transform: 'rotate(0deg)' },
    '75%': { transform: 'rotate(-2deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
})

export default customChipStyle
