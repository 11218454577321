declare var process: {
  env: {
    NODE_ENV: 'development' | 'production'
    REACT_APP_API: string
    REACT_APP_BLENDER_FILE_URL: string
    REACT_APP_AUTH0_DOMAIN: string
    REACT_APP_AUTH0_CLIENT_ID: string
    REACT_APP_AUTH0_ROLE_CUSTOM_CLAIM: string
    REACT_APP_AUTH0_LINKACCOUNT_CUSTOM_CLAIM: string
  }
}

export const API_BASE_URL = process.env.REACT_APP_API
export const BLENDER_FILE_URL = process.env.REACT_APP_BLENDER_FILE_URL
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_ROLE_CUSTOM_CLAIM = process.env.REACT_APP_AUTH0_ROLE_CUSTOM_CLAIM
export const AUTH0_LINKACCOUNT_CUSTOM_CLAIM = process.env.REACT_APP_AUTH0_LINKACCOUNT_CUSTOM_CLAIM

//API
export const API_KEYPOINT = `${API_BASE_URL}/api/keypoint_facial_alex`
export const API_PROJECT = `${API_BASE_URL}/api/project`
export const API_SEARCH_WORD = `${API_BASE_URL}/api/word/search`
export const API_SPEECH_TOKEN = `${API_BASE_URL}/api/speech_token`
export const API_SPEECH_TO_TEXT = `${API_BASE_URL}/api/speech_to_text`
export const API_TOKENIZATION = `${API_BASE_URL}/api/tokenization`
export const API_USER = `${API_BASE_URL}/api/user`
export const API_USER_ROLE = `${API_BASE_URL}/api/user/role`
export const API_WORD = `${API_BASE_URL}/api/word`
export const API_WORD_STATUS = `${API_BASE_URL}/api/word/status`
export const API_UPLOAD_BLEND = `${API_BASE_URL}/api/word/pose`
export const API_YOUTUBE_TRANSCRIPT = `${API_BASE_URL}/api/youtube_transcript`
export const API_CATEGORY = `${API_BASE_URL}/api/word/category`

//Auth0
export const AUDIENCE = API_BASE_URL
export const SCOPE = `read:current_user`