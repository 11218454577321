import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import youtubeTranscriptReducer from 'redux/youtubeTranscript/reducer'
import keypointReducer from 'redux/keypoint/reducer'
import tokenizationReducer from 'redux/tokenization/reducer'
import alertReducer from 'redux/alert/reducer'
import loadingReducer from 'redux/loading/reducer'
import searchReducer from 'redux/search/reducer'
import languageReducer from 'redux/language/reducer'
import captionInputReducer from 'redux/captionInput/reducer'
import charactersReducer from 'redux/characters/reducer'
import modelPlayerReducer from 'redux/modelPlayer/reducer'
import projectReducer from 'redux/project/reducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  backlist: ['keypoint', 'youtubeSubtitle', 'tokenization', 'edit', 'language'],
}

const rootReducer = combineReducers({
  keypoint: keypointReducer,
  youtubeTranscript: youtubeTranscriptReducer,
  tokenization: tokenizationReducer,
  alert: alertReducer,
  loading: loadingReducer,
  search: searchReducer,
  language: languageReducer,
  character: charactersReducer,
  modelPlayer: modelPlayerReducer,
  captions: captionInputReducer,
  project: projectReducer,
})

export default persistReducer(persistConfig, rootReducer)
export type RootState = ReturnType<typeof rootReducer>
