import { fontSizes } from 'styles/mixins'
import { createStyles } from '@material-ui/core'

const playerPageStyle = createStyles({
  iconBtn: {
    color: '#fff',
    fontSize: fontSizes.large8,
  },
  playIconMobile:{
    color: '#fff',
    fontSize: fontSizes.large4,
  },
  shareButton: {
    color: '#fff',
  },
  root: {
    maxWidth: '200px',
  },
})

export default playerPageStyle
