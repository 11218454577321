import {
  GET_TRANSCRIPT_START,
  GET_TRANSCRIPT_SUCCESS,
  GET_TRANSCRIPT_FAILURE,
  YoutubeTranscriptActionTypes,
  InitialState,
  CLEAR_TRANSCRIPT,
} from 'redux/youtubeTranscript/types'

const INITIAL_STATE: InitialState = {
  loading: false,
  transcript: [],
  error: '',
}

const youtubeTranscriptReducer = (
  state = INITIAL_STATE,
  action: YoutubeTranscriptActionTypes
): InitialState => {
  switch (action.type) {
    case GET_TRANSCRIPT_START:
      return { ...state, loading: true }
    case GET_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        loading: false,
        transcript: action.payload,
        error: '',
      }
    case GET_TRANSCRIPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case CLEAR_TRANSCRIPT:
      return INITIAL_STATE
    default:
      return state
  }
}

export default youtubeTranscriptReducer
