import { YoutubeTranscript } from 'types/transcript'
import { CaptionsToSave } from 'types/captionInput'

import {
  GET_TRANSCRIPT_START,
  GET_TRANSCRIPT_SUCCESS,
  GET_TRANSCRIPT_FAILURE,
  YoutubeTranscriptActionTypes,
  CLEAR_TRANSCRIPT,
} from 'redux/youtubeTranscript/types'


export const retrieveTranscriptStart = () => ({
  type: GET_TRANSCRIPT_START,
})

export const retrieveTranscriptSuccess = (
  transcript: Array<YoutubeTranscript> | Array<CaptionsToSave>
): YoutubeTranscriptActionTypes => ({
  type: GET_TRANSCRIPT_SUCCESS,
  payload: transcript,
})

export const retrieveTranscriptFailure = (
  message: string
): YoutubeTranscriptActionTypes => ({
  type: GET_TRANSCRIPT_FAILURE,
  payload: message,
})

export const clearTranscript = () => ({
  type: CLEAR_TRANSCRIPT
})

