import { useState, memo } from 'react'
import Slider from '@material-ui/core/Slider'
import { Container } from './style'

interface Props {
  onChange: (newValue: number | number[]) => void
}

const AnimationSpeedSlider = ({ onChange }: Props) => {
  const [value, setValue] = useState<number | number[]>(1)
  const marks = [
    {
      value: 1,
      label: '5',
    },
    {
      value: 2,
      label: '4',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '2',
    },
    {
      value: 5,
      label: '1',
    },
  ]
  const handleChange = (_event: any, newValue: number | number[]) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <Container>
      <h4>Speed</h4>
      <Slider
        defaultValue={1}
        value={value}
        step={1}
        min={1}
        max={5}
        valueLabelDisplay="auto"
        marks={marks}
        onChange={handleChange}
      />
    </Container>
  )
}

export default memo(AnimationSpeedSlider)
