import React, { MouseEvent, FocusEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import playerPageStyle from 'styles/material_ui/views/playerPageStyle'
import { Container } from './styles'
//http://localhost:3000/player/c_LSBRJB3wE/108
interface Props {
  value: string
}

const useStyles = makeStyles(playerPageStyle)

const LinkSharing = ({ value }: Props) => {
  const classes = useStyles()
  const linkRef = useRef<any>(null)
  const { t } = useTranslation()

  const copyToClipboard = (
    event: MouseEvent<HTMLButtonElement> | FocusEvent
  ) => {
    const target = event.target as HTMLElement
    linkRef.current.select()
    document.execCommand('copy')
    target.focus()
  }

  return (
    <Container>
      <TextField
        variant="outlined"
        label={t('player.header.tooltab.share.popup.label')}
        defaultValue={value}
        classes={{ root: classes.root }}
        size="small"
        inputRef={linkRef}
        InputProps={{
          readOnly: true,
        }}
        onFocus={copyToClipboard}
      />
      <Button color="primary" onClick={copyToClipboard}>
        {t('player.header.tooltab.share.popup.copy')}
      </Button>
    </Container>
  )
}

export default LinkSharing
