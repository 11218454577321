import styled from 'styled-components'
import { spaces } from 'styles/mixins'

export const Container = styled.div`
  padding: 0 ${spaces.large4} ${spaces.large2};
  height: 80vh;
  overflow-y: scroll;
`

export const DescriptionContainer = styled.div`
  margin: ${spaces.large2} 0;
  text-align: center;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spaces.large2};
`
