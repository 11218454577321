import {
  InitialState,
  STATE_PROJECT_INFO,
  CHANGE_PROJECT_NAME,
  CHANGE_YOUTUBE_ID,
  ProjectActionTypes,
  CLEAR_PROJECT_CACHE,
  SET_DIRTY,
  RESET_DIRTY,
  SAVE_PROJECT_START,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAILURE,
  RESET_SAVE_STATUS,
} from 'redux/project/types'

const INITIAL_STATE: InitialState = {
  name: '',
  project_id: '',
  youtube_id: '',
  captions: [],
  status: 'Not found',
  dirty_bit: false,
  save_status: 'save',
}

const projectReducer = (
  state = INITIAL_STATE,
  action: ProjectActionTypes
): InitialState => {
  switch (action.type) {
    case STATE_PROJECT_INFO:
      return {
        ...state,
        name: action.name,
        project_id: action.project_id,
        youtube_id: action.youtube_id,
        captions: action.captions,
        status: action.status,
      }
    case CHANGE_PROJECT_NAME:
      return {
        ...state,
        name: action.payload,
      }
    case CHANGE_YOUTUBE_ID:
      return {
        ...state,
        youtube_id: action.payload,
      }
    case SET_DIRTY:
      return {
        ...state,
        dirty_bit: true,
      }
    case RESET_DIRTY:
      return {
        ...state,
        dirty_bit: false,
      }
    case SAVE_PROJECT_START:
      return {
        ...state,
        save_status: 'saving...',
      }
    case SAVE_PROJECT_SUCCESS:
      return {
        ...state,
        save_status: 'saved',
      }
    case SAVE_PROJECT_FAILURE:
      return {
        ...state,
        save_status: 'save',
      }
    case RESET_SAVE_STATUS:
      return {
        ...state,
        save_status: 'save',
      }
    case CLEAR_PROJECT_CACHE:
      return INITIAL_STATE
    default:
      return state
  }
}

export default projectReducer
