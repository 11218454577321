import { Overlay, Loading } from './style'

const Spinner = () => {
  return (
    <Overlay>
      <Loading />
    </Overlay>
  )
}

export default Spinner
