import { Language } from 'types/language'

export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'

export interface InitialState{
    lang: Language
}

interface SwitchLanguage {
    type: typeof SWITCH_LANGUAGE,
    payload: Language
}

export type LanguageActionTypes = SwitchLanguage