import { useState, useEffect, Fragment, ChangeEvent } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import YouTube from 'react-youtube'
import { useParams } from 'react-router-dom'
import useMeasure from 'react-use-measure'
import { useAuth0 } from '@auth0/auth0-react'

//material ui core component
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption'

//component
import ThreeScene from 'components/ThreeScene'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import CustomAlert from 'components/CustomAlert'
import CaptionCard from 'components/CaptionCard'
import CustomIconButton from 'components/CustomIconButton'
import SpinnerOverlay from 'components/SpinnerOverlay'
//utils
import { getYoutubeVideoId, validateYoutubeUrl } from 'utils/regex'
import { ReactComponent as SpeechToText } from 'assets/voice-message.svg'
import { usePrompt } from 'utils/hook'
//redux
import { showAlert } from 'redux/alert/action'
import { loadingStart, loadingStop } from 'redux/loading/action'
import { stateProjectInfo, changeYoutubeId } from 'redux/project/action'
import { selectYoutubeId, selectDirtyBit, selectProjectName, selectProjectCaptions } from 'redux/project/selector'
import { setDirtyBit, saveProject } from 'redux/project/action'
import {clearCaption} from 'redux/captionInput/action'
import {
  retrieveTranscriptStart,
  retrieveTranscriptSuccess,
  retrieveTranscriptFailure,
  clearTranscript,
} from 'redux/youtubeTranscript/action'
import {
  Container,
  FormContainer,
  UrlInput,
  VideoWrapper,
  VideoAnimationSection,
  VideoPlaceHolder,
  PlayIcon,
  Label,
  UrlError,
  ModelWrapper,
} from './style'
import { RequestMethod } from 'types/api'
import { API_SPEECH_TO_TEXT, API_PROJECT, API_YOUTUBE_TRANSCRIPT } from 'env'

const youtubeOptions = {
  width: '100%',
  height: '100%',
}

const EditPage = () => {
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [urlError, setUrlError] = useState<string>('')
  const [noCaption, setNoCaption] = useState(false)
  const [isPolling, setIsPolling] = useState(false)
  const dirtyBit = useSelector(selectDirtyBit)
  const youtubeId = useSelector(selectYoutubeId)
  const projectName = useSelector(selectProjectName)
  const captions = useSelector(selectProjectCaptions)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { projectId } = useParams<{ projectId: string }>()
  const [videoRef, bounds] = useMeasure()
  const [sceneRef, boundscene] = useMeasure()
  const { getAccessTokenSilently } = useAuth0()

  usePrompt(dirtyBit)

  //check speech to text processing status
  const polling = (youtubeId: string, projectId: string) => {
    const checkStatus = () => {
      const config = {
        method: RequestMethod.GET,
        url: `${API_SPEECH_TO_TEXT}?youtube_id=${youtubeId}&project_id=${projectId}`,
      }
      axios(config)
        .then((res) => {
          const data = res.data
          const { status, result } = data          
          if (status !== 'Success') {
            if (!isPolling) setIsPolling(true)
            setTimeout(checkStatus, 30000)
          } else {
            setIsPolling(false)
            dispatch(retrieveTranscriptSuccess(result))
          }
        })
        .catch((err) => {
          setIsPolling(false)
          console.error('err', err)
        })
    }
    setIsPolling(true)
    checkStatus()
  }

  /* load project information from database 
  such as name, project_id, youtube_id, captions. */
  const fetchProjectData = async (
    unmounted: boolean,
    source: CancelTokenSource
  ) => {
    dispatch(loadingStart())
    const config = {
      method: RequestMethod.GET,
      url: `${API_PROJECT}/${projectId}`,
      cancelToken: source.token,
    }
    try {
      const response = await axios(config)
      const data = response.data
      const { youtube_id, captions, project_id, stt_status } = data
      if (!unmounted) {
        if (youtube_id)
          setVideoUrl(`https://www.youtube.com/watch?v=${youtube_id}`)
        if (captions.length !== 0) dispatch(retrieveTranscriptSuccess(captions))
        dispatch(stateProjectInfo(data))
        dispatch(loadingStop())
        if (stt_status === 'Processing') {
          polling(youtube_id, project_id)
        }
      }
    } catch (err) {
      if (!unmounted) {
        const message = err.response?.data.error
        dispatch(showAlert(message, 'error'))
        dispatch(loadingStop())
      }
    }
  }

  //retrieve youtube transcript
  const fetchYoutubeTranscript = async (videoId: string) => {
    dispatch(retrieveTranscriptStart())
    try {
      const config = {
        method: RequestMethod.GET,
        url: `${API_YOUTUBE_TRANSCRIPT}/${videoId}`,
      }
      const res = await axios(config)
      const data = res.data.result
      dispatch(showAlert('Fetch caption from Youtube success', 'success'))
      //set captions to captionInput in redux
      dispatch(retrieveTranscriptSuccess(data))
      dispatch(loadingStop())
    } catch (err) {
      const message = err.response?.data.error
      setNoCaption(true)
      dispatch(showAlert(message, 'error'))
      dispatch(retrieveTranscriptFailure(message))
      dispatch(loadingStop())
    }
  }

  //When enter edit page, system will fetch project information
  useEffect(() => {
    let unmounted = false
    let source = axios.CancelToken.source()
    fetchProjectData(unmounted, source)
    return () => {
      unmounted = true
      source.cancel('Cancelling in cleanup')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setVideoUrl(value)
    dispatch(setDirtyBit())
    let youtubeVideoId = ''
    const isYoutubeUrl = validateYoutubeUrl(value)

    /* if youtube url is correct set youtube video id to redux
    and retrieve transcript */
    if (isYoutubeUrl) {
      youtubeVideoId = getYoutubeVideoId(value)
      dispatch(changeYoutubeId(youtubeVideoId))
      fetchYoutubeTranscript(youtubeVideoId)
      setUrlError('')
    } else {
      setUrlError(t('home.input.error'))
    }
  }

  const speechToText = async () => {
    dispatch(clearCaption())
    dispatch(clearTranscript())
    try {
      const accessToken = await getAccessTokenSilently()
      const config = {
        method: RequestMethod.POST,
        url: API_SPEECH_TO_TEXT,
        data: {
          project_id: projectId,
          youtube_id: youtubeId,
        },
      }
      const res = await axios(config)
      const data = res.data.status
      if (data === 'start' || data === 'success') {
        polling(youtubeId, projectId)
        //save after fire speech to text api
        dispatch(
          saveProject(projectName, youtubeId, projectId, captions, accessToken)
        )
      }
    } catch (err) {
      console.error('error', err)
    }
  }

  //get closed caption button disabled status
  const getDisabledCC = () => {
    if (youtubeId && noCaption) return true
    else if (!youtubeId) return true
    else return false
  }

  //click closed caption button
  const handleClickCC = () => {
    fetchYoutubeTranscript(youtubeId)
  }

  return (
    <Fragment>
      <Navbar />
      <CustomAlert />
      <Container>
        <Box display="flex" alignItems="center">
          <Box flexGrow={2}>
            <Label htmlFor="video-url">{t('home.input.label')}</Label>
            <UrlError>{urlError}</UrlError>
            <UrlInput
              id="video-url"
              name="video-url"
              placeholder={t('home.input.placeholder')}
              onChange={handleVideoChange}
              value={videoUrl}
              disabled={isPolling}
            />
          </Box>
          <Box alignSelf="flex-end">
            <CustomIconButton
              svgIcon={<ClosedCaptionIcon />}
              tooltipLabel="Retrieve video transcript"
              placement="top"
              iconSize="36px"
              color="secondary"
              onClick={handleClickCC}
              disabled={getDisabledCC()}
              arrow
            />
            <CustomIconButton
              svgIcon={
                <svg>
                  <SpeechToText width="100%" height="100%" />
                </svg>
              }
              tooltipLabel="Auto generate caption"
              placement="top"
              iconSize="36px"
              color="secondary"
              onClick={speechToText}
              disabled={youtubeId === ''}
              arrow
            />
          </Box>
        </Box>
        <VideoAnimationSection>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div>
                {youtubeId ? (
                  <VideoWrapper ref={videoRef}>
                    <YouTube videoId={youtubeId} opts={youtubeOptions} />
                  </VideoWrapper>
                ) : (
                  <VideoPlaceHolder>
                    <PlayIcon />
                  </VideoPlaceHolder>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <ModelWrapper ref={sceneRef}>
                {bounds && boundscene && (
                  <ThreeScene
                    playerState={-1}
                    isEditPage={true}
                    gender="male"
                    height={bounds.height}
                    width={boundscene.width}
                  />
                )}
              </ModelWrapper>
            </Grid>
          </Grid>
        </VideoAnimationSection>
        {isPolling ? (
          <SpinnerOverlay />
        ) : (
          <FormContainer>
            <CaptionCard />
          </FormContainer>
        )}
      </Container>
      <Footer />
    </Fragment>
  )
}

export default EditPage
