import { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { Container } from './style'
import {changeSkinColor} from 'redux/characters/action'
import rgbToHex from 'utils/rgbToHex'

interface Props {
  color: string
}

const SkinColorBox = ({ color }: Props) => {
  const dispatch = useDispatch()

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const backgroundStyle = window
      .getComputedStyle(target, null)
      .getPropertyValue('background-color')
    const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/
    const match = matchColors.exec(backgroundStyle)
    if (match !== null) {
      const r = parseInt(match[1])
      const g = parseInt(match[2])
      const b = parseInt(match[3])
      const hexColor = rgbToHex(r, g, b)
      dispatch(changeSkinColor(hexColor))
    }
  }
  return <Container background={color} onClick={handleClick}></Container>
}

export default SkinColorBox
