import { MyBone, Timeline } from 'types/model'
import * as THREE from 'three'

const animate = (
  bones: MyBone,
  timeline: Timeline,
  previousFrame: number,
  nextFrame: number,
  t: number
) => {
  Object.keys(bones)
    .map((e) => parseFloat(e))
    .forEach((index) => {
      if(index >= 0 && index <= 137){
        let startPoseQuaternion = new THREE.Quaternion().set(
          timeline[previousFrame][index][0],
          timeline[previousFrame][index][1],
          timeline[previousFrame][index][2],
          timeline[previousFrame][index][3]
        )
        let endPoseQuaternion = new THREE.Quaternion().set(
          timeline[nextFrame][index][0],
          timeline[nextFrame][index][1],
          timeline[nextFrame][index][2],
          timeline[nextFrame][index][3]
        )
        THREE.Quaternion.slerp(
          startPoseQuaternion,
          endPoseQuaternion,
          bones[index].quaternion,
          t
        )
      }
      if(index >= 8 && index <= 81){
        let startPoseVector;
        let endPoseVector;
        startPoseVector = new THREE.Vector3().set(
          timeline[previousFrame][index+130][0],
          timeline[previousFrame][index+130][1],
          timeline[previousFrame][index+130][2],
        )
        endPoseVector = new THREE.Vector3().set(
          timeline[nextFrame][index+130][0],
          timeline[nextFrame][index+130][1],
          timeline[nextFrame][index+130][2],
        )
        bones[index].position.lerpVectors(startPoseVector, endPoseVector, t);
      }
    })
}
export default animate
