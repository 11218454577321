import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { urlMatch } from 'utils/regex'
import { GLTFNode, GLTFMaterial } from 'types/gltf'

import * as THREE from 'three'

type ObjectMap = {
  nodes: GLTFNode
  materials: GLTFMaterial
}

const buildGraph = (object: THREE.Object3D) => {
  const data: ObjectMap = { nodes: {}, materials: {} }
  if (object) {
    object.traverse((obj: any) => {
      if (obj.name) data.nodes[obj.name] = obj as THREE.Object3D
      if (obj.material && !data.materials[obj.material.name])
        data.materials[obj.material.name] = obj.material as THREE.Material
    })
  }
  return data
}

const passValuefromArrayBuffer = (arrayBuffer: ArrayBuffer) => {
  const loader = new GLTFLoader()
  const glbBlob = new Blob([arrayBuffer], { type: 'model/gltf-binary' })
  const blobUrl = URL.createObjectURL(glbBlob)
  const url = urlMatch(blobUrl)
  if (url) {
    return new Promise<GLTFNode>((resolve, reject) => {
      loader.parse(
        arrayBuffer,
        url,
        (res) => {
          const obj3d = res.scene.children
          const { nodes } = buildGraph(obj3d[0])
          resolve(nodes)
        },
        (err) => {
          console.error('err', err)
          reject(err)
        }
      )
    })
  }
}

const loadModel = async (url: string) => {
  try {
    const response = await fetch(url)
    const resArray = await response.arrayBuffer()
    const result = await passValuefromArrayBuffer(resArray)
    return result
  } catch (err) {
    const errorMessage = 'Unable to Download Model'
    console.error(errorMessage)
  }
}

export default loadModel
