import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { spaces} from 'styles/mixins'

export const Container = styled.div`
  width: 100%;
  padding: ${spaces.large2};
  justify-content: space-between;
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  height: 32px;
`

export const Options = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const LogoLink = styled(Link)`
  position: relative;
  height: 100%;
  padding: ${spaces.large4};
`
