import styled, { css } from 'styled-components'
import { mediaPhone, mediaTabLand } from 'styles/media'

export const Container = styled.div`
    width: 80%;
    margin: 0 auto;
    text-align: center;
`

export const SceneWrapper = styled.div`
    width: 800px;
    height: 800px;
    margin: 0 auto;
    ${mediaTabLand(css`
        width: 500px;
        height: 500px;
    `)}
    ${mediaPhone(css`
        width: 300px;
        height: 300px;
    `)}
`