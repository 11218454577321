import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { selectAlertPack } from 'redux/alert/selector'
import * as alertAction from 'redux/alert/action'

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const CustomAlert = ({ closeAlert, alertPack }) => {
  const { isShow, message, severity } = alertPack

  return (
    <Snackbar open={isShow} autoHideDuration={6000} onClose={closeAlert}>
      <Alert onClose={closeAlert} severity={severity || 'success'}>
        {message}
      </Alert>
    </Snackbar>
  )
}

const mapState = createStructuredSelector({
  alertPack: selectAlertPack,
})

const mapDispatch = (dispatch) => ({
  closeAlert: () => dispatch(alertAction.closeAlert()),
})

export default connect(mapState, mapDispatch)(CustomAlert)
