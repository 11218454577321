import {
  CHANGE_CHARACTER,
  InitialState,
  CharacterActionTypes,
  CHANGE_SKIN_COLOR,
} from 'redux/characters/types'

const INITIAL_STATE: InitialState = {
  currentCharacter: 'male',
  currentSkinColor: '#cf9874',
}

const charactersReducer = (
  state = INITIAL_STATE,
  action: CharacterActionTypes
): InitialState => {
  switch (action.type) {
    case CHANGE_CHARACTER:
      return {
        ...state,
        currentCharacter: action.payload,
      }
    case CHANGE_SKIN_COLOR:
      return{
        ...state,
        currentSkinColor: action.payload,
      }
    default:
      return state
  }
}

export default charactersReducer
