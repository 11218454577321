import styled, { css } from 'styled-components'
import { spaces, otherColor } from 'styles/mixins'
import { CardStyle } from 'styles/card'
import { secondaryTitle } from 'styles/typography'
import { mediaPhone, mediaTabLand } from 'styles/media'

export const Container = styled.div`
  width: 50% !important;
  margin: ${spaces.large4} auto;
  padding: ${spaces.large4};
  ${CardStyle}
  ${mediaTabLand(css`
    width: 60% !important;
  `)}
  ${mediaPhone(css`
    width: 100% !important;
  `)}
`

export const FormContainer = styled.div`
  padding: ${spaces.large2};
`

export const ButtonContainer = styled.div`
  margin-right: ${spaces.normal};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Title = styled.div`
  ${secondaryTitle}
  text-transform:uppercase;
  margin-bottom: ${spaces.large2};
`

export const ErrorText = styled.span`
  color: ${otherColor.red};
`
