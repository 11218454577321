import {
  CharacterActionTypes,
  CHANGE_CHARACTER,
  CHANGE_SKIN_COLOR,
} from 'redux/characters/types'

export const changeCharacter = (model: string): CharacterActionTypes => ({
  type: CHANGE_CHARACTER,
  payload: model,
})

export const changeSkinColor = (color: string): CharacterActionTypes => ({
  type: CHANGE_SKIN_COLOR,
  payload: color,
})
