import { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'

import loadModel from 'utils/loadmodel'
import { GLTFNode } from 'types/gltf'
import { RequestMethod } from 'types/api'
import { API_CATEGORY } from 'env'
import { CategoryResponse } from 'types/category'

export const useLoadModel = (path: string) => {
  const [nodes, setNodes] = useState<GLTFNode>({})
  useEffect(() => {
    loadModel(path).then((value) => {
      if (value) {
        setNodes(value)
      }
    })
  }, [path])
  return nodes
}

export const usePrevious = (data: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = data
  }, [data])
  return ref.current
}
/*Warn user that there is changed, please save before leave page*/
export const usePrompt = (
  when: boolean,
  message: string = 'Are you sure you want to quit without saving your changes?'
) => {
  const history = useHistory()

  const self = useRef(null)

  //@ts-ignore
  const onWindowOrTabClose = (event) => {
    if (!when) {
      return
    }

    if (typeof event == 'undefined') {
      event = window.event
    }

    if (event) {
      event.returnValue = message
    }

    return message
  }

  useEffect(() => {
    if (when) {
      //@ts-ignore
      self.current = history.block(message)
    } else {
      self.current = null
    }

    window.addEventListener('beforeunload', onWindowOrTabClose)

    return () => {
      if (self.current) {
        //@ts-ignore
        self.current()
        self.current = null
      }

      window.removeEventListener('beforeunload', onWindowOrTabClose)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, when])
}

export const useCategory = () => {
  const [category, setCategory] = useState<CategoryResponse[]>([])
  const fetchCategory = useCallback(
    async (unmounted: boolean, source: CancelTokenSource) => {
      try {
        const config = {
          method: RequestMethod.GET,
          url: API_CATEGORY,
          cancelToken: source.token,
        }
        const res = await axios(config)
        if (!unmounted && res.status === 200) {
          setCategory(res.data.category_name)
        }
      } catch (err) {
        if (!unmounted) {
          console.error('Fail to fetch category', err)
        }
      }
    },
    []
  )
  useEffect(() => {
    let unmounted = false
    let source = axios.CancelToken.source()
    fetchCategory(unmounted, source)
    return () => {
      unmounted = true
      source.cancel('Cleanup category API')
    }
  }, [fetchCategory])
  return category
}
