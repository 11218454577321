import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LinearProgress from '@material-ui/core/LinearProgress'
import { AUTH0_LINKACCOUNT_CUSTOM_CLAIM } from 'env'

const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, { 
      onRedirecting: () => <LinearProgress />,
      // auth0 doesn't include role in our custom claim when the account is signed in and linked for the first time. 
      // So, we include AUTH0_LINKACCOUNT_CUSTOM_CLAIM in the id token when the account is signed in and linked for 
      // the first time and reissue the token using the cookie in the frontend to get the complete id token with role
      claimCheck: (user) => !(AUTH0_LINKACCOUNT_CUSTOM_CLAIM in user)
    })}
    {...args}
  />
);

export default ProtectedRoute;