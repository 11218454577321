import {
  InitialState,
  AlertActionTypes,
  ALERT_SHOW,
  ALERT_CLOSE,
} from 'redux/alert/types'

const INITIAL_STATE: InitialState = {
  isShow: false,
  message: '',
  severity: undefined,
}

const alertReducer = (
  state = INITIAL_STATE,
  action: AlertActionTypes
): InitialState => {
  switch (action.type) {
    case ALERT_SHOW:
      return {
        isShow: true,
        message: action.payload,
        severity: action.severity,
      }
    case ALERT_CLOSE:
      return INITIAL_STATE
    default:
      return state
  }
}

export default alertReducer
