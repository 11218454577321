import { createMuiTheme } from '@material-ui/core/styles'

export const myTheme = createMuiTheme({
  typography: {
    fontSize: '1.6rem',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'Mitr',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#6d10cd',
    },
    secondary: {
      main: '#623dd6',
    },
  },
})
