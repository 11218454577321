import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import customChipStyle from 'styles/material_ui/components/customChipStyle'

const useStyles = makeStyles(customChipStyle)

const PosChipDescribe = ({ pos, label }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let color = ''
  switch (pos) {
    case 'home.form.header.body.pronoun.abbreviations':
      color = 'magenta'
      break
    case 'home.form.header.body.verb.abbreviations':
      color = 'green'
      break
    case 'home.form.header.body.adverb.abbreviations':
      color = 'purple'
      break
    case 'home.form.header.body.noun.abbreviations':
      color = 'blue'
      break
    case 'home.form.header.body.preposition.abbreviations':
      color = 'mint'
      break
    case 'home.form.header.body.conjunction.abbreviations':
      color = 'yellow'
      break
    case 'home.form.header.body.interjection.abbreviations':
      color = 'pink'
      break
    case 'home.form.header.body.auxiliaryVerb.abbreviations':
      color = 'orange'
      break
    case 'home.form.header.body.number.abbreviations':
      color = 'darkGreen'
      break
    default:
      break
  }

  const chipClasses = classNames({
    [classes.chip]: true,
    [classes.transparentBg]: true,
    [classes.smallFont]: true,
  })

  const avatarClasses = classNames({
    [classes[`${color}Avatar`]]: color,
  })

  return (
    <Chip
      avatar={<Avatar className={avatarClasses}>{t(pos)}</Avatar>}
      label={t(label)}
      className={chipClasses}
    />
  )
}

export default PosChipDescribe
