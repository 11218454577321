import styled from 'styled-components'
import {
  spaces,
  borders,
  black,
  fontSizes,
  lineHeights,
  fontWeights,
  otherColor,
  scene,
} from 'styles/mixins'
import { CardStyle } from 'styles/card'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

export const Container = styled.div`
  width: 80%;
  margin: ${spaces.large1} auto;
`

export const Label = styled.label`
  font-size: ${fontSizes.large1};
`

export const UrlInput = styled.input`
  margin: ${spaces.normal} 0;
  width: 100%;
  padding: ${spaces.large1} ${spaces.large2};
  line-height: ${lineHeights.normal};
  font-size: ${fontSizes.normal};
  font-family: 'Mitr', sans-serif;
  font-weight: ${fontWeights.light};
  box-shadow: 0px 2px 6px 1px rgba(98, 61, 214, 0.2);
  border: none;
  border-radius: ${borders.default};
  caret-color: ${black};
  ${CardStyle}
  &:focus {
    outline: none;
  }
`

export const UrlError = styled.div`
  display: inline;
  margin-left: ${spaces.normal};
  color: ${otherColor.red};
  font-size: ${fontSizes.small2};
`

export const PlayIcon = styled(PlayCircleOutlineIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${fontSizes.large10};
  color: #fff;
`

export const VideoPlaceHolder = styled.div`
  width: 100%;
  height: 520px;
  background-color: ${black};
  position: relative;
`

export const VideoAnimationSection = styled.div`
  width: 100%;
  margin: ${spaces.large4} auto;
`

export const FormContainer = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  margin: ${spaces.large4} auto;
  border-radius: ${borders.default};
`

export const VideoWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const ModelWrapper = styled.div`
  height: 100%;
  background-color: ${scene};
`
