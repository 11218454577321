import styled,{css} from 'styled-components'
import { spaces } from 'styles/mixins'
import { mediaPhone } from 'styles/media'

export const ImageWrapper = styled.div`
  width: 80%;
  text-align: center;
  margin: 0 auto;
  padding: ${spaces.large9} 0;
`

export const ImageOverlay = styled.img`
  width: 400px;
  ${mediaPhone(css`
    width: 200px;
  `)}
`
