import styled from 'styled-components'
import { borders, gray, spaces, bluePalette } from 'styles/mixins'

const getBackgroundColor = (props: any) => {
  if (props.isDragActive) {
    return bluePalette[100]
  }
  return gray[50]
}

const getBorderColor = (props: any) => {
  if (props.isDragActive){
    return bluePalette[400]
  }
  return gray[300]
}

export const Container = styled.div`
  background-color: ${props => getBackgroundColor(props)};
  padding: ${spaces.large4};
  border-radius: ${borders.default};
  border-style: dashed;
  border-width: 2px;
  border-color: ${props => getBorderColor(props)};
  text-align: center;
`

export const Subcontent = styled.h5`
  opacity: 0.5;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ProgressWrapper = styled.div`
  width: 100px;
`

export const Image = styled.img`
  width: 120px;
`

