import { useEffect, useRef, useState, useCallback } from 'react'
import { useFrame, useLoader } from 'react-three-fiber'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import buildTimeline from 'utils/timeline'
import { boneIndex, StandardBone } from 'types/bone'
import { ALEX, MyBone } from 'types/model'
import { clearKeypoint } from 'redux/keypoint/action'
import {
  selectCurrentKeypoint,
  selectAllTimes,
} from 'redux/keypoint/selector'
import { selectSkinColor } from 'redux/characters/selector'

import { useLoadModel } from 'utils/hook'
import animate from 'utils/animate'
import * as THREE from 'three'

const BODY_MULTIPLY_PATH = '/alex/alex_textures/Alex_Body_multiply.png'
const BODY_PATH = '/alex/alex_textures/Alex_Body.png'
const CLOTHES_MULTIPLY_PATH = '/alex/alex_textures/Alex_Clothes_multiply.png'
const HAIR1_MULTIPLY_PATH = '/alex/alex_textures/Alex_Hair1_multiply.png'
const HAIR2_MULTIPLY_PATH = '/alex/alex_textures/Alex_Hair2_multiply.png'
const CLOTHES2_MULTIPLY_PATH = '/alex/alex_textures/Alex_shirt_multiply.png'
const CLOCK = new THREE.Clock()
const FPS = 15
let offset = 0;
interface Props {
  playerState: number
  player: any
  isEditPage?: boolean
}

const AlexModel = ({ playerState, player, isEditPage }: Props) => {
  const group = useRef()
  const keypoint = useSelector(selectCurrentKeypoint)
  const allTimes = useSelector(selectAllTimes)
  const skinColor = useSelector(selectSkinColor)
  const dispatch = useDispatch()
  const [startPose, setStartPose] = useState<number[][]>([])
  const [bones, setBones] = useState<MyBone>({})
  const [startTime, setStartTime] = useState<number | undefined>()
  // const [offset, setOffSet] = useState<number>(0)
  
  const nodes = useLoadModel(ALEX.path)
  const Body_multiply = useLoader(THREE.TextureLoader, BODY_MULTIPLY_PATH)
  const Body = useLoader(THREE.TextureLoader, BODY_PATH)
  const Clothes_multiply = useLoader(THREE.TextureLoader, CLOTHES_MULTIPLY_PATH)
  const Hair1_multiply = useLoader(THREE.TextureLoader, HAIR1_MULTIPLY_PATH)
  const Hair2_multiply = useLoader(THREE.TextureLoader, HAIR2_MULTIPLY_PATH)
  const Clothes2_multiply = useLoader(
    THREE.TextureLoader,
    CLOTHES2_MULTIPLY_PATH
  )
    
  const texture = {
    Body_multiply: Body_multiply,
    Body: Body,
    Clothes_multiply: Clothes_multiply,
    Hair1_multiply: Hair1_multiply,
    Hair2_multiply: Hair2_multiply,
    Clothes2_multiply: Clothes2_multiply,
  }
  const fetchEverything = useCallback(
    async (startPoseList) => {
      await fetch('/start.csv')
        .then((response) => response.text())
        .then((data) => {
          let startPoseArr = [...startPoseList]
          data.split('\n').forEach((value) => {
            startPoseArr.push(value.split(' ').map((m) => parseFloat(m)))
          })

          setStartPose(startPoseArr)
        })

      await fetch('/standard_bone.json')
        .then((response) => response.json())
        .then((data: StandardBone) => {
          // create reverse map between model's bone name and our bone name
          let boneName: { [key: string]: string } = {}
          let bonesTemp: { [key: string]: any } = {}
          Object.entries(data).forEach(
            ([key, value]) => (boneName[value] = key)
          )
          // create map between index and bone object for creating animation
          Object.entries(nodes).forEach(([key, value]) => {
            if (value.type === 'Bone') {
              const index = boneName[key]
              if (boneIndex[index] !== undefined) {
                bonesTemp[boneIndex[index]] = value
              }
            }
          })
          setBones(bonesTemp) 
        })
    },
    [nodes]
  )

  useEffect(() => {
    if (!isEmpty(nodes)) {
      fetchEverything(startPose)
    }
    //eslint-disable-next-line
  }, [nodes])

  Object.keys(bones).forEach((index) => {
    let i = parseInt(index);
    if(i >= 0 && i <= 137){
      bones[i].quaternion.set(
        startPose[i][0],
        startPose[i][1],
        startPose[i][2],
        startPose[i][3],
      )
    }
    if(i >= 8 && i <= 81){
      bones[i].position.set(
        startPose[i+130][0],
        startPose[i+130][1],
        startPose[i+130][2],
      )
    }
  })

  const { timeline, timelineKeySorted } = buildTimeline(
    keypoint,
    allTimes,
    isEditPage,
    startPose
  )
 
  const findKF = (FrameNo: number) => {
    const nextIndex = timelineKeySorted.findIndex(
      (element: number) => element > FrameNo
    )
    const prevIndex =
      nextIndex === -1 ? timelineKeySorted.length - 1 : nextIndex - 1
    return {
      previousFrame: timelineKeySorted[prevIndex],
      nextFrame: nextIndex === -1 ? -1 : timelineKeySorted[nextIndex],
    }
  }


  let t = 0
  let currentTime = 0

  useFrame(() => {
    //PlayerPage,playerState = -2: Video does not start. playerState = 1: pause video. 
    if (!isEmpty(timeline) && playerState !== -2 && playerState !== 1 ) {
      if (!startTime) {
        setStartTime(CLOCK.getElapsedTime())
      }
      //Play from ProjectPage
      if (playerState === -1) {
        if (startTime) currentTime = CLOCK.getElapsedTime() - startTime
      }
      //5: start playing video, 2: 
      if (playerState === 5 || playerState === 2) {
        currentTime = player.getCurrentTime()
        offset = CLOCK.getElapsedTime() - player.getCurrentTime();
      }
      let FrameNo = 0;
      // 0 – ended
      if(playerState === 0){
        FrameNo = (CLOCK.getElapsedTime() - offset) / (1 / FPS);
      }else{
        FrameNo = currentTime / (1 / FPS)
      }
      const { previousFrame, nextFrame } = findKF(FrameNo)

      // nextFrame = -1 (stop playing)
      if (nextFrame !== -1) {
        t = (FrameNo - previousFrame) / (nextFrame - previousFrame)
        animate(bones, timeline, previousFrame, nextFrame, t)
      } else {
        if(playerState === -1){
           dispatch(clearKeypoint())
        }
        setStartTime(undefined)
      }
    }
  })
  
  return (
    <>
      {!isEmpty(nodes) && (
        <group
          ref={group}
          position={ALEX.position}
          scale={ALEX.scale}
          dispose={null}
        >
          <group rotation={[Math.PI / 2, 0, 0]}>
            <primitive object={nodes['rootx']} />
            <skinnedMesh
              geometry={nodes.Alex_Hair2.geometry}
              skeleton={nodes.Alex_Hair2.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Hair2_multiply}
                color="#000000"
                map-flipY={false}
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_body001.geometry}
              skeleton={nodes.Alex_body001.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color={skinColor}
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_body001_1.geometry}
              skeleton={nodes.Alex_body001_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color={skinColor}
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_eyebrows.geometry}
              skeleton={nodes.Alex_eyebrows.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Body_multiply}
                map-flipY={false}
                color="#0a043c"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_eyes001.geometry}
              skeleton={nodes.Alex_eyes001.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_eyes001_1.geometry}
              skeleton={nodes.Alex_eyes001_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_fake_reflection.geometry}
              skeleton={nodes.Alex_fake_reflection.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_iris_left001.geometry}
              skeleton={nodes.Alex_iris_left001.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#151515"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_iris_left001_1.geometry}
              skeleton={nodes.Alex_iris_left001_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#6a492b"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_iris_right001.geometry}
              skeleton={nodes.Alex_iris_right001.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#151515"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_iris_right001_1.geometry}
              skeleton={nodes.Alex_iris_right001_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#6a492b"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_jeans.geometry}
              skeleton={nodes.Alex_jeans.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#0a043c"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_teeth_down002.geometry}
              skeleton={nodes.Alex_teeth_down002.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_teeth_down002_1.geometry}
              skeleton={nodes.Alex_teeth_down002_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_teeth_up002.geometry}
              skeleton={nodes.Alex_teeth_up002.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_teeth_up002_1.geometry}
              skeleton={nodes.Alex_teeth_up002_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#D5D5D5"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_tongue.geometry}
              skeleton={nodes.Alex_tongue.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                attach="material"
                color="#a35858"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_shirt001.geometry}
              skeleton={nodes.Alex_shirt001.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Clothes_multiply}
                map-flipY={false}
                color="#000000"
                skinning
              />
            </skinnedMesh>
            <skinnedMesh
              geometry={nodes.Alex_shirt001_1.geometry}
              skeleton={nodes.Alex_shirt001_1.skeleton}
              frustumCulled={false}
            >
              <meshStandardMaterial
                map={texture.Clothes_multiply}
                map-flipY={false}
                color="#ffffff"
                skinning
              />
            </skinnedMesh>
          </group>
        </group>
      )}
    </>
  )
}

export default AlexModel
