import * as THREE from 'three'
export interface AllTimes {
  start_time: number
  duration: number
}

export interface Keypoint {
  frame: { [key: number]: number[] }
  pose: { [key: number]: number[][][] }
  sentences: number[][]
  word_sentences?: string[][]
}

export type MyBone = { [key: number]: THREE.Bone }

export type Timeline = { [key: number]: number[][] }

export const ALEX = {
  path: '/alex/fixed_alex.glb',
  scale: new THREE.Vector3(1, 1, 1),
  scale_s: new THREE.Vector3(1, 1, 1),
  position: new THREE.Vector3(0, -145, 0),
}

export const NINA = {
  path: '/nina/nina2.glb',
  scale: new THREE.Vector3(100, 100, 100),
  position: new THREE.Vector3(0, -145, 0),
}
