import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { getUserRole } from 'utils/role'

import { makeStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { USER_ROLE } from 'types/userRole'
import { ReactComponent as AddWordIcon } from 'assets/add_word.svg'
import { ReactComponent as DashboardIcon } from 'assets/dashboard.svg'
import { ReactComponent as RealtimeIcon } from 'assets/realtime.svg'
import { ReactComponent as ListIcon } from 'assets/list.svg'

import accountMenuStyle from 'styles/material_ui/components/accountMenuStyle'
import { Center, Username } from './style'

interface Props {
  onLogout: () => void
  mobile?: boolean
}

const useStyles = makeStyles(accountMenuStyle)

const AccountMenu = ({ onLogout, mobile }: Props) => {
  const { user } = useAuth0()
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const handleGoToPage = (page: string) => {
    history.push(`/${page}`)
  }

  const getName = (element: string) => {
    if (user) {
      const name = user.name || '';
      switch (element) {
        case 'avatar':
          return name[0].toUpperCase()
        case 'fullname':
          return name
        default:
          return 'User'
      }
    }
  }

  const getSubHeader = () => {
    if (!mobile)
      return (
        <ListSubheader
          component="div"
          id="account-list-subheader"
          className={classes.subheader}
        >
          {getName(`fullname`)}
        </ListSubheader>
      )
    else return <div></div>
  }

  const renderAdminMenu = () => {
    const userRole = getUserRole(user);
    switch (userRole) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.ANIMATOR:
      case USER_ROLE.SIGN_LANGUAGE_INTERPRETER:
        return (
          <Fragment>
            <Divider />
            <ListSubheader
              component="div"
              id="admin-list-subheader"
              className={classes.subheader}
            >
              Admin
            </ListSubheader>
            <ListItem button onClick={() => handleGoToPage('admin/wordlist')}>
              <ListItemIcon style={{ fontSize: '16px' }}>
                <SvgIcon>
                  <ListIcon />
                </SvgIcon>
              </ListItemIcon>
              Word List
            </ListItem>
            <ListItem button onClick={() => handleGoToPage('admin/addWord')}>
              <ListItemIcon style={{ fontSize: '16px' }}>
                <SvgIcon>
                  <AddWordIcon />
                </SvgIcon>
              </ListItemIcon>
              Add Word
            </ListItem>
          </Fragment>
        )
      default:
        return <div></div>
    }
  }

  return (
    <Fragment>
      <List
        component="nav"
        aria-labelledby="account-list-subheader"
        subheader={getSubHeader()}
      >
        {mobile && (
          <ListItem>
            <Center>
              <IconButton size="small">
                <Avatar src={user?.picture}></Avatar>
              </IconButton>
              <Username>{getName(`fullname`)}</Username>
            </Center>
          </ListItem>
        )}
        <ListItem button onClick={() => handleGoToPage(`dashboard`)}>
          <ListItemIcon style={{ fontSize: '16px' }}>
            <SvgIcon>
              <DashboardIcon width="100%" height="100%" />
            </SvgIcon>
          </ListItemIcon>
          {t('navbar.dashboard')}
        </ListItem>
        <ListItem button onClick={() => handleGoToPage(`live`)}>
          <ListItemIcon style={{ fontSize: '16px' }}>
            <SvgIcon>
              <RealtimeIcon width="100%" height="100%" />
            </SvgIcon>
          </ListItemIcon>
          {t('navbar.realtime')}
        </ListItem>
        {renderAdminMenu()}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={onLogout}
          className={classes.logout}
          dense={true}
        >
          <ListItemIcon style={{ fontSize: '16px' }}>
            <ExitToAppIcon />
          </ListItemIcon>
          {t('navbar.logout')}
        </ListItem>
      </List>
    </Fragment>
  )
}

export default AccountMenu
