import { Fragment } from 'react'
import { useSelector } from 'react-redux'

import Spinner from 'components/Spinner'
import Desktop from './Desktop'

import { selectIsPlayerPageLoading } from 'redux/loading/selector'

const ViewerPage = () => {
  const isLoading = useSelector(selectIsPlayerPageLoading)

  const getSpinner = () => {
    if (isLoading) return <Spinner />
  }

  return (
    <Fragment>
      {getSpinner()}
      <Desktop/>
    </Fragment>
  )
}

export default ViewerPage
